import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_ATTACHMENT',
  'FETCH_ATTACHMENT_SUCCESS',
  'FETCH_ATTACHMENTS_SUCCESS',
  'FETCH_ATTACHMENTS_FAIL',
  'REMOVE_ATTACHMENT',
  'ATTACHMENT_UPLOAD_SUCCESS',
  'ATTACHMENT_UPDATE_PROGRESS',
  'ATTACHMENT_UPLOAD_STARTED',
  'ATTACHMENT_UPLOAD_CANCEL',
  'ATTACHMENT_UPLOAD_FAIL',
  'UPDATE_ATTACHMENT_SUCCESS',
  'UPDATE_ATTACHMENT_FAIL',
]);

export default actionTypes;
