import { PureComponent } from 'react';

const CHECK_LENGTH = 64;
let currentTime = 0;

export default class Loader extends PureComponent {
  // Sometimes the loader may be unmounted than instantly replaced with another loader deeper in the tree. To avoid any jaring effect,
  // we cache the animation's currentTime on unmount so that it can be restored upon re-mounting
  pathRefs = {};
  componentDidMount() {
    if (currentTime) {
      Object.values(this.pathRefs).forEach(el => {
        const animation = el.getAnimations?.()[0];
        if (animation) animation.currentTime = currentTime;
      });
    }
  }
  componentWillUnmount() {
    const animations = Object.values(this.pathRefs).map(pr => pr.getAnimations?.()[0]);
    currentTime = animations[0]?.currentTime;
  }
  render() {
    const { style } = this.props;
    return (
      <div
        css={({ palette }) => ({
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          color: palette.primary.main,

          '& .animated': {
            animationDuration: '3s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
          },
          '& .tick': {
            fill: palette.logo.dark,
            animationName: 'loaderTick',

            // fillOpacity: 0
          },
          '& .check': {
            strokeWidth: 0.5,
            fillOpacity: 0,
            stroke: 'currentColor',
            fill: 'currentColor',
            strokeDashoffset: CHECK_LENGTH,
            strokeDasharray: CHECK_LENGTH,
          },
          '& .check1': {
            animationName: 'loaderCheck1',
          },
          '& .check2': {
            strokeDashoffset: 0,
            animationName: 'loaderCheck2',
          },

          '@keyframes loaderCheck1': {
            '0%': { strokeDashoffset: CHECK_LENGTH, transform: 'translateX(5.8px)', opacity: 1 },
            '27%': { strokeDashoffset: 0, transform: 'translateX(5.8px)' },
            '33%': { transform: 'translateX(5.8px)', opacity: 1 },
            '45%': { transform: 'translateX(0)', fillOpacity: 0 },
            '50%': { fillOpacity: 0 },
            '60%': { fillOpacity: 1 },
            '82%': { transform: 'translateX(0)', opacity: 1 },
            '95%': { strokeDashoffset: 0, transform: 'translateY(-5px)', opacity: 0 },
            '100%': { strokeDashoffset: 0, transform: 'translateY(-5px)', opacity: 0, fillOpacity: 1 },
          },
          '@keyframes loaderCheck2': {
            '0%': { transform: 'translateX(-5.8px)', opacity: 0 },
            '26.9%': { opacity: 0 },
            '27%': { transform: 'translateX(-5.8px)', opacity: 1 },
            '33%': { transform: 'translateX(-5.8px)' },
            '45%': { transform: 'translateX(0)' },
            '50%': { fillOpacity: 0 },
            '60%': { fillOpacity: 1 },
            '82%': { transform: 'translateX(0)', opacity: 1, fillOpacity: 1 },
            '95%': { strokeDashoffset: 0, transform: 'translateY(-5px)', opacity: 0, fillOpacity: 1 },
            '100%': { strokeDashoffset: 0, transform: 'translateY(-5px)', opacity: 0, fillOpacity: 1 },
          },
          '@keyframes loaderTick': {
            '0%': { opacity: 0 },
            '50%': { opacity: 0 },
            '60%': { transform: 'translateY(0)', opacity: 1 },
            '82%': { transform: 'translateY(0)', opacity: 1 },
            '95%': { transform: 'translateY(-5px)', opacity: 0 },
            '100%': { transform: 'translateY(-5px)', opacity: 0 },
          },
        })}
      >
        <svg height="100" style={style} viewBox="0 0 35 30" width="100">
          <g style={{ transform: 'translate(-13px, -5px)' }}>
            <path
              className="animated tick"
              d="M15.5,10.3l1.7,4.8c0,0,0.6,1.9,2.7,1.9s2.5,0,2.5,0l-1.8-5.1c0,0-0.8-1.6-2.3-1.6S15.5,10.3,15.5,10.3z"
              ref={el => (this.pathRefs.tick = el)}
            />
            <path
              className="animated check check1"
              d="M19.5,21.5l3.1,8.7h5.2l6.6-19.9H32c0,0-1.6,0-2.2,1.7s-4.5,13.4-4.5,13.4l-0.8-2.3c0,0-0.6-1.6-2.2-1.6
                S19.5,21.5,19.5,21.5z"
              ref={el => (this.pathRefs.check1 = el)}
            />
            <path
              className="animated check check2"
              d="M31,21.5l3.1,8.7h5.2L46,10.3h-2.4c0,0-1.6,0-2.2,1.7c-0.6,1.7-4.5,13.4-4.5,13.4L36,23.1c0,0-0.6-1.6-2.2-1.6
                C32.2,21.5,31,21.5,31,21.5z"
              ref={el => (this.pathRefs.check2 = el)}
            />
          </g>
        </svg>
      </div>
    );
  }
}
