import ocTypes from '../constants/OnlineCourseActionTypes';
import { addItemsToState } from './utils';

const initialState = {
  status: {
    fetch: {},
  },
  items: {},
};

export default function onlineCourseFeedbacks(state = initialState, action) {
  switch (action.type) {
    case ocTypes.FETCH_ONLINE_COURSE_SUCCESS:
      return addItemsToState(state, [action.onlineCourseFeedback]);

    default:
      return state;
  }
}
