import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogResponsive from 'my-elements/DialogResponsive';

import TermsAndConditionsContent from './TermsAndConditionsContent';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import * as appStateActions from 'my-actions/AppStateActions';

class TermsAndConditionsDialog extends Component {
  handleClose = () => {
    this.props.appStateActions.toggleTermsAndConditions(false);
  };

  render() {
    const { toggleState } = this.props;

    return (
      <DialogResponsive fullWidth maxWidth="md" onClose={this.handleClose} open={Boolean(toggleState)}>
        <DialogTitle>Website Terms of Use</DialogTitle>
        <DialogContent>
          <TermsAndConditionsContent />
        </DialogContent>
      </DialogResponsive>
    );
  }
}

export default connect(
  state => ({ toggleState: state.appState.toggle_terms_and_conditions }),
  dispatch => ({ appStateActions: bindActionCreators(appStateActions, dispatch) }),
)(TermsAndConditionsDialog);
