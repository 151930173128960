import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sortBy from 'sort-by';
import { UAParser } from 'ua-parser-js';

import { EXTENSION_ID, getExtensionVersion } from 'my-core/extension-api';
// import BrowserNotification from 'my-core/browser-notification';
import PushPub from 'my-core/push-pub';
import { getCurrentUser } from 'my-core/storeUtils';

import StudyRoomInvite from './StudyRoomInvite';

import * as notificationActions from 'my-actions/NotificationActions';
import * as platformActions from 'my-actions/PlatformActions';
import * as webNotificationActions from 'my-actions/WebNotificationActions';

class NotificationMonitor extends Component {
  componentDidMount() {
    const { currentUser, platformActions, webNotificationActions } = this.props;
    this.notificationsSubscription = PushPub.getNotificationSubscription();
    // this.notificationsSubscription.on('connected', () => {
    //   console.log('NotificationSubscription connected');
    // });
    // this.notificationsSubscription.on('disconnected', () => {
    //   console.log('NotificationSubscription disconnected');
    // });
    // this.notificationsSubscription.on('rejected', () => {
    //   console.log('NotificationSubscription rejected');
    // });
    this.notificationsSubscription.on('received', data => {
      webNotificationActions.handleNotification(data);
    });
    getExtensionVersion(resp => {
      if (resp) {
        platformActions.updatePlatform({ chromeExtensionVersion: resp });
      } else {
        const link = document.createElement('link');
        link.rel = 'chrome-webstore-item';
        link.href = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`;
        document.head.appendChild(link);
      }
    });
    const uaData = UAParser();
    platformActions.updatePlatform({
      deviceName: window.localStorage?.getItem('DEVICE_NAME') || `${uaData.browser.name} v${uaData.browser.major}`,
      ...uaData,
    });
    if (!currentUser.guest) {
      // BrowserNotification.initialize();
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.notifications !== this.props.notifications || nextProps.currentUser.id !== this.props.currentUser.id
    );
  }

  componentWillUnmount() {
    if (this.props.currentUser && this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }

  render() {
    const { notifications } = this.props;
    const activeNotifications = Object.values(notifications)
      .filter(n => n.active)
      .sort(sortBy('id'));

    return activeNotifications.map(this.renderNotification);
  }

  renderNotification = notification => {
    const { notificationActions } = this.props;

    switch (notification.type) {
      case 'STUDY_ROOM_INVITE':
        return <StudyRoomInvite key={notification.id} actions={notificationActions} notification={notification} />;
    }
  };
}

export default connect(
  state => ({
    currentUser: getCurrentUser(state),
    notifications: state.notifications.items,
  }),
  dispatch => ({
    notificationActions: bindActionCreators(notificationActions, dispatch),
    platformActions: bindActionCreators(platformActions, dispatch),
    webNotificationActions: bindActionCreators(webNotificationActions, dispatch),
  }),
)(NotificationMonitor);
