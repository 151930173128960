import types from '../constants/ChatActionTypes';

const initialState = {
  status: {},
  items: {},
};

export default function chats(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_POPUP_CHAT: {
      const popupChats = state.status.popup_chats || [];
      return {
        ...state,
        status: {
          ...state.status,
          popup_chats:
            popupChats.find(c => c.id === action.chatId) ?
              popupChats.map(c => (c.id === action.chatId ? { ...c, show: true } : c))
            : [{ id: action.chatId, show: true }, ...popupChats],
        },
      };
    }
    case types.HIDE_POPUP_CHAT:
      return {
        ...state,
        status: {
          ...state.status,
          popup_chats: state.status.popup_chats.map(c => (c.id === action.chatId ? { ...c, show: false } : c)),
        },
      };
    case types.REMOVE_POPUP_CHAT:
      return {
        ...state,
        status: {
          ...state.status,
          popup_chats: state.status.popup_chats.filter(c => c.id !== action.chatId),
        },
      };
    case types.REMOVE_ALL_POPUP_CHATS:
      return {
        ...state,
        status: {
          ...state.status,
          popup_chats: [],
        },
      };

    default:
      return state;
  }
}
