import announcementActionTypes from '../constants/AnnouncementActionTypes';
import announcementResponseActionTypes from '../constants/AnnouncementResponseActionTypes';
import types from '../constants/CommentActionTypes';
import { addItems, removeItem } from './utils';

function addComments(items, state) {
  return addItems(items, comment, types.ADD_COMMENT, 'comment', state);
}
function comment(state = {}, action) {
  switch (action.type) {
    case types.ADD_COMMENT:
      return {
        ...state,
        ...action.comment,
      };
    default:
      return state;
  }
}

const initialState = {
  status: {},
  items: {},
};

export default function comments(state = initialState, action) {
  switch (action.type) {
    case types.ADD_COMMENT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.id || action.comment.id]: comment(state.items[action.id || action.comment.id], action),
        },
      };

    case types.REMOVE_COMMENT:
      return removeItem(state, action.id);

    case announcementActionTypes.FETCH_ANNOUNCEMENT_SUCCESS:
      if (action.item.current_user_response) return addComments(action.item.current_user_response.comments, state);
      return state;

    case announcementActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS:
      return addComments(
        action.items.flatMap(a => a.current_user_response?.comments),
        state,
      );

    case announcementResponseActionTypes.FETCH_ANNOUNCEMENT_RESPONSES_SUCCESS:
      return addComments(
        action.items.flatMap(a => a.comments),
        state,
      );

    case announcementResponseActionTypes.CREATE_ANNOUNCEMENT_RESPONSE_COMMENT_SUCCESS:
      return addComments([action.item], state);

    default:
      return state;
  }
}
