import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_ANNOUNCEMENT_SUCCESS',
  'FETCH_ANNOUNCEMENT_FAIL',
  'FETCH_ANNOUNCEMENTS_ADMIN_SUCCESS',
  'FETCH_ANNOUNCEMENTS_SUCCESS',
  'FETCH_ANNOUNCEMENTS_FAIL',
  'CREATE_ANNOUNCEMENT_SUCCESS',
  'CREATE_ANNOUNCEMENT_FAIL',
  'UPDATE_ANNOUNCEMENT_SUCCESS',
  'UPDATE_ANNOUNCEMENT_FAIL',
  'DELETE_ANNOUNCEMENT_SUCCESS',
  'DELETE_ANNOUNCEMENT_FAIL',
]);

export default actionTypes;
