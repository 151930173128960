import types from '../constants/TutorFeedbackActionTypes';
import { addNewItems } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function tutorFeedbacks(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TUTOR_FEEDBACK:
      return { ...state, status: { ...state.status, [action.fetchKey]: { fetching: true } } };
    case types.FETCH_TUTOR_FEEDBACK_SUCCESS:
      return {
        ...state,
        items: addNewItems(state, [action.item]),
        status: { ...state.status, [action.fetchKey]: { success: true, id: action.item?.id } },
      };
    case types.FETCH_TUTOR_FEEDBACK_FAIL:
      return { ...state, status: { ...state.status, [action.fetchKey]: { error: action.error } } };

    case types.CREATE_TUTOR_FEEDBACK:
    case types.UPDATE_TUTOR_FEEDBACK:
      return { ...state, status: { ...state.status, [action.requestKey]: { saving: true } } };
    case types.CREATE_TUTOR_FEEDBACK_SUCCESS:
    case types.UPDATE_TUTOR_FEEDBACK_SUCCESS:
      return {
        ...state,
        items: addNewItems(state, action.tutorFeedbacks),
        status: {
          ...state.status,
          [action.requestKey]: { id: action.tutorFeedbacks[0].id, success: true },
        },
      };
    case types.CREATE_TUTOR_FEEDBACK_FAIL:
    case types.UPDATE_TUTOR_FEEDBACK_FAIL:
      return { ...state, status: { ...state.status, [action.requestKey]: { error: action.error } } };

    default:
      return state;
  }
}
