/* eslint-disable perfectionist/sort-jsx-props */
import memoize from 'memoize-one';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useCurrentUser } from 'my-core/hooks';
import {
  aboutPath,
  accountSettingsPath,
  adminDashboardPath,
  announcementsPath,
  blogPath,
  brandAmbassadorDashboardPath,
  careersPath,
  chatPagePath,
  checkoutPath,
  classRepWizardPath,
  contentCreatorDashboardPath,
  contestPath,
  courseBookletPath,
  courseClassRepApplyPath,
  coursePath,
  educatorsLandingPath,
  essayWriterLandingPagePath,
  essayWriterPath,
  flashCardsEditPath,
  flashCardsPath,
  highSchoolLandingPath,
  instructorDashboardPath,
  invitesPagePath,
  jobApplicantPath,
  livePrepLandingPath,
  livePrepSchoolPath,
  marketingRepDashboardPath,
  mcatEliteLandingPath,
  mcatEventsPath,
  mcatExamDates,
  mcatFreeResources,
  mcatRepDashboardPath,
  medSchoolCalculatorPath,
  onDemandLandingPath,
  parentsLandingPath,
  partnerPath,
  partnershipsPath,
  pricingPath,
  searchPagePath,
  studentCoursePagePath,
  studentDashboardPath,
  studentOnboardingPath,
  studentSignupPath,
  subscriptionLandingPath,
  teacherDashboardPath,
  tokensDashboardPath,
  tutoringDashboardPath,
  tutoringLandingPath,
  tutorSearchPath,
  universityLandingPath,
} from 'my-core/routes';
import { hasAnyRoles, hasManagerRole } from 'my-core/user-utils';

import Announcements from 'my-components/Announcements';
import BuilderLandingPage from 'my-components/BuilderLandingPage';
import ChatPage from 'my-components/ChatPage';
import ClassRepApplicationPage from 'my-components/ClassRepApplicationPage';
import ClassRepWizardPage from 'my-components/ClassRepWizardPage';
import ContestPage from 'my-components/ContestPage';
import CourseAssignmentPage from 'my-components/CourseAssignmentPage';
import CoursePage, { CourseBookletPage } from 'my-components/CoursePage';
import EssayJack, { LandingPage as EssayJackLandingPage } from 'my-components/EssayJack';
import { FlashCardDeckEditPage } from 'my-components/FlashCardsManager';
import FlashCardsPage from 'my-components/FlashCardsPage';
import InstructorDashboard from 'my-components/InstructorDashboard';
import JobApplication from 'my-components/JobApplication';
import Loadable from 'my-components/Loadable';
import { LoginFormDialog, ReauthenticateForm } from 'my-components/LoginForm';
import MarketingRepDashboard from 'my-components/MarketingRepDashboard';
import MCATCalculator from 'my-components/MCATCalculator';
import MCATCARSPassagePage from 'my-components/MCATCARSPassagePage';
import MCATEventPage from 'my-components/MCATEventPage';
import MCATExamDates from 'my-components/MCATExamDates';
import { MCAT, MCATFreeResources } from 'my-components/MCATLandingPages';
import MessageManager from 'my-components/MessageManager';
import NotesSharingPage from 'my-components/NotesSharingPage';
import NotificationMonitor from 'my-components/NotificationMonitor';
import PartnerDashboard from 'my-components/PartnerDashboard';
import PracticeQuestionPage from 'my-components/PracticeQuestionPage';
import Pricing from 'my-components/Pricing';
import { PrivacyPolicyDialog, PrivacyPolicyPage } from 'my-components/PrivacyPolicy';
import ProfileForm from 'my-components/ProfileForm';
import SchoolPage from 'my-components/SchoolPage';
import SignupOnboarding from 'my-components/SignupOnboarding';
import SiteTour from 'my-components/SiteTour';
import StudentCoursePage from 'my-components/StudentCoursePage';
import StudentCoursesDashboard from 'my-components/StudentCoursesDashboard';
import { StudentTutoringDashboardPage } from 'my-components/StudentTutoringDashboard';
import TeacherDashboard from 'my-components/TeacherDashboard';
import { TermsAndConditionsDialog, TermsAndConditionsPage } from 'my-components/TermsAndConditions';
import TextbookContents from 'my-components/TextbookContents';
import TokensDashboard from 'my-components/TokensDashboard';

import AppBar from './AppBar';
import MainErrorBoundary from './MainErrorBoundary';
import PopupChats from './PopupChats';
import SiteWideBanner from './SiteWideBanner';

const AboutUs = Loadable(() => import(/* webpackChunkName: "about-us" */ 'my-components/AboutUs'));
const CoursesPage = Loadable(() => import(/* webpackChunkName: "courses-page" */ 'my-components/CoursesPage'));
const HighSchoolPage = Loadable(
  () => import(/* webpackChunkName: "high-school-landing" */ 'my-components/HighSchoolPage'),
);
const HomePage = Loadable(() => import(/* webpackChunkName: "homepage" */ 'my-components/HomePage'));
const LivePrepLandingPage = Loadable(
  () => import(/* webpackChunkName: "live-prep-landing" */ 'my-components/LivePrepLandingPage'),
);
const OnDemandLandingPage = Loadable(
  () => import(/* webpackChunkName: "on-demand-landing" */ 'my-components/OnDemandLandingPage'),
);
const UniversityPage = Loadable(
  () => import(/* webpackChunkName: "university-landing" */ 'my-components/UniversityPage'),
);

const SearchPage = Loadable(() => import(/* webpackChunkName: "search-page" */ 'my-components/SearchPage'));
const SubscriptionLandingPage = Loadable(
  () => import(/* webpackChunkName: "subscriptions-page" */ 'my-components/SubscriptionLandingPage'),
);

const InstructorsPage = Loadable(() => import(/* webpackChunkName: "instructors" */ 'my-components/InstructorsPage'));

const Blog = Loadable(() => import(/* webpackChunkName: "blog" */ 'my-components/Blog'));

const ContentCreatorDashboard = Loadable(
  () => import(/* webpackChunkName: "digitizer-dashboard" */ 'my-components/ContentCreatorDashboard'),
);

const AdminLayout = Loadable(() => import(/* webpackChunkName: "admin-dashboard" */ 'my-components/AdminLayout'));
const Invites = Loadable(() => import(/* webpackChunkName: "invites" */ 'my-components/InvitesPage'));
const EducatorsPage = Loadable(() => import(/* webpackChunkName: "educators" */ 'my-components/EducatorsPage'));
const ParentsPage = Loadable(() => import(/* webpackChunkName: "parents" */ 'my-components/ParentsPage'));

const OnlineCourse = Loadable(() => import(/* webpackChunkName: "online-courses" */ 'my-components/OnlineCourse'));
const OnlineCoursePracticeMode = Loadable(
  () => import(/* webpackChunkName: "online-courses" */ 'my-components/OnlineCoursePracticeMode'),
);
const OnlineCoursePrintToPdf = Loadable(
  () => import(/* webpackChunkName: "online-courses-print" */ 'my-components/OnlineCoursePrintToPdf'),
);
const ShareActivity = Loadable(
  () => import(/* webpackChunkName: "online-courses-share" */ 'my-components/ShareActivity'),
);

/* 'oc-manager' chunk routes */
const OnlineCourseActivitiesManager = Loadable(
  () => import(/* webpackChunkName: "oc-manager" */ 'my-components/OnlineCourseActivitiesManager'),
);
const OnlineCourseManager = Loadable(
  () => import(/* webpackChunkName: "oc-manager" */ 'my-components/OnlineCourseManager'),
);
const OnlineCourseNew = Loadable(() => import(/* webpackChunkName: "oc-manager" */ 'my-components/OnlineCourseNew'));

const StudyRoom = Loadable(() => import(/* webpackChunkName: "study-room" */ 'my-components/StudyRoom'));
const WizeStory = Loadable(() => import(/* webpackChunkName: "wize-story" */ 'my-components/WizeStory'));

const Scholarships = Loadable(() => import(/* webpackChunkName: "scholarships" */ 'my-components/Scholarships'));

const TutorLandingPage = Loadable(() => import(/* webpackChunkName: "tutoring" */ 'my-components/TutorLandingPage'));
const TutorSearch = Loadable(() => import(/* webpackChunkName: "tutor-search" */ 'my-components/TutorSearch'));

const CareersPage = Loadable(() => import(/* webpackChunkName: "job-positions" */ 'my-components/CareersPage'));
const JobPositionPage = Loadable(() => import(/* webpackChunkName: "job-positions" */ 'my-components/JobPositionPage'));

const PartnersPage = Loadable(() => import(/* webpackChunkName: "partnerships" */ 'my-components/PartnersPage'));

const TutorProfile = Loadable(() => import(/* webpackChunkName: "tutor-profile" */ 'my-components/TutorProfile'));
const TeacherReviewPage = Loadable(
  () => import(/* webpackChunkName: "tutor-profile" */ 'my-components/TeacherReviewPage'),
);

export default function MainLayout(props) {
  const { darkMode, onChangeDarkMode } = props;
  const location = useLocation();
  const currentUser = useCurrentUser();
  const partnerUsers = useSelector(state =>
    getPartnerUsers(currentUser.id, state.partnerUsers.items, state.partners.items),
  );

  useEffect(() => {
    window.scrollTo?.(0, 0);
  }, [location.pathname]);

  const [userRole, homePath] = useMemo(
    () =>
      currentUser.guest ? ['guest', '/']
      : hasAnyRoles(currentUser, 'admin') ? ['admin', adminDashboardPath()]
      : hasAnyRoles(currentUser, ['instructor', 'ss_instructor', 'mcat_instructor']) ?
        ['instructor', instructorDashboardPath()]
      : hasAnyRoles(currentUser, 'tutor') ? ['tutor', instructorDashboardPath()]
      : hasAnyRoles(currentUser, 'teacher') ? ['teacher', teacherDashboardPath()]
      : hasAnyRoles(currentUser, 'content_creator') ? ['content_creator', contentCreatorDashboardPath()]
      : hasManagerRole(currentUser) ? ['manager', adminDashboardPath()]
      : ['student', partnerUsers.length ? partnerPath(partnerUsers[0].partner) : studentDashboardPath()],
    [currentUser, partnerUsers],
  );

  if (location.pathname === '/' && homePath !== '/') {
    return <Navigate to={homePath} replace />;
  }

  return (
    <MainErrorBoundary>
      <SiteWideBanner />
      <AppBar
        darkMode={darkMode}
        homePath={homePath}
        onChangeDarkMode={onChangeDarkMode}
        partnerUsers={partnerUsers}
        variant={userRole}
      />

      {currentUser.guest ?
        <LoginFormDialog />
      : <>
          <ReauthenticateForm />
          <NotificationMonitor />
          <PopupChats />
        </>
      }
      <div style={{ minHeight: '100%', paddingTop: 0 }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/homepage-alt" element={<HomePage />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path={studentSignupPath()} element={<SignupOnboarding />} />
          <Route path={studentOnboardingPath()} element={<SignupOnboarding />} />
          <Route path={checkoutPath()} element={<SignupOnboarding />} />
          {/* for access without being affected by optimize experiment */}
          <Route path={studentDashboardPath()} element={<StudentCoursesDashboard />} />
          <Route path={tutoringDashboardPath()} element={<StudentTutoringDashboardPage />} />
          <Route path={`${adminDashboardPath()}/*`} element={<AdminLayout />} />
          <Route path={instructorDashboardPath()} element={<InstructorDashboard />} />
          <Route path={contentCreatorDashboardPath()} element={<ContentCreatorDashboard />} />
          <Route path={`${teacherDashboardPath()}/*`} element={<TeacherDashboard />} />
          <Route path={aboutPath()} element={<AboutUs />} />
          <Route path={careersPath()} element={<CareersPage />} />
          <Route path={contestPath()} element={<ContestPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path={highSchoolLandingPath()} element={<HighSchoolPage />} />
          <Route path={universityLandingPath()} element={<UniversityPage />} />
          <Route path="/instructors" element={<InstructorsPage />} />
          <Route path="/scholarships/*" element={<Scholarships />} />
          <Route path="/job-positions/:id" element={<JobPositionPage />} />
          <Route path={mcatEliteLandingPath()} element={<MCAT />} />
          <Route path={mcatFreeResources()} element={<MCATFreeResources />} />
          <Route path={mcatEventsPath(':type?')} element={<MCATEventPage />} />
          <Route path={mcatExamDates()} element={<MCATExamDates />} />
          <Route path={'/cars-passages/:weekSlug'} element={<MCATCARSPassagePage />} />
          <Route path={medSchoolCalculatorPath()} element={<MCATCalculator />} />
          <Route path={livePrepLandingPath()} element={<LivePrepLandingPage />} />
          <Route path={onDemandLandingPath()} element={<OnDemandLandingPage />} />
          <Route path={subscriptionLandingPath()} element={<SubscriptionLandingPage />} />
          <Route path={educatorsLandingPath()} element={<EducatorsPage />} />
          <Route path={parentsLandingPath()} element={<ParentsPage />} />
          <Route path="/schools/:id" element={<SchoolPage />} />
          <Route path={livePrepSchoolPath({ slug: ':id' })} element={<SchoolPage />} />
          <Route path={searchPagePath()} element={<SearchPage />} />
          <Route path={invitesPagePath()} element={<Invites />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path={partnershipsPath()} element={<PartnersPage />} />
          <Route path="/partners/:idOrSlug" element={<PartnerDashboard />} />
          <Route path={blogPath(':slug?')} element={<Blog />} />
          <Route path={tutoringLandingPath()} element={<TutorLandingPage />} />
          <Route path={tutorSearchPath()} element={<TutorSearch />} />
          <Route path="tutors/:id" element={<TutorProfile />} />
          <Route path="/our-story" element={<WizeStory />} />
          <Route path="/instructors/:id" element={<TutorProfile />} />
          <Route path="/instructors/:id/write-a-review" element={<TeacherReviewPage />} />
          <Route path={`${coursePath(':idOrSlug')}`} element={<CoursePage />} />
          <Route path={courseBookletPath(':idOrSlug')} element={<CourseBookletPage />} />
          <Route path={courseClassRepApplyPath(':idOrSlug')} element={<ClassRepApplicationPage />} />
          <Route path={`${studentCoursePagePath(':idOrSlug')}/*`} element={<StudentCoursePage />} />
          <Route path={flashCardsPath(':id')} element={<FlashCardsPage />} />
          <Route path={flashCardsEditPath(':id')} element={<FlashCardDeckEditPage />} />
          <Route path="/shares/:token" element={<ShareActivity />} />
          <Route path="/online-courses/new" element={<OnlineCourseNew />} />
          <Route path="/online-courses/:id/manager/*" element={<OnlineCourseManager />} />
          <Route path="/online-courses/:id/print/*" element={<OnlineCoursePrintToPdf />} />
          <Route path="/online-courses/:id/practice-mode/*" element={<OnlineCoursePracticeMode />} />{' '}
          <Route path="/online-courses/:id/*" element={<OnlineCourse />} />
          <Route path="/online-course-activities/*" element={<OnlineCourseActivitiesManager />} />
          <Route path="/course-assignments/:id/*" element={<CourseAssignmentPage />} />
          <Route path="/study-rooms/:slug" element={<StudyRoom />} />
          <Route path={accountSettingsPath()} element={<ProfileForm />} />
          <Route path="/upload-notes" element={<NotesSharingPage />} />
          <Route path={brandAmbassadorDashboardPath()} element={<MarketingRepDashboard />} />
          <Route path={marketingRepDashboardPath()} element={<MarketingRepDashboard />} />
          <Route path={mcatRepDashboardPath()} element={<MarketingRepDashboard />} />
          <Route path={classRepWizardPath(':crcId')} element={<ClassRepWizardPage />} />
          <Route path={announcementsPath()} element={<Announcements />} />
          <Route path={jobApplicantPath()} element={<JobApplication />} />
          <Route path={chatPagePath('*')} element={<ChatPage />} />
          <Route path={tokensDashboardPath()} element={<TokensDashboard />} />
          <Route path={pricingPath()} element={<Pricing />} />
          <Route path="/textbooks/*" element={<TextbookContents />} />
          <Route path="/practice-questions/:id/*" element={<PracticeQuestionPage />} />
          <Route path={`${essayWriterPath()}/*`} element={<EssayJack />} />
          <Route path={essayWriterLandingPagePath()} element={<EssayJackLandingPage />} />
          <Route path="/lp/:slug" element={<BuilderLandingPage />} />
        </Routes>
        <PrivacyPolicyDialog />
        <TermsAndConditionsDialog />
        <SiteTour />
      </div>
      <MessageManager />
    </MainErrorBoundary>
  );
}

const getPartnerUsers = memoize((userId, partnerUsers, partners) =>
  Object.values(partnerUsers)
    .filter(pu => pu.user_id === userId && ['accepted', 'invited'].includes(pu.status))
    .map(pu => ({ ...pu, partner: partners[pu.partner_id] }))
    .filter(pu => pu.partner),
);
