import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_WHITEBOARD',
  'FETCHING_WHITEBOARD',
  'FETCH_WHITEBOARD_SUCCESS',
  'FETCH_WHITEBOARD_FAIL',
  'REMOVE_WHITEBOARD',
  'WHITEBOARD_NEW_ELEMENT',
  'WHITEBOARD_NEW_ELEMENTS',
  'WHITEBOARD_REMOVE_ELEMENT',
  'WHITEBOARD_REMOVE_ELEMENTS',
  'WHITEBOARD_NEW_POINT',
  'WHITEBOARD_END_ELEMENT',
  'WHITEBOARD_MOVE_CURSOR',
  'WHITEBOARD_REMOVE_CURSOR',
  'WHITEBOARD_CLEAR_ALL',
  'WHITEBOARD_SET_BACKGROUND',
  'WHITEBOARD_SET_ZOOM',
  'WHITEBOARD_UPDATE_ELEMENT',
  'WHITEBOARD_UPDATE_ELEMENTS',
]);

export default actionTypes;
