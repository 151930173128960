import { createConsumer } from '@rails/actioncable';

class PushPub {
  static instance = null;
  subscriptions = {};

  static getInstance() {
    PushPub.instance ||= new PushPub();
    return PushPub.instance;
  }

  constructor() {}

  getCable() {
    return (this._cable ||= createConsumer());
  }

  getNotificationSubscription() {
    return this.subscribe('NotificationsChannel');
  }

  subscribe(channel) {
    const params = typeof channel === 'object' ? channel : { channel };
    const identifier = JSON.stringify(params);
    const existingSubscription = this.subscriptions[identifier];
    if (existingSubscription && !existingSubscription.unsubscribed) return existingSubscription;
    this.subscriptions[identifier] = new Subscription(this.getCable(), channel);
    return this.subscriptions[identifier];
  }
}

export default PushPub.getInstance();

class Subscription {
  _handlers = {};

  constructor(cable, params) {
    this.ACSubscription = cable.subscriptions.create(params, {
      connected: () => this._emit('connected'),
      disconnected: () => this._emit('disconnected'),
      rejected: () => this._emit('rejected'),
      received: data => {
        this._emit('received', data);
      },
    });
  }

  perform(action, data) {
    return this.ACSubscription.perform(action, data);
  }
  send(data) {
    return this.ACSubscription.send(data);
  }
  unsubscribe() {
    this.ACSubscription.unsubscribe();
    this.unsubscribed = true;
  }

  on(event, handler) {
    const handlers = (this._handlers[event] ||= []);
    handlers.push(handler);
  }

  off(event, handler) {
    if (this._handlers[event]) {
      this._handlers[event] = this._handlers[event].filter(h => h !== handler);
    }
  }

  _emit(event, data) {
    const handlers = this._handlers[event] || [];
    handlers.forEach(handler => handler(data));
  }
}
