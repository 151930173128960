import types from '../constants/ResourceViewActionTypes';
import {
  createItemFail,
  createItemSuccess,
  fetchItemFail,
  fetchItemsFail,
  fetchItemsSuccess,
  fetchItemSuccess,
} from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function resourceViews(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_RESOURCE_VIEW_SUCCESS:
      return fetchItemSuccess(state, action);
    case types.FETCH_RESOURCE_VIEW_FAIL:
      return fetchItemFail(state, action);

    case types.FETCH_RESOURCE_VIEWS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_RESOURCE_VIEWS_FAIL:
      return fetchItemsFail(state, action);

    case types.CREATE_RESOURCE_VIEW_SUCCESS:
      return createItemSuccess(state, action);
    case types.CREATE_RESOURCE_VIEW_FAIL:
      return createItemFail(state, action);

    default:
      return state;
  }
}
