import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_VOTE_SUCCESS',
  'FETCH_VOTE_FAIL',
  'FETCH_VOTES_SUCCESS',
  'FETCH_VOTES_FAIL',
  'CREATE_VOTE_SUCCESS',
  'CREATE_VOTE_FAIL',
  'UPDATE_VOTE_SUCCESS',
  'UPDATE_VOTE_FAIL',
  'DELETE_VOTE_SUCCESS',
  'DELETE_VOTE_FAIL',
]);

export default actionTypes;
