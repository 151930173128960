import P from 'pluralize';

import { isMacOS } from 'my-core/user-agent-utils';

import { toDate } from './date-utils';

// By default will stip off the .00 if no cents
export function numberToCurrency(number, opts) {
  const result = parseFloat(number || 0).toLocaleString('en', { style: 'currency', currency: 'USD' });
  if (opts?.hideCents) return result.replace(/\.\d+/, '');
  if (!opts?.forceCents) return result.replace(/\.00$/, '');
  return result;
}
export function numberToPrice(number, opts) {
  if (number) return numberToCurrency(number, opts);
  return 'Free';
}

export function shortNum(value) {
  if (value < 10) return value.toFixed(1);
  if (value < 1_000) return value.toFixed(0);
  if (value < 10_000) return `${(value / 1000).toFixed(1)}K`;
  if (value < 1_000_000) return `${(value / 1000).toFixed(0)}K`;
  return `${(value / 1_000_000).toFixed(0)}M`;
}

export function camelize(string) {
  //modified from example at: https://ourcodeworld.com/articles/read/608/how-to-camelize-and-decamelize-strings-in-javascript
  return string.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, s1, s2) => {
    if (s2) return s2.toUpperCase();
    return s1.toLowerCase();
  });
}
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function humanize(string) {
  return capitalize(
    string
      .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
      .replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1_$2')
      .replace(/[-\s]+/g, '_')
      .toLowerCase()
      .replace(/_id$/, '')
      .replace(/_/g, ' '),
  );
}

export function dasherize(string) {
  //modified from https://github.com/jprichardson/string.js/blob/master/lib/string.js
  return string
    .replace(/[_\s]+/g, '-')
    .replace(/([A-Z])/g, '-$1')
    .replace(/-+/g, '-')
    .replace(/^-/, '')
    .replace(/-$/, '')
    .toLowerCase();
}

export function pluralize(number, word, exludeNumber = false) {
  if (number === 1) {
    return exludeNumber ? word : `1 ${word}`;
  }
  return exludeNumber ? P(word) : `${number} ${P(word)}`;
}

export function ordinal(number) {
  const rem100 = +number % 100;
  if (rem100 > 20 || rem100 < 10) {
    switch (rem100 % 10) {
      case 1:
        return number + 'st';
      case 2:
        return number + 'nd';
      case 3:
        return number + 'rd';
    }
  }
  return number + 'th';
}

// export function withArticle(word) {
//   const article = ['a', 'e', 'i', 'o', 'u'].includes(word[0].toLowerCase()) ? 'an' : 'a';
//   return `${article} ${word}`;
// }

export function titleCase(string) {
  //modified from https://github.com/jprichardson/string.js/blob/master/lib/string.js
  return string.replace(/(^[a-z]| [a-z]|-[a-z]|_[a-z])/g, m => m.toUpperCase());
}

export function truncate(string, length, ellipsis = entities.horizontalEllipsis) {
  if (string.length > length) {
    return `${string.substring(0, length - ellipsis.length)}${ellipsis}`;
  }
  return string;
}

export function formatSeekTime(time) {
  if (time == null) return;
  // using `parseInt` instead of `Math.floor` causes issues for floating point time values like 4.232e-8
  const hours = Math.floor(time / 3600) || 0;
  return [
    hours,
    padNumber(Math.floor((time % 3600) / 60) || 0, !hours ? 1 : 2),
    padNumber(Math.floor(time % 60) || 0, 2),
  ]
    .filter(Boolean)
    .join(':');
}

function padNumber(number, length) {
  return `${number}`.padStart(length, 0);
}

export function sortByDate(sortProp) {
  let sortOrder = 1;
  if (sortProp[0] === '-') {
    sortOrder = -1;
    sortProp = sortProp.substr(1);
  }
  return (a, b) => sortOrder * (toDate(a[sortProp]).getTime() - toDate(b[sortProp]).getTime());
}

export function hasCommandModifier(e) {
  return isMacOS() ? e.metaKey : e.ctrlKey;
}

export function mergeSumObjects(object1 = {}, object2 = {}) {
  // if (!object1) return object2;
  // if (!object2) return object1;
  return Object.keys(object1).reduce(
    (mem, key) => {
      const val1 = object1[key];
      const val2 = object2[key];
      if (!val2) mem[key] = val1;
      if (Number.isFinite(val2)) mem[key] = val2 + val1;
      if (typeof val2 === 'object') mem[key] = mergeSumObjects(val1, val2);
      return mem;
    },
    { ...object2 },
  );
}

export { default as isDeepEqual } from 'fast-deep-equal';
// https://you-dont-need.github.io/You-Dont-Need-Lodash-Underscore/#/?id=_debounce
// export function debounce(func, wait, opts) {
//   let timeout;
//   function debounced(...args) {
//     let context = this;
//     clearTimeout(timeout);
//     if (opts?.leading && !timeout) func.apply(context, args);
//     timeout = setTimeout(() => {
//       timeout = null;
//       if (!opts?.leading) func.apply(context, args);
//     }, wait);
//   }
//   debounced.cancel = function () {
//     if (timeout) clearTimeout(timeout);
//     timeout = null;
//   };
//   return debounced;
// }
//
// export function throttle(func, timeFrame, opts) {
//   var lastTime = 0;
//   let timeout;
//   function throttled(...args) {
//     var now = new Date();
//     if (opts?.leading !== false) {
//       if (now - lastTime >= timeFrame) {
//         func(...args);
//         lastTime = now;
//       }
//     } else {
//       if (timeout) return;
//       timeout = setTimeout(() => {
//         timeout = null;
//         func(...args);
//       }, timeFrame);
//     }
//   }
//   throttled.cancel = function () {
//     if (timeout) clearTimeout(timeout);
//     timeout = null;
//   };
//   return throttled;
// }

// Keep using the lodash-es implementation as it is relatively lightweight and feature rich (leading/trailing/maxWait parameters)
export { debounce, throttle } from 'lodash-es';

export const entities = {
  emDash: '\u2014',
  nonBreakingHyphen: '\u2011',
  horizontalEllipsis: '\u2026',
  noBreakSpace: '\u00a0',
};
