import { useTitle } from 'my-core/hooks';

import Footer from 'my-components/Footer';
import { Container } from 'my-layout';

import PrivacyPolicy from './PrivacyPolicy';

export default function PrivacyPolicyPage() {
  useTitle('Privacy Policy');

  return (
    <>
      <section css={({ palette }) => ({ backgroundColor: palette.common.white })}>
        <Container topLevel>
          <PrivacyPolicy />
        </Container>
      </section>
      <Footer />
    </>
  );
}
