import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_RESOURCE_VIEW_SUCCESS',
  'FETCH_RESOURCE_VIEW_FAIL',
  'FETCH_RESOURCE_VIEWS_SUCCESS',
  'FETCH_RESOURCE_VIEWS_FAIL',
  'CREATE_RESOURCE_VIEW_SUCCESS',
  'CREATE_RESOURCE_VIEW_FAIL',
]);

export default actionTypes;
