import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_STUDENT_TUTOR_REQUESTS',
  'FETCH_STUDENT_TUTOR_REQUESTS_SUCCESS',
  'FETCH_STUDENT_TUTOR_REQUESTS_FAIL',

  'FETCH_TUTOR_TUTOR_REQUESTS',
  'FETCH_TUTOR_TUTOR_REQUESTS_SUCCESS',
  'FETCH_TUTOR_TUTOR_REQUESTS_FAIL',

  'PURCHASE_TUTOR_REQUESTS',
  'PURCHASE_TUTOR_REQUESTS_SUCCESS',
  'PURCHASE_TUTOR_REQUESTS_FAIL',
]);

export default actionTypes;
