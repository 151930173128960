import memoize from 'memoize-one';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMessageFromError } from 'my-core/error-utils';
import { getCurrentUser } from 'my-core/storeUtils';

import { addMessage } from 'my-actions/MessageActions';

export function useCurrentUser(opts) {
  return useSelector(state => {
    let user = getCurrentUser(state);
    if (opts?.includeSchool && user.school_id) {
      user = getUserWithSchool(user, state.schools.items);
    }
    if (opts?.includeCourses) {
      user = getUserWithUserCourses(user, state.userCourses.items, state.courses.items, state.schools.items);
    }
    return user;
  });
}
const getUserWithSchool = memoize((user, schools) => ({ ...user, school: schools[user.school_id] }));
const getUserWithUserCourses = memoize((user, userCourses, courses, schools) => ({
  ...user,
  user_courses: Object.values(userCourses)
    .filter(uc => uc.user_id === user.id)
    .map(uc => {
      const course = courses[uc.course_id];
      return { ...uc, course: { ...course, school: schools[course.school_id] } };
    }),
}));

export function useHasChats() {
  return useSelector(state => !!(state.chats.status.current_user_chats || Object.keys(state.chats.items).length));
}

export function useFetchItems({
  disabled,
  entity,
  fetchAction,
  fetchKey,
  fetchOnMount,
  fetchParams,
  maxLife = 30,
  page: pageProp,
}) {
  fetchKey ||= JSON.stringify(fetchParams);
  const dispatch = useDispatch();
  const [internalPage, setInternalPage] = useState(0);
  const continuous = pageProp === undefined;
  const page = pageProp || internalPage;
  const selectFetchItemsState = useMemo(
    () =>
      memoize((fetchStatus, items, page) => ({
        fetchStatus,
        items: getItemsFromFetchStatus(fetchStatus, items, page),
      })),
    [],
  );

  const { fetchStatus, items } = useSelector(state =>
    selectFetchItemsState(state[entity].status.fetch[fetchKey], state[entity].items, !continuous && page),
  );
  // should this check disabled?
  const [fetching, setFetching] = useState(!(disabled || fetchStatus?.pages));

  useEffect(() => {
    if (fetching) {
      setFetching(false);
    }
  }, [fetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const maxAge = maxLife * 60000; //convert minutes to milliseconds
  const age = fetchStatus?.fetched_at ? Date.now() - fetchStatus.fetched_at : 0;
  const isCacheExpired = age >= maxAge;
  // TODO: automatically re-fetch after fetchStatus has been cleared (by update/create)
  // eg. Admin Reports -> New Report
  // *Be very careful not to created an infinite fetch loop*
  useEffect(() => {
    if (!disabled && (!fetchStatus?.pages?.[pageProp || 0] || fetchOnMount || isCacheExpired)) {
      dispatch(fetchAction(fetchParams, fetchKey, pageProp || 0));
      setFetching(true);
    }
    setInternalPage(0);
  }, [fetchKey, disabled, pageProp]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefetch = useCallback(() => {
    if (!fetching) {
      dispatch(fetchAction(fetchParams, fetchKey, page));
      setFetching(true);
    }
  }, [fetching, fetchKey, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMore = useCallback(() => {
    const newPage = page + 1;
    dispatch(fetchAction(fetchParams, fetchKey, newPage));
    setInternalPage(newPage);
    setFetching(true);
  }, [fetching, fetchKey, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const canFetchMore = Boolean(
    !fetching &&
      continuous &&
      fetchStatus &&
      !fetchStatus.error &&
      !fetchStatus.last_fetch_empty &&
      items.length < fetchStatus.total,
  );
  return {
    fetching,
    fetchError: fetchStatus?.error,
    fetchStatus,
    total: fetchStatus?.total,
    items,
    onFetchMore: canFetchMore ? fetchMore : undefined,
    onRefetch: handleRefetch,
  };
}

function getItemsFromFetchStatus(fetchStatus, items, page) {
  if (typeof page === 'number') {
    return (fetchStatus?.pages?.[page] || []).map(id => items[id]).filter(Boolean);
  } else {
    return Object.keys(fetchStatus?.pages || {})
      .sort()
      .flatMap(p => fetchStatus.pages[p].map(id => items[id]))
      .filter(Boolean);
  }
}

export function useUpdateItems({
  entity,
  errorMessage,
  getUpdateKey,
  onUpdateFail,
  onUpdateSuccess,
  successMessage,
  updateAction,
  updateKey: defaultUpdateKey,
}) {
  const dispatch = useDispatch();
  const status = useSelector(state => state[entity].status);
  const [updatingIds, setUpdatingIds] = useState([]);
  const [cachedStatus, setCachedStatus] = useState(status);
  const updater = useCallback(
    (id, ...rest) => {
      const updateKey = getUpdateKey?.(id) || defaultUpdateKey || `update_${id}`;
      dispatch(updateAction(id, ...rest));
      setUpdatingIds(ids =>
        [...ids, [id, updateKey]].filter(([id], idx, arr) => arr.findIndex(([id2]) => id === id2) === idx),
      );
      setCachedStatus(status);
    },
    [dispatch, status], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (updatingIds.length) {
      const statusChangedId = updatingIds.find(entry => cachedStatus?.[entry[1]] !== status?.[entry[1]]);
      if (statusChangedId) {
        setUpdatingIds(updatingIds.filter(id => id !== statusChangedId));
        setCachedStatus(status);
        const updateStatus = status[statusChangedId[1]];
        if (updateStatus.success) {
          dispatch(addMessage({ content: successMessage || 'Save successful' }));
          onUpdateSuccess?.(statusChangedId);
        } else {
          dispatch(addMessage({ content: errorMessage || getMessageFromError(updateStatus.error), type: 'error' }));
          onUpdateFail?.(statusChangedId);
        }
      }
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return { updater, updatingIds: updatingIds.map(([id]) => id), updateStatus: cachedStatus };
}

export function useCreateItem({ createAction, entity, errorMessage, onCreateFail, onCreateSuccess, successMessage }) {
  const dispatch = useDispatch();
  const createStatus = useSelector(state => state[entity].status.create);
  const [creating, setCreating] = useState(false);
  const [cachedStatus, setCachedStatus] = useState(createStatus);
  const creator = useCallback(
    params => {
      dispatch(createAction(params));
      setCreating(true);
      setCachedStatus(createStatus);
    },
    [dispatch, createStatus], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (creating && cachedStatus !== createStatus) {
      setCreating(false);
      setCachedStatus(createStatus);
      if (createStatus.success) {
        successMessage && dispatch(addMessage({ content: successMessage || 'Save successful' }));
        onCreateSuccess?.(createStatus.id);
      } else {
        dispatch(addMessage({ content: errorMessage || getMessageFromError(createStatus.error), type: 'error' }));
        onCreateFail?.(createStatus.error);
      }
    }
  }, [createStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return { creator, creating };
}
