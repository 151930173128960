import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_NOTIFICATION',
  'FETCHING_NOTIFICATIONS',
  'REMOVE_NOTIFICATION',
  'MARK_NOTIFICATIONS_AS_READ_SUCCESS',
  'MARK_NOTIFICATIONS_AS_READ_FAIL',
  'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
  'MARK_ALL_NOTIFICATIONS_AS_READ_FAIL',
]);

export default actionTypes;
