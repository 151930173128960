import types from '../constants/NotificationActionTypes';
import { addItems, removeItem } from './utils';

function notification(state = {}, action) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        ...action.notification,
      };
    default:
      return state;
  }
}

const initialState = {
  status: {},
  items: {},
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return addItems([action.notification], notification, types.ADD_NOTIFICATION, 'notification', state);

    case types.ADD_NOTIFICATIONS:
      return addItems(action.notifications, notification, types.ADD_NOTIFICATION, 'notification', state);
    case types.FETCHING_NOTIFICATIONS:
      return {
        ...state,
        status: {
          ...state.status,
          fetchingNotification: 'fetching',
        },
      };

    case types.REMOVE_NOTIFICATION:
      return removeItem(state, action.id);

    case types.MARK_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        items: Object.values(state.items).reduce((mem, n) => {
          if ((!action.groupKey || action.groupKey === n.group_key) && action.notificationType === n.type)
            mem[n.id] = { ...n, read: true };
          else mem[n.id] = n;
          return mem;
        }, {}),
      };
    case types.MARK_NOTIFICATIONS_AS_READ_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          [`mark_as_read_${action.notificationType}_${action.groupKey}`]: { error: action.error },
        },
      };

    case types.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        items: Object.keys(state.items).reduce((mem, id) => ((mem[id] = { ...state.items[id], read: true }), mem), {}),
      };

    case types.MARK_ALL_NOTIFICATIONS_AS_READ_FAIL:
      return { ...state, status: { ...state.status, mark_all_as_read: { error: action.error } } };

    default:
      return state;
  }
}
