import memoize from 'memoize-one';

const buildGuestUser = memoize(platform => ({
  guest: true,
  name: 'Guest User',
  user_type: 'guest',
  roles: [],
  country_code: platform.country_code,
}));

export function getCurrentUser(state) {
  const currentUserId = state.users.status.current_user;
  if (currentUserId) {
    return state.users.items[currentUserId];
  }
  return buildGuestUser(state.platform);
}
