import queryString from 'query-string';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import DialogResponsive from 'my-elements/DialogResponsive';

import PrivacyPolicy from './PrivacyPolicy';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import * as appStateActions from 'my-actions/AppStateActions';

function PrivacyPolicyDialog(props) {
  const { appStateActions, toggleState } = props;
  const location = useLocation();
  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (searchQuery['privacyPolicy'] !== undefined) appStateActions.togglePrivacyPolicy(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    appStateActions.togglePrivacyPolicy(false);
  };

  return (
    <DialogResponsive fullWidth maxWidth="md" onClose={handleClose} open={!!toggleState}>
      <DialogTitle variant="h4">Privacy Policy</DialogTitle>
      <DialogContent
        sx={{
          '& #iubenda_policy.iubenda_embed_policy': {
            '& .iub_container': {
              m: 0,
              '& .iub_content': { p: 0 },
            },
          },
        }}
      >
        <PrivacyPolicy />
      </DialogContent>
    </DialogResponsive>
  );
}

export default connect(
  state => ({ toggleState: state.appState.toggle_privacy_policy }),
  dispatch => ({ appStateActions: bindActionCreators(appStateActions, dispatch) }),
)(PrivacyPolicyDialog);
