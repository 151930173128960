import { withEmotionCache } from '@emotion/react';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { TRACKING_DISABLED } from 'my-core/tag-manager';

import TagManager from 'my-components/TagManager';

import MainStyles from './MainStyles';

export default withEmotionCache((_, cache) => {
  useEffect(() => window.dispatchAppMountedEvent?.(), []);
  // WK-1240: hack to override @emotion sheet `speedy` option to make sure prerendered pages include css rules
  // in DOM content (bypass insertRule API). Needs to be in component tree child of <StyleEngineProvider />
  if (TRACKING_DISABLED) cache.sheet.isSpeedy = false;

  // the BrowserRouter future={{ v7_startTransition: true }} flag causes some UX issues, like the flash of loading screen
  // on each navigation, and also results in race conditions when copying values to/from state<-->searchParams (like debounced AdminDashboard filters)
  return (
    <BrowserRouter>
      <MainStyles />
      <TagManager />
    </BrowserRouter>
  );
});
