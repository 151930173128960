import onlineCourseTypes from '../constants/OnlineCourseActionTypes';
import onlineCourseSurveyTypes from '../constants/OnlineCourseSurveyActionTypes';
import types from '../constants/OnlineCourseUserActionTypes';
import { addItems, addItemsToState, fetchItemsFail, fetchItemsSuccess, removeItem, updateItem } from './utils';

function addOCUsers(items, state) {
  return addItems(items, onlineCourseUser, types.ADD_ONLINE_COURSE_USER, 'onlineCourseUser', state);
}

function onlineCourseUser(state = {}, action) {
  switch (action.type) {
    case types.ADD_ONLINE_COURSE_USER:
      return {
        ...state,
        ...action.onlineCourseUser,
      };
    default:
      return state;
  }
}

const initialState = {
  status: {
    fetch: {},
  },
  items: {},
};

export default function onlineCourseUsers(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ONLINE_COURSE_USERS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_ONLINE_COURSE_USERS_FAIL:
      return fetchItemsFail(state, action);

    case types.UPDATE_ONLINE_COURSE_USER_SUCCESS:
      return addOCUsers([action.onlineCourseUser], {
        ...state,
        status: { ...state.status, update: { success: action.onlineCourseUser.id, error: null } },
      });
    case types.UPDATE_ONLINE_COURSE_USER_FAIL:
      return { ...state, status: { ...state.status, update: { error: action.error } } };

    case types.UPDATE_ONLINE_COURSE_USER_PROGRESS: {
      const updates = action.progress;
      return updateItem(
        action.id,
        ocu => {
          const { progress } = ocu;
          const newCompleted = updates
            .map(u => ({
              ...progress?.completed?.find(a => a.activity_id === u.activity_id),
              ...u,
            }))
            .concat((progress?.completed || []).filter(a => !updates.some(u => u.activity_id === a.activity_id)));

          const current = updates.find(u => u.is_current);
          const newCurrent =
            current ?
              {
                chapter_id: current.chapter_id,
                section_id: current.section_id,
                activity_id: current.activity_id,
                assessment_id: current.assessment_id,
              }
            : progress.current;

          const viewTime = newCompleted.reduce((mem, p) => mem + (p.duration || 0), 0);

          return {
            ...ocu,
            updated_at: new Date().toJSON(),
            view_time: viewTime,
            progress: {
              completed: newCompleted,
              current: newCurrent,
            },
          };
        },
        state,
      );
    }
    case types.UPDATE_ONLINE_COURSE_USER_PROGRESS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          [`update_progress_${action.id}`]: { error: null },
        },
      };

    case types.UPDATE_ONLINE_COURSE_USER_PROGRESS_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          [`update_progress_${action.id}`]: {
            error: action.error,
          },
        },
      };

    case types.PURCHASE_ONLINE_COURSE_USER_SUCCESS:
      return addOCUsers([action.onlineCourseUser], {
        ...state,
        status: { ...state.status, purchase: { success: action.onlineCourseUser.id, error: null } },
      });
    case types.PURCHASE_ONLINE_COURSE_USER_FAIL:
      return { ...state, status: { ...state.status, purchase: { error: action.error } } };

    case types.ADD_USER_TO_ONLINE_COURSE_SUCCESS:
      return addOCUsers([action.onlineCourseUser], {
        ...state,
        status: { ...state.status, fetch: {}, add_user_to_online_course: { success: action.onlineCourseUser.id } },
      });
    case types.ADD_USER_TO_ONLINE_COURSE_FAIL:
      return { ...state, status: { ...state.status, add_user_to_online_course: { error: action.error } } };

    case types.DELETE_ONLINE_COURSE_USER_SUCCESS:
      return removeItem(
        { ...state, status: { ...state.status, fetch: {}, delete: { success: action.id } } },
        action.id,
      );
    case types.DELETE_ONLINE_COURSE_USER_FAIL:
      return { ...state, status: { ...state.status, delete: { error: action.error } } };

    case types.REFUND_ONLINE_COURSE_USER_SUCCESS:
      return addOCUsers([action.onlineCourseUser], {
        ...state,
        status: { ...state.status, fetch: {}, refund: { success: action.onlineCourseUser.id, amount: action.amount } },
      });
    case types.REFUND_ONLINE_COURSE_USER_FAIL:
      return { ...state, status: { ...state.status, refund: { success: false, error: action.error } } };

    case types.SEND_ONLINE_COURSE_USER_RECEIPT_SUCCESS:
      return { ...state, status: { ...state.status, send_receipt: { success: action.onlineCourseUserId } } };

    case types.SEND_ONLINE_COURSE_USER_RECEIPT_FAIL:
      return { ...state, status: { ...state.status, send_receipt: { error: action.error } } };

    case types.FETCH_ADMIN_ONLINE_COURSE_USERS_SUCCESS:
      return addOCUsers(action.onlineCourseUsers, {
        ...state,
        status: { ...state.status, adminFetch: { success: true } },
      });
    case types.FETCH_ADMIN_ONLINE_COURSE_USERS_FAIL:
      return { ...state, status: { ...state.status, adminFetch: { success: false } } };

    case types.EXPIRE_ALL_ONLINE_COURSE_USER_ACCESS_SUCCESS:
      return {
        ...state,
        items: action.onlineCourseUserIds.reduce(
          (mem, ocuId) => {
            if (mem[ocuId]) {
              mem[ocuId] = {
                ...mem[ocuId],
                status: 'expired',
                expires_at: new Date().toJSON(),
              };
            }
            return mem;
          },
          { ...state.items },
        ),
        status: { ...state.status, [action.requestKey]: { success: true } },
      };
    case types.EXPIRE_ALL_ONLINE_COURSE_USER_ACCESS_FAIL:
      return { ...state, status: { ...state.status, [action.requestKey]: { error: action.error } } };

    case onlineCourseTypes.FETCH_ONLINE_COURSE_SUCCESS:
      if (action.onlineCourseUser) {
        return addOCUsers([action.onlineCourseUser], state);
      }
      return state;

    case onlineCourseTypes.FETCH_ONLINE_COURSE_INSTRUCTOR_OVERVIEW_SUCCESS:
      return addOCUsers(action.onlineCourse.online_course_users, state);

    case onlineCourseTypes.ADD_USER_TO_ONLINE_COURSE_SUCCESS:
      return addOCUsers([action.onlineCourseUser], state);

    case onlineCourseSurveyTypes.CREATE_ONLINE_COURSE_SURVEY_SUCCESS:
      return addItemsToState(state, action.onlineCourseUsers);

    default:
      return state;
  }
}
