import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { cloneElement, useEffect, useRef, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Collapse from '@mui/material/Collapse';
import Popover from '@mui/material/Popover';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles(
  ({ constants }) => ({
    root: {},
    container: {
      position: 'absolute',
      top: constants.APPBAR_OFFSET,
      maxHeight: `calc(100dvh - ${constants.APPBAR_OFFSET}px)`,
      left: 0,
      right: 0,
      overflow: 'auto',
      zIndex: 2,
    },
    backdrop: {
      zIndex: -1,
      background: 'linear-gradient(to bottom, transparent 56px, rgba(0,0,0,0.5) 56px)',
    },
  }),
  { name: 'PopoverResponsive' },
);

export default function PopoverResponsive(props) {
  const classes = useStyles(props);
  const { breakpoint = 'sm', button, CollapseProps, component: Component, componentProps, PopoverProps } = props;

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down(breakpoint));
  const passedProps = { isSmall, open, setOpen };

  useEffect(() => {
    if (!(isSmall && open)) return;

    const prevOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = prevOverflow;
    };
  }, [open, isSmall]);

  return (
    <>
      {renderButton()}
      {renderWrapper()}
    </>
  );

  function renderButton() {
    const buttonProps = { ref: anchorEl };
    if (typeof button === 'function') return button({ ...buttonProps, ...passedProps });
    return cloneElement(button, { ...buttonProps, onClick: () => setOpen(!open) });
  }

  function renderWrapper() {
    const content = <Component {...componentProps} {...passedProps} />;

    if (isSmall)
      return (
        <>
          <Collapse className={classes.container} in={open} {...CollapseProps}>
            {content}
          </Collapse>
          <Backdrop className={classes.backdrop} onClick={() => setOpen(false)} open={open} />
        </>
      );
    return (
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        BackdropProps={{ invisible: false }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...PopoverProps}
      >
        {content}
      </Popover>
    );
  }
}

PopoverResponsive.propTypes = {
  breakpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  button: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  CollapseProps: PropTypes.object,
  component: PropTypes.elementType.isRequired,
  componentProps: PropTypes.object,
  PopoverProps: PropTypes.object,
  query: PropTypes.string,
};
