import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_ONLINE_COURSE_SURVEY_SUCCESS',
  'FETCH_ONLINE_COURSE_SURVEY_FAIL',
  'FETCH_ONLINE_COURSE_SURVEYS_SUCCESS',
  'FETCH_ONLINE_COURSE_SURVEYS_FAIL',
  'CREATE_ONLINE_COURSE_SURVEY_SUCCESS',
  'CREATE_ONLINE_COURSE_SURVEY_FAIL',
  'UPDATE_ONLINE_COURSE_SURVEY_SUCCESS',
  'UPDATE_ONLINE_COURSE_SURVEY_FAIL',
  'DELETE_ONLINE_COURSE_SURVEY_SUCCESS',
  'DELETE_ONLINE_COURSE_SURVEY_FAIL',
]);

export default actionTypes;
