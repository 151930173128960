import { googleOneTapSignInStateChanged } from 'my-core/gtm-events';
import { insertScript } from 'my-core/script-utils';

const GSI_SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

export function renderGoogleButton(elementId, opts, onSuccess, onError) {
  insertScript(GSI_SCRIPT_SRC, {
    onLoad: () => {
      if (initializeGoogleLogin(onSuccess, onError)) {
        // https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
        window.google.accounts.id.renderButton(document.getElementById(elementId), {
          theme: 'outline',
          size: 'large',
          shape: 'pill',
          // width: 400,
          text: 'signin_with',
          logo_alignment: 'center',
          ...opts,
        });
      }
    },
    onError,
  });
}

export function loadOneTapSignIn(onSuccess, onError) {
  insertScript(GSI_SCRIPT_SRC, {
    onLoad: () => {
      if (initializeGoogleLogin(onSuccess, onError)) {
        window.google.accounts.id.prompt(res => {
          // getMomentType, isSkippedMoment, isDismissedMoment, getDismissedReason
          googleOneTapSignInStateChanged({
            moment_type: res.getMomentType(),
            dismissed_reason: res.getDismissedReason(),
          });
        });
      }
    },
    onError,
  });
}

function initializeGoogleLogin(onSuccess, onError) {
  if (!window.google) {
    onError?.();
    return false;
  }
  window.google.accounts.id.initialize({
    client_id: process.env.GOOGLE_API_CLIENT_ID,
    use_fedcm_for_prompt: true,
    callback: credentialResponse => handleGoogleLogin(credentialResponse, onSuccess, onError),
  });
  return true;
}

function handleGoogleLogin(credentialResponse, onSuccess, onError) {
  credentialResponse.credential ? onSuccess(credentialResponse) : onError(credentialResponse); // console.error('Google sign in fail');
}

//https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseIdToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getUniqueGoogleId(token) {
  const jsonPayload = parseIdToken(token);
  return jsonPayload.sub;
}

export function googleLogout() {
  insertScript(GSI_SCRIPT_SRC, {
    onLoad: () => window.google?.accounts?.id?.disableAutoSelect(),
  });
}
