import API from './api';
import { DEVICE_INFO, DEVICE_UUID } from './tag-manager';

let performTracking = false;
export function installWizeTracking() {
  performTracking = true;
}

export function wizeTrack(type, target_id, target_type) {
  if (performTracking) {
    API.putJSON('/tracking_events', {
      tracking_event: { type, target_id, target_type, device_info: DEVICE_INFO, device_uuid: DEVICE_UUID },
    }).catch(e => console.error(e));
  }
}

export function wizeTrack2(type, target_id, parameters) {
  if (performTracking) {
    API.putJSON('/tracking_events2', {
      tracking_event: { type, target_id, device_info: DEVICE_INFO, device_uuid: DEVICE_UUID, parameters },
    }).catch(e => console.error(e));
  }
}
