import reactionTypes from '../constants/ResourceReactionActionTypes';
import voteTypes from '../constants/VoteActionTypes';
import { createReaction, createVote, deleteReaction, deleteVote } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function forumPosts(state = initialState, action) {
  switch (action.type) {
    case voteTypes.CREATE_VOTE_SUCCESS:
      return createVote('ForumPost', action, state);

    case voteTypes.DELETE_VOTE_SUCCESS:
      return deleteVote('ForumPost', action, state);

    case reactionTypes.CREATE_RESOURCE_REACTION_SUCCESS: {
      return createReaction('ForumPost', action, state);
    }
    case reactionTypes.DELETE_RESOURCE_REACTION_SUCCESS: {
      return deleteReaction('ForumPost', action, state);
    }

    default:
      return state;
  }
}
