const DEFAULT_STATE = { items: {}, status: {} };

export default function (state = DEFAULT_STATE) {
  return state;
}

function updateApiRequests(state, action, updatesArg) {
  const { cacheGroupKey, meta, parentRequestKey, requestKey } = action;
  const rootKey = parentRequestKey || requestKey;
  const updates = { ...updatesArg, ...meta };
  return {
    ...state,
    [rootKey]: {
      ...state[rootKey],
      ...(parentRequestKey &&
        requestKey != null && {
          requests: {
            ...state[rootKey]?.requests,
            [requestKey]: { ...state[rootKey]?.requests?.[requestKey], ...updates },
          },
        }),
      ...updates,
    },
    ...(cacheGroupKey && {
      __cacheGroups: {
        ...state.__cacheGroups,
        [cacheGroupKey]: [...(state.__cacheGroups?.[cacheGroupKey] || []), rootKey],
      },
    }),
  };
}

export const combinedReducerEnhancer = reducer => (state, action) => {
  if (
    action.error?.status === 401 &&
    (action.error.body.error_code === 'reauthentication_required' || action.error.body.error_code === 'unauthenticated')
  ) {
    state = { ...state, appState: { ...state.appState, show_reauthentication_form: true } };
  }

  switch (action.type) {
    case 'STANDARD_API_REQUEST':
      return {
        ...state,
        apiRequests: updateApiRequests(state.apiRequests, action, { fetching: true }),
        ...addPayloadToState(action.instantUpdate, state),
      };
    case 'STANDARD_API_REQUEST_SUCCESS': {
      const newState = {
        ...state,
        apiRequests: updateApiRequests(state.apiRequests, action, {
          fetching: false,
          success: true,
          error: undefined,
          fetched_at: Date.now(),
        }),
        ...addPayloadToState(action.payload, state),
      };
      if (action.meta?._destroy) {
        Object.entries(action.meta._destroy).forEach(([entityType, entityIds]) => {
          if (!entityIds?.length) return;
          const newItems = { ...newState[entityType].items };
          entityIds.forEach(entityId => delete newItems[entityId]);
          newState[entityType] = { ...newState[entityType], items: newItems };
        });
      }
      return newState;
    }
    case 'STANDARD_API_REQUEST_FAIL':
      return {
        ...state,
        apiRequests: updateApiRequests(state.apiRequests, action, {
          fetching: false,
          error: action.error,
          fetched_at: Date.now(),
        }),
        ...addPayloadToState(action.payload, state),
      };
    case 'STANDARD_API_DELETE_REQUEST': {
      if (state.apiRequests[action.key]) {
        const newApiRequests = { ...state.apiRequests };
        delete newApiRequests[action.key];
        return { ...state, apiRequests: newApiRequests };
      }
      return state;
    }
    case 'STANDARD_API_DELETE_CACHE_GROUP_REQUESTS': {
      const requestKeys = state.apiRequests.__cacheGroups?.[action.key];
      if (requestKeys) {
        const newApiRequests = { ...state.apiRequests };
        const newCacheGroups = { ...newApiRequests.__cacheGroups };
        delete newCacheGroups[action.key];
        requestKeys.forEach(k => delete newApiRequests[k]);
        newApiRequests.__cacheGroups = newCacheGroups;
        return { ...state, apiRequests: newApiRequests };
      }
      return state;
    }
    case 'STANDARD_PUSH_UPDATE': {
      return {
        ...state,
        ...addPayloadToState(action.payload, state),
      };
    }
    default:
      return reducer(state, action);
  }
};

function addPayloadToState(payload, state) {
  if (!payload) return;
  return Object.entries(payload).reduce((mem, [entityType, entities]) => {
    mem[entityType] = {
      ...state[entityType],
      items: entities.reduce(
        (mem2, entity) => {
          mem2[entity.id] = {
            ...mem2[entity.id],
            ...entity,
          };
          return mem2;
        },
        {
          ...state[entityType].items,
        },
      ),
    };
    return mem;
  }, {});
}
