import P from 'pluralize';

import { camelize } from 'my-utils';

export default function (json) {
  const result = {};
  function insertEntry(entry) {
    const normType = _normalizeType(entry.type);
    const normEntry = _normalizeEntry(entry);
    (result[normType] ||= []).push(normEntry);
  }

  const data =
    json.data ?
      Array.isArray(json.data) ?
        json.data
      : [json.data]
    : [];
  data.forEach(insertEntry);
  json.included?.forEach(insertEntry);

  // if (mergeRelationships) _mergeRelationships(data, result);
  // Object.keys(result).forEach(k => (result[k] = Object.values(result[k])));

  result.meta = json.meta;

  return result;
}

function _normalizeEntry(entry) {
  const { attributes = {}, id, relationships } = entry;

  return {
    ..._normalizeRelationships(relationships),
    relationships,
    ...attributes,
    id: attributes.id || parseInt(id) || id,
  };
}

function _normalizeRelationships(relationships) {
  if (!relationships) return;
  const normalizedRelationships = {};

  Object.entries(relationships).forEach(([name, { data }]) => {
    if (Array.isArray(data)) {
      //P.isSingular(name)
      normalizedRelationships[`${P.singular(name)}_ids`] = data.map(r => parseInt(r.id));
    } else {
      normalizedRelationships[`${name}_id`] = data && parseInt(data.id);
    }
  });

  return normalizedRelationships;
}

function _normalizeType(type) {
  return P(camelize(type));
}

// function _mergeRelationships(data, result) {
//   data.forEach(entry => {
//     const entryType = _normalizeType(entry.type);
//     const normEntry = result[entryType].find(e => e.id == entry.id);
//     Object.entries(entry.relationships || {}).forEach(([relName, { data }]) => {
//       if (data) {
//         if (Array.isArray(data)) {
//           normEntry[relName] = data
//             .map(({ type, id }) => result[_normalizeType(type)]?.find(e => e.id == id))
//             .filter(Boolean);
//         } else {
//           normEntry[relName] = result[_normalizeType(data.type)]?.find(e => e.id == data.id);
//         }
//       }
//     });
//   });
// }

// function _normalizeMeta(store, meta) {
// TODO: create meta normalizer. key should be called status
// }
