import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import API from 'my-core/api';
import { siteTourStarted } from 'my-core/gtm-events';
import { useCurrentUser, useHasChats } from 'my-core/hooks';
import { invitesPagePath } from 'my-core/routes';
import { logout as tagLogout } from 'my-core/tag-manager';
import { hasAnyRoles } from 'my-core/user-utils';

// import { experimentsFlag } from 'my-core/feature-toggles';
import { ALT_PATHS, NO_APPBAR_ROUTES, ROLE_ROUTES, VARIANTS } from './app-bar-data';
import AppBar from './AppBar';

import * as appStateActions from 'my-actions/AppStateActions';
import { startAllSiteTours } from 'my-actions/SiteTourActions';
import * as userActions from 'my-actions/UserActions';

export default function AppBarCtnr(props) {
  const { darkMode, homePath, onChangeDarkMode, partnerUsers, variant } = props;

  const currentUser = useCurrentUser({ includeSchool: true, includeCourses: true });
  const dispatch = useDispatch();
  const hasChats = useHasChats();
  const location = useLocation();
  const siteBannerActive = useSelector(state => state.appState.site_wide_banner?.isOpen);

  const signOut = useCallback(() => {
    API.deleteJSON('/users/sign_out').then(() => {
      tagLogout();
      window.location.replace('/');
    });
  }, []);

  const showPrivacyPolicy = useCallback(() => dispatch(appStateActions.togglePrivacyPolicy(true)), [dispatch]);
  // const toggleContactUsForm = useCallback(() => dispatch(appStateActions.toggleContactUsForm()), [dispatch]);

  const toggleLoginForm = useCallback(
    formProps =>
      dispatch(
        appStateActions.toggleLoginForm({ page: 'login', source: 'appbar', messagingType: 'stat', ...formProps }),
      ),
    [dispatch],
  );
  const toggleSignUpForm = useCallback(
    formProps =>
      dispatch(
        appStateActions.toggleLoginForm({ page: 'signup', source: 'appbar', messagingType: 'stat', ...formProps }),
      ),
    [dispatch],
  );

  const startSiteTours = useCallback(() => {
    siteTourStarted('app_bar');
    dispatch(startAllSiteTours());
  }, [dispatch]);

  const googleLoginSuccess = useCallback(
    ({ credential }, redirect, role) => {
      dispatch(userActions.googleLogin(credential, redirect, 'oneTap', role));
    },
    [dispatch],
  );

  const siteTours = useSelector(state => state.siteTours.items);
  const showSiteTourBtn = useMemo(
    () => Boolean(Object.keys(siteTours).filter(tId => tId !== 'site_tour_help').length),
    [siteTours],
  );

  const color = useMemo(() => {
    const altPaths = [...ALT_PATHS, ...(VARIANTS[variant].altPaths || [])];
    const path = altPaths.find(p =>
      typeof p.path === 'string' ?
        p.exact ?
          p.path === location.pathname
        : location.pathname.startsWith(p.path)
      : p.path.test(location.pathname),
    );
    return !!path && (path.type || 'dark');
  }, [location.pathname, variant]);

  const colorOverride = useSelector(state => state.appState.app_bar_color_override);

  const routes = useMemo(
    () => ({
      // main links on the left side of appbar
      main: VARIANTS[variant].left,

      partners: partnerUsers,

      // in the dropdown menu
      // links at the top
      collapsed: VARIANTS[variant].collapsed,

      // buttons in
      resources: [
        { onClick: showPrivacyPolicy, label: 'Privacy Policy' },
        // { onClick: toggleContactUsForm, label: 'Contact Us' },
        { href: 'https://help.wizeprep.com', label: 'Help Center' },
        !currentUser.guest && { path: invitesPagePath(), label: 'Invite a Friend' },
      ].filter(Boolean),

      // additional links depending on role
      role: ROLE_ROUTES.filter(([roles]) => hasAnyRoles(currentUser, roles)).map(x => x[1]),
    }),
    [currentUser, partnerUsers, showPrivacyPolicy, /*toggleContactUsForm,*/ variant],
  );

  // don't render the app bar on these routes
  if (NO_APPBAR_ROUTES.some(r => (typeof r === 'string' ? location.pathname.startsWith(r) : r.test(location.pathname))))
    return null;

  return (
    <AppBar
      color={colorOverride || color}
      currentUser={currentUser}
      darkMode={darkMode}
      hasChats={hasChats}
      homePath={homePath}
      location={location}
      onChangeDarkMode={onChangeDarkMode}
      onGoogleLoginSuccess={googleLoginSuccess}
      onSignOut={signOut}
      onStartSiteTour={showSiteTourBtn && startSiteTours}
      onToggleLoginForm={toggleLoginForm}
      onToggleSignUpForm={toggleSignUpForm}
      routes={routes}
      siteBannerActive={siteBannerActive}
    />
  );
}
