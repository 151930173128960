import API from 'my-core/api';
import { userLogInCompleted, userSignUpCompleted } from 'my-core/gtm-events';
import standardApiRequestAction from 'my-core/standardApiRequestAction';

import types from '../constants/UserActionTypes';

// import warning from 'warning';

function addUser(user) {
  return {
    type: types.ADD_USER,
    user,
  };
}

export function fetchUsers(params = {}, fetchKey, page = 0) {
  return dispatch => {
    API.getJSON('/users', { ...params, page })
      .then(({ items, total }) => {
        dispatch({ type: types.FETCH_USERS_SUCCESS, items, total, fetchKey, page });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.FETCH_USERS_FAIL, fetchKey, error });
      });
  };
}

export function fetchUserV1(idOrSlug, params, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/users/${idOrSlug}`,
    method: 'get',
    requestKey: fetchKey,
    body: params,
  });
}

export function fetchUsersApi(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/users',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function fetchEducatorsApi(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/users/educators',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function fetchEducatorProfile(idOrSlug, params, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/users/${idOrSlug}/educator_profile`,
    method: 'get',
    requestKey: fetchKey,
    body: params,
  });
}

export function fetchRecommendedTutorsForStudent(studentId, params, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/users/recommended_tutors_for_student/${studentId}`,
    method: 'get',
    requestKey: fetchKey,
    body: params,
  });
}

export function fetchInstructorProfile(id) {
  // warning(false, 'UserActions#fetchInstructorProfile DEPRECATED. Use fetchEducatorProfile');
  return (dispatch, getState) => {
    const { status } = getState().users;
    const fetchStatus = status[`fetch_instructor_${id}`];
    if (fetchStatus && fetchStatus.fetching) return;

    dispatch({ type: types.FETCHING_INSTRUCTOR_PROFILE, id });
    API.getJSON(`/users/${id}/instructor_profile`)
      .then(instructor => {
        dispatch({ type: types.FETCH_INSTRUCTOR_PROFILE_SUCCESS, instructor, id });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.FETCH_INSTRUCTOR_PROFILE_FAIL, error, id });
      });
  };
}

export function fetchInstructorDashboardStats(userId, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/users/${userId}/instructor_dashboard`,
    requestKey: fetchKey,
  });
}

export function fetchMarketingRepDashboardStats(fetchKey) {
  return standardApiRequestAction({
    path: '/api/v1/users/marketing_rep_dashboard',
    requestKey: fetchKey,
  });
}

export function fetchUserInviteData() {
  return dispatch => {
    API.getJSON('/users/invite_data')
      .then(user => {
        dispatch({ type: types.FETCH_USER_INVITE_DATA_SUCCESS, user });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.FETCH_USER_INVITE_DATA_FAIL, error });
      });
  };
}

export function updateUserApi(id, params) {
  return standardApiRequestAction({
    path: `/api/v1/users/${id}`,
    method: 'put',
    body: params,
    destroy: { userCourses: params.user?.user_courses_attributes?.filter(uc => uc.id && uc._destroy).map(uc => uc.id) },
  });
}

export function confirmUserEmail(id) {
  return standardApiRequestAction({
    path: `/api/v1/users/${id}/confirm`,
    method: 'patch',
  });
}

export function signInAsUser(userId) {
  return () => {
    API.postJSON(`/admin/users/${userId}/sign_in_as`).then(() => {
      window.location.href = '/';
    });
  };
}

export function deactivateUser(userId) {
  return standardApiRequestAction({
    path: `/api/v1/users/${userId}/deactivate`,
    method: 'delete',
  });
}

export function fetchRtcToken(userId) {
  return dispatch => {
    API.getJSON('/rtc/token').then(json => {
      dispatch(addUser({ id: userId, rtcAccessToken: json.token }));
    });
  };
}

export function fetchStudentStudyStats(userId, requestKey) {
  return standardApiRequestAction({
    path: `/api/v1/users/${userId}/study_stats`,
    requestKey,
  });
}

// User Login/Sign up

export function emailLogin({ email, password, rememberMe }, redirect, source) {
  return dispatch => {
    API.postJSON('/users/sign_in', { user: { email, password, remember_me: rememberMe } })
      .then(user => {
        _handleLoginSuccess(redirect, false, dispatch, user, 'email-login', source);
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.USER_LOGIN_ERROR, error });
      });
  };
}

export function facebookLogin({ accessToken, signedRequest, userID }, redirect, source, signupRole) {
  return dispatch => {
    API.postJSON('/omniauth', {
      provider: 'facebook',
      token: accessToken,
      signed_request: signedRequest,
      fb_uid: userID,
      signup_role: signupRole,
    })
      .then(({ is_new, user }) => {
        _handleLoginSuccess(redirect, is_new, dispatch, user, 'facebook', source);
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.USER_LOGIN_ERROR, error });
      });
  };
}

export function googleLogin(token, redirect, source, signupRole) {
  return dispatch => {
    API.postJSON('/omniauth', { provider: 'google', token, signup_role: signupRole })
      .then(({ is_new, user }) => {
        _handleLoginSuccess(redirect, is_new, dispatch, user, 'google', source);
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.USER_LOGIN_ERROR, error });
      });
  };
}

export function userSignUp(user, redirect, source, signupRole) {
  return dispatch => {
    API.postJSON('/users', { user, signup_role: signupRole })
      .then(user => {
        _handleLoginSuccess(redirect, true, dispatch, user, 'email-signup', source);
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: types.USER_SIGN_UP_ERROR, error });
      });
  };
}

function _handleLoginSuccess(redirect, newUser, dispatch, user, method, source) {
  const reload = () => {
    if (typeof redirect === 'function') {
      dispatch({ type: types.USER_LOGIN_SUCCESS, user });
      redirect();
    } else {
      if (!redirect && newUser) {
        if (user.roles?.includes('teacher')) {
          redirect = '/';
        }
      }
      redirect ? (window.location.href = redirect)
      : (
        window.location.search.includes('reset_password_token') // don't reload with reset_password_token param or it will sign them out again
      ) ?
        (window.location.href = '/')
      : window.location.reload(true);
    }
  };
  (newUser ? userSignUpCompleted : userLogInCompleted)(reload, user, method, source);
}

export function resetUserPassword(email) {
  return dispatch => {
    API.postJSON('/users/password', { user: { email } })
      .then(() => {
        dispatch({ type: types.USER_PASSWORD_RESET_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.USER_PASSWORD_RESET_FAIL, error });
      });
  };
}

export function updateUserPassword(user) {
  return dispatch => {
    API.putJSON('/users/password', { user })
      .then(() => {
        window.location.href = '/';
      })
      .catch(error => {
        dispatch({ type: types.USER_UPDATE_PASSWORD_FAIL, error });
      });
  };
}

export function resendConfirmation(email) {
  return dispatch => {
    API.getJSON('/users/confirmations/resend_instructions', { email })
      .then(() => {
        dispatch({ type: types.USER_CONFIRMATION_RESEND_SUCCESS, email });
      })
      .catch(error => {
        dispatch({ type: types.USER_CONFIRMATION_RESEND_FAIL, error });
      });
  };
}

export function resendUserPasswordResetEmail(userId) {
  return standardApiRequestAction({
    path: `/api/v1/users/${userId}/resend_password_reset`,
    method: 'post',
  });
}

export function resendUserConfirmationEmail(userId) {
  return standardApiRequestAction({
    path: `/api/v1/users/${userId}/resend_confirmation`,
    method: 'post',
  });
}

export function acceptUserContract(params) {
  return standardApiRequestAction({
    path: '/api/v1/users/accept_contract',
    body: params,
    method: 'put',
  });
}
