import { chatNotification, tokensAddedSound } from 'my-core/sound';
import { standardPushUpdate } from 'my-core/standardApiRequestAction';

import { fetchChat } from './ChatActions';
import { addNotification, removeNotification } from './NotificationActions';

export function handleNotification(notification) {
  switch (notification.action) {
    case 'NEW_CHAT_MESSAGE':
      chatNotification.play();
      return newChatMessage(notification);
    case 'STUDY_ROOM_INVITE':
      return newStudyRoomInvite(notification);
    case 'STUDY_ROOM_CANCEL_INVITE':
      return removeNotification(notification.id);
    case 'NEW_NOTIFICATION':
      return dispatch => {
        if (notification.notification.data.attributes.type === 'TokenCredit') tokensAddedSound.play();
        dispatch(standardPushUpdate(notification.notification));
        if (notification.payload) {
          dispatch(standardPushUpdate(notification.payload));
        }
      };
    case 'DISPATCH_ACTION':
      return notification.payload;
    case 'STANDARD_PUSH_UPDATE':
      return standardPushUpdate(notification.payload);
    default:
      return () => {};
  }
}

function newChatMessage({ message }) {
  return (dispatch, getState) => {
    const chatId = message.data.relationships.chat.data.id;
    const chat = getState().chats.items[chatId];
    if (!chat) {
      fetchChat(chatId, { include: ['users', 'last_message'] })(dispatch, getState);
    } else {
      dispatch(standardPushUpdate(message));
    }
  };
}

function newStudyRoomInvite(notification) {
  return dispatch => {
    dispatch(
      addNotification({
        ...notification,
        active: true,
        type: 'STUDY_ROOM_INVITE',
      }),
    );
    setTimeout(() => {
      dispatch(removeNotification(notification.id));
    }, 9000);
  };
}
