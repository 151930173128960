import types from '../constants/OnlineCourseActionTypes';
import ocUserTypes from '../constants/OnlineCourseUserActionTypes';
import { addItems, removeItem } from './utils';

function addOnlineCourses(items, state) {
  return addItems(items, onlineCourse, types.ADD_ONLINE_COURSE, 'onlineCourse', state);
}

function updateActivities(state = [], activities) {
  return activities ?
      activities.map(activity => {
        const stateActivity = state.find(a2 => a2.id === activity.id);
        return {
          ...stateActivity,
          ...activity,
        };
      })
    : state;
}
function updateSections(state = [], sections) {
  return sections ?
      sections.map(section => {
        const stateSection = state.find(s2 => s2.id === section.id);
        const stateActivities = (stateSection && stateSection.activities) || [];
        return {
          ...stateSection,
          ...section,
          activities: updateActivities(stateActivities, section.activities),
        };
      })
    : state;
}

function updateChapters(state = [], chapters) {
  return chapters ?
      chapters.map(chapter => {
        const stateChapter = state.find(c2 => c2.id === chapter.id);
        const stateSections = (stateChapter && stateChapter.sections) || [];
        return {
          ...stateChapter,
          ...chapter,
          sections: updateSections(stateSections, chapter.sections),
        };
      })
    : state;
}

function onlineCourse(state = {}, action) {
  switch (action.type) {
    case types.ADD_ONLINE_COURSE:
    case types.CREATE_ONLINE_COURSE_SUCCESS:
    case types.UPDATE_ONLINE_COURSE_SUCCESS:
    case types.FETCH_ONLINE_COURSE_SUCCESS:
      return {
        ...state,
        ...action.onlineCourse,
        chapters: updateChapters(state.chapters, action.onlineCourse.chapters),
      };
    default:
      return state;
  }
}

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function onlineCourses(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ONLINE_COURSE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.id || action.onlineCourse.id]: onlineCourse(state.items[action.id || action.onlineCourse.id], action),
        },
      };
    case types.ADD_ONLINE_COURSES:
      return addOnlineCourses(action.onlineCourses, state);

    case types.FETCH_ONLINE_COURSE_SUCCESS:
      return addOnlineCourses([action.onlineCourse], {
        ...state,
        status: { ...state.status, [`fetch_${action.onlineCourse.id}`]: { success: true, fetched_at: Date.now() } },
      });
    case types.FETCH_ONLINE_COURSE_FAIL:
      return {
        ...state,
        status: { ...state.status, [`fetch_${action.onlineCourseId}`]: { success: false, error: action.error } },
      };

    case types.FETCH_ONLINE_COURSE_BOOKLET_SUCCESS:
      return addOnlineCourses([action.onlineCourse], {
        ...state,
        status: { ...state.status, [action.fetchKey]: { success: true, fetched_at: Date.now() } },
      });
    case types.FETCH_ONLINE_COURSE_BOOKLET_FAIL:
      return {
        ...state,
        status: { ...state.status, [action.fetchKey]: { success: false, error: action.error } },
      };

    case types.FETCHING_ONLINE_COURSES:
      return {
        ...state,
        status: {
          ...state.status,
          fetchingOnlineCourse: 'fetching',
        },
      };

    case types.FETCH_ONLINE_COURSE_INSTRUCTOR_OVERVIEW_SUCCESS:
      return addOnlineCourses([action.onlineCourse], state);
    case types.FETCH_ONLINE_COURSE_INSTRUCTOR_OVERVIEW_FAIL:
      return { ...state, status: { ...state.status, instructor_overview_fetch_error: action.error } };

    case types.REMOVE_ONLINE_COURSE:
      return removeItem(state, action.id);

    case types.CREATE_ONLINE_COURSE_SUCCESS:
      return addOnlineCourses([action.onlineCourse], {
        ...state,
        status: { ...state.status, create: { success: action.onlineCourse.id } },
      });
    case types.CREATE_ONLINE_COURSE_FAIL:
      return { ...state, status: { ...state.status, create: { error: action.error } } };

    case types.UPDATE_ONLINE_COURSE_FETCHING:
      return {
        ...state,
        status: { ...state.status, update: { fetching: true } },
      };
    case types.UPDATE_ONLINE_COURSE_SUCCESS:
      return addOnlineCourses([action.onlineCourse], {
        ...state,
        status: { ...state.status, update: { success: action.onlineCourse.id } },
      });
    case types.UPDATE_ONLINE_COURSE_FAIL:
      return { ...state, status: { ...state.status, update: { error: action.error } } };

    case types.FETCH_ONLINE_COURSE_STATS_SUCCESS:
      return addOnlineCourses([action.onlineCourse], {
        ...state,
        status: {
          ...state.status,
          [`fetch_stats_${action.onlineCourse.id}`]: { success: true, fetched_at: Date.now() },
        },
      });
    case types.FETCH_ONLINE_COURSE_STATS_FAIL:
      return {
        ...state,
        status: { ...state.status, [`fetch_stats_${action.onlineCourseId}`]: { error: action.error } },
      };

    case types.FETCH_ONLINE_COURSE_EDITOR_ACTIVITIES_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          fetch_editor_activities: {
            ...state.status.fetch_editor_activities,
            [action.fetchKey]: { success: true },
          },
        },
      };
    case types.FETCH_ONLINE_COURSE_EDITOR_ACTIVITIES_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          fetch_editor_activities: {
            ...state.status.fetch_editor_activities,
            [action.fetchKey]: { error: action.error },
          },
        },
      };

    case ocUserTypes.PURCHASE_ONLINE_COURSE_USER_SUCCESS:
      if (action.onlineCourse) return addOnlineCourses([action.onlineCourse], state);
      return state;

    // ADMIN ACTION TYPES
    case types.FETCH_ADMIN_ONLINE_COURSES_SUCCESS:
      return addOnlineCourses(action.onlineCourses, {
        ...state,
        status: { ...state.status, admin_fetch: { success: true } },
      });
    case types.FETCH_ADMIN_ONLINE_COURSES_FAIL:
      return { ...state, status: { ...state.status, admin_fetch: { error: action.error } } };

    case types.ADD_USER_TO_ONLINE_COURSE_SUCCESS:
      return { ...state, status: { ...state.status, [`add_user_to_course_${action.courseId}`]: { success: true } } };
    case types.ADD_USER_TO_ONLINE_COURSE_FAIL:
      return {
        ...state,
        status: { ...state.status, [`add_user_to_course_${action.courseId}`]: { error: action.error } },
      };

    default:
      return state;
  }
}
