import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_ONLINE_COURSE_ANNOUNCEMENT',
  'FETCH_ONLINE_COURSE_ANNOUNCEMENT_SUCCESS',
  'FETCH_ONLINE_COURSE_ANNOUNCEMENT_FAIL',
  'FETCH_ONLINE_COURSE_ANNOUNCEMENTS_SUCCESS',
  'FETCH_ONLINE_COURSE_ANNOUNCEMENTS_FAIL',
  'CREATE_ONLINE_COURSE_ANNOUNCEMENT_SUCCESS',
  'CREATE_ONLINE_COURSE_ANNOUNCEMENT_FAIL',
  'UPDATE_ONLINE_COURSE_ANNOUNCEMENT_SUCCESS',
  'UPDATE_ONLINE_COURSE_ANNOUNCEMENT_FAIL',
  'DELETE_ONLINE_COURSE_ANNOUNCEMENT_SUCCESS',
  'DELETE_ONLINE_COURSE_ANNOUNCEMENT_FAIL',
]);

export default actionTypes;
