import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_TUTOR_AVAILABILITIES',
  'FETCH_TUTOR_AVAILABILITIES_SUCCESS',
  'FETCH_TUTOR_AVAILABILITIES_FAIL',

  'BATCH_UPDATE_TUTOR_AVAILABILITIES',
  'BATCH_UPDATE_TUTOR_AVAILABILITIES_SUCCESS',
  'BATCH_UPDATE_TUTOR_AVAILABILITIES_FAIL',
]);

export default actionTypes;
