import types from '../constants/SearchActionTypes';

const initialState = {
  current: '',
  results: {},
  searching: false,
};

function searchContext(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_SEARCH_QUERY:
      return {
        ...state,
        current: action.query,
        previous: state.results[state.current] ? state.current : state.previous,
        searching: action.searching,
      };
    case types.PERFORMING_SEARCH:
      return {
        ...state,
        searching: true,
      };
    case types.FETCH_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searching: action.query === state.current ? false : state.searching,
        error: undefined,
        results: { ...state.results, [action.query]: action.results },
      };
    case types.FETCH_SEARCH_RESULTS_FAIL:
      return {
        ...state,
        searching: action.query === state.current ? false : state.searching,
        error: action.error,
      };
    default:
      return state;
  }
}

export default function search(state = {}, action) {
  switch (action.type) {
    case types.SET_CURRENT_SEARCH_QUERY:
    case types.PERFORMING_SEARCH:
    case types.FETCH_SEARCH_RESULTS_SUCCESS:
    case types.FETCH_SEARCH_RESULTS_FAIL:
      return {
        ...state,
        [action.contextKey]: searchContext(state[action.contextKey], action),
      };
    default:
      return state;
  }
}
