import { UAParser } from 'ua-parser-js';

// const { ua, browser, cpu, device, engine, os } = UAParser();
const { device, os } = UAParser();

export function isMobileDevice() {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Device_Detection
  return device.type === 'mobile';
}

export function isMacOS() {
  return os.name === 'macOS';
}

export function isTouchEnabled() {
  return 'ontouchstart' in window || window.navigator?.maxTouchPoints > 0 || window.navigator?.msMaxTouchPoints > 0;
}

const IS_IPAD_PRO = window.navigator?.platform === 'MacIntel' && window.navigator?.maxTouchPoints > 1;
export function isIos() {
  return IS_IPAD_PRO || os.name === 'iOS';
}
