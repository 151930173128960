import sortBy from 'sort-by';

import types from '../constants/SiteTourActionTypes';

function updateSiteTour(siteTourId, state, action) {
  return {
    ...state,
    items: {
      ...state.items,
      [siteTourId]: siteTour(state.items[siteTourId], action),
    },
  };
}

function siteTour(state = { steps: [] }, action) {
  switch (action.type) {
    case types.ADD_SITE_TOUR_STEP:
      return { ...state, id: action.siteTourId, steps: [action.step, ...state.steps].sort(sortBy('order')) };
    case types.REMOVE_SITE_TOUR_STEP:
      return { ...state, steps: state.steps.filter(step => step.id !== action.stepId) };
    default:
      return state;
  }
}

const initialState = {
  items: {},
  status: { running: false, viewed: [] },
};

export default function siteTours(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SITE_TOUR_STEP:
      return updateSiteTour(action.siteTourId, state, action);
    case types.REMOVE_SITE_TOUR_STEP: {
      const rsState = updateSiteTour(action.siteTourId, state, action);
      if (rsState.items[action.siteTourId].steps.length === 0) delete rsState.items[action.siteTourId];
      return rsState;
    }
    case types.START_ALL_SITE_TOURS:
      return {
        ...state,
        items: Object.values(state.items).reduce((mem, tour) => ((mem[tour.id] = { ...tour, run: true }), mem), {}),
        status: { ...state.status, running: true },
      };
    case types.END_ALL_SITE_TOURS:
      return {
        ...state,
        items: Object.values(state.items).reduce((mem, tour) => ((mem[tour.id] = { ...tour, run: false }), mem), {}),
        status: { ...state.status, running: false },
      };
    case types.VIEWED_SITE_TOUR:
      return { ...state, status: { ...state.status, viewed: [...state.status.viewed, action.siteTourId] } };
    case types.VIEWED_SITE_TOURS:
      return { ...state, status: { ...state.status, viewed: [...state.status.viewed, ...action.siteTourIds] } };
    default:
      return state;
  }
}
