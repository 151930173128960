import loadable from '@loadable/component';
import memoize from 'memoize-one';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sortBy from 'sort-by';

import { siteTourStarted } from 'my-core/gtm-events';
import { debounce } from 'my-utils';

import * as messageActions from 'my-actions/MessageActions';
import * as siteTourActions from 'my-actions/SiteTourActions';

const SiteTour = loadable(() => import('./SiteTour'));

class SiteTourManager extends Component {
  promptedTourIds = [];

  componentDidUpdate(prevProps) {
    const { runningUnviewedTourIds, siteTourActions, unviewedTourIds } = this.props;
    if (!prevProps.unviewedTourIds && unviewedTourIds) {
      this.debouncedShowStartTourMessage();
    }
    if (prevProps.runningUnviewedTourIds !== runningUnviewedTourIds && runningUnviewedTourIds.length) {
      siteTourActions.viewedSiteTours(runningUnviewedTourIds);
    }
  }

  showStartTourMessage = () => {
    const { messageActions, siteTourActions, unviewedTourIds } = this.props;
    if (unviewedTourIds && !unviewedTourIds.every(id => this.promptedTourIds.includes(id))) {
      this.promptedTourIds = this.promptedTourIds.concat(unviewedTourIds);
      // siteTourActions.startAllSiteTours();
      messageActions.addMessage({
        action: {
          children: 'Start Tour',
          onClick: () => {
            siteTourActions.startAllSiteTours();
            siteTourStarted('toast_message');
          },
        },
        content: 'Want a tour?',
        type: 'info',
      });
    }
  };
  debouncedShowStartTourMessage = debounce(this.showStartTourMessage, 1000);

  render() {
    const { siteTourActions, steps } = this.props;

    return !!steps.length && <SiteTour onTourFinish={siteTourActions.endAllSiteTours} steps={steps} />;
  }
}

const getSiteTourManagerState = memoize((siteTours, viewedTours) => {
  const unviewedTourIds = [];
  const runningUnviewedTourIds = [],
    runningTours = [];
  Object.values(siteTours).forEach(t => {
    const viewed = viewedTours.includes(t.id);
    if (!viewed) unviewedTourIds.push(t.id);
    if (t.run) {
      runningTours.push(t);
      if (!viewed) runningUnviewedTourIds.push(t.id);
    }
  });

  return {
    steps: runningTours.flatMap(t => t.steps).sort(sortBy('order')),
    unviewedTourIds: !!unviewedTourIds.length && unviewedTourIds,
    runningUnviewedTourIds,
  };
});

export default connect(
  ({ siteTours }) => getSiteTourManagerState(siteTours.items, siteTours.status.viewed),
  dispatch => ({
    messageActions: bindActionCreators(messageActions, dispatch),
    siteTourActions: bindActionCreators(siteTourActions, dispatch),
  }),
)(SiteTourManager);
