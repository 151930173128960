import studyRoomTypes from '../constants/StudyRoomActionTypes';
import types from '../constants/WhiteboardActionTypes';
import { addItems, removeItem } from './utils';

function element(state = {}, action) {
  switch (action.type) {
    case types.WHITEBOARD_NEW_POINT:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          points: [...state.attributes.points, ...action.point],
        },
      };
    case types.WHITEBOARD_END_ELEMENT:
      return {
        ...state,
        completed: true,
      };
    case types.WHITEBOARD_UPDATE_ELEMENT:
    case types.WHITEBOARD_UPDATE_ELEMENTS: {
      const { attributes, textAttrs } = action.updates;
      const element = { ...state, ...action.updates };
      if (attributes) element.attributes = { ...state.attributes, ...attributes };
      if (textAttrs) element.textAttrs = { ...state.textAttrs, ...textAttrs };

      return element;
    }
    default:
      return state;
  }
}

const wbInitial = {
  background: {},
  cursors: {},
  elements: {},
};

function whiteboard(state = wbInitial, action) {
  switch (action.type) {
    case types.ADD_WHITEBOARD:
      return {
        ...state,
        ...action.whiteboard,
      };
    case types.WHITEBOARD_NEW_ELEMENT:
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.element.id]: action.element,
        },
      };
    case types.WHITEBOARD_NEW_ELEMENTS: {
      const elements = { ...state.elements };
      action.elements.forEach(el => (elements[el.id] = { ...el }));

      return { ...state, elements };
    }
    case types.WHITEBOARD_REMOVE_ELEMENT: {
      const newState = { ...state, elements: { ...state.elements } };
      delete newState.elements[action.elementId];
      return newState;
    }
    case types.WHITEBOARD_REMOVE_ELEMENTS:
      return {
        ...state,
        elements: Object.keys(state.elements).reduce((mem, id) => {
          if (!action.elementIds.includes(id)) mem[id] = state.elements[id];
          return mem;
        }, {}),
      };

    case types.WHITEBOARD_NEW_POINT:
    case types.WHITEBOARD_END_ELEMENT:
    case types.WHITEBOARD_UPDATE_ELEMENT: {
      if (!state.elements[action.elementId]) return state;
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.elementId]: element(state.elements[action.elementId], action),
        },
      };
    }
    case types.WHITEBOARD_UPDATE_ELEMENTS: {
      const elements = { ...state.elements };
      const { type } = action;
      Object.keys(action.updates).forEach(
        id => (elements[id] = element(state.elements[id], { type, id, updates: { ...action.updates[id] } })),
      );
      return { ...state, elements };
    }
    case types.WHITEBOARD_MOVE_CURSOR:
      return {
        ...state,
        cursors: {
          ...state.cursors,
          [action.cursor.clientId]: action.cursor,
        },
      };
    case types.WHITEBOARD_REMOVE_CURSOR: {
      const newState = { ...state, cursors: { ...state.cursors } };
      delete newState.cursors[action.client_id];
      return newState;
    }
    case types.WHITEBOARD_CLEAR_ALL:
      return {
        ...state,
        elements: {},
      };
    case types.WHITEBOARD_SET_BACKGROUND:
      return {
        ...state,
        background: action.background,
      };
    case types.WHITEBOARD_SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };
    default:
      return state;
  }
}

const initialState = {
  status: {},
  items: {},
};

export default function whiteboards(state = initialState, action) {
  switch (action.type) {
    case types.ADD_WHITEBOARD:
    case types.WHITEBOARD_NEW_ELEMENT:
    case types.WHITEBOARD_NEW_ELEMENTS:
    case types.WHITEBOARD_REMOVE_ELEMENT:
    case types.WHITEBOARD_REMOVE_ELEMENTS:
    case types.WHITEBOARD_NEW_POINT:
    case types.WHITEBOARD_END_ELEMENT:
    case types.WHITEBOARD_MOVE_CURSOR:
    case types.WHITEBOARD_REMOVE_CURSOR:
    case types.WHITEBOARD_CLEAR_ALL:
    case types.WHITEBOARD_SET_BACKGROUND:
    case types.WHITEBOARD_SET_ZOOM:
    case types.WHITEBOARD_UPDATE_ELEMENT:
    case types.WHITEBOARD_UPDATE_ELEMENTS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.id || action.whiteboard.id]: whiteboard(state.items[action.id || action.whiteboard.id], action),
        },
      };

    case types.FETCHING_WHITEBOARD:
      return {
        ...state,
        status: {
          ...state.status,
          fetchingWhiteboard: 'fetching',
        },
      };
    case types.FETCH_WHITEBOARD_SUCCESS:
      return addItems(action.whiteboards, whiteboard, types.ADD_WHITEBOARD, 'whiteboard', {
        ...state,
        status: {
          ...state.status,
          fetchingWhiteboard: Date.now(),
        },
      });
    case types.REMOVE_WHITEBOARD:
      return removeItem(state, action.id);
    case studyRoomTypes.UPDATE_STUDY_ROOM:
      return addItems(action.studyRoom.whiteboards, whiteboard, types.ADD_WHITEBOARD, 'whiteboard', state);
    case studyRoomTypes.DELETE_STUDY_ROOM_WHITEBOARD:
      return removeItem(state, action.whiteboardId);
    default:
      return state;
  }
}
