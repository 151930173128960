import types from '../constants/MessageActionTypes';
import { addItemsToState, removeItem } from './utils';

const initialState = {
  status: {},
  items: {},
};

export default function messages(state = initialState, action) {
  switch (action.type) {
    case types.ADD_MESSAGE:
      return addItemsToState(state, [{ created_at: Date.now(), ...action.message }]);
    case types.REMOVE_MESSAGE:
      return removeItem(state, action.id);
    default:
      return state;
  }
}
