import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_ONLINE_COURSE_POLL_SUCCESS',
  'FETCH_ONLINE_COURSE_POLL_FAIL',
  'FETCH_ONLINE_COURSE_POLLS_SUCCESS',
  'FETCH_ONLINE_COURSE_POLLS_FAIL',
  'CREATE_ONLINE_COURSE_POLL_SUCCESS',
  'CREATE_ONLINE_COURSE_POLL_FAIL',
  'UPDATE_ONLINE_COURSE_POLL_SUCCESS',
  'UPDATE_ONLINE_COURSE_POLL_FAIL',
  'DELETE_ONLINE_COURSE_POLL_SUCCESS',
  'DELETE_ONLINE_COURSE_POLL_FAIL',
]);

export default actionTypes;
