import { useEffect } from 'react';

import { insertScript } from 'my-core/script-utils';

function PrivacyPolicy() {
  useEffect(() => {
    insertScript('https://cdn.iubenda.com/iubenda.js', { skipCache: true, async: false });
  }, []);

  return (
    <a
      className="iubenda-black iubenda-embed iubenda-noiframe iub-body-embed"
      href="https://www.iubenda.com/privacy-policy/8202786"
      title="Privacy Policy"
    >
      Privacy Policy
    </a>
  );
  // return <iframe className={classes.root} src="//www.iubenda.com/privacy-policy/8202786/full-legal" />;
}

export default PrivacyPolicy;
