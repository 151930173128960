import types from '../constants/OnlineCourseSurveyActionTypes';
import {
  createItemFail,
  createItemSuccess,
  fetchItemFail,
  fetchItemsFail,
  fetchItemsSuccess,
  fetchItemSuccess,
  removeItemFail,
  removeItemSuccess,
  updateItemFail,
  updateItemSuccess,
} from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function onlineCourseSurveys(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ONLINE_COURSE_SURVEY_SUCCESS:
      return fetchItemSuccess(state, action);
    case types.FETCH_ONLINE_COURSE_SURVEY_FAIL:
      return fetchItemFail(state, action);

    case types.FETCH_ONLINE_COURSE_SURVEYS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_ONLINE_COURSE_SURVEYS_FAIL:
      return fetchItemsFail(state, action);

    case types.CREATE_ONLINE_COURSE_SURVEY_SUCCESS:
      return createItemSuccess(state, action);
    case types.CREATE_ONLINE_COURSE_SURVEY_FAIL:
      return createItemFail(state, action);

    case types.UPDATE_ONLINE_COURSE_SURVEY_SUCCESS:
      return updateItemSuccess(state, action);
    case types.UPDATE_ONLINE_COURSE_SURVEY_FAIL:
      return updateItemFail(state, action);

    case types.DELETE_ONLINE_COURSE_SURVEY_SUCCESS:
      return removeItemSuccess(state, action);
    case types.DELETE_ONLINE_COURSE_SURVEY_FAIL:
      return removeItemFail(state, action);

    default:
      return state;
  }
}
