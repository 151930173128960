import standardApiRequestAction from 'my-core/standardApiRequestAction';

import types from '../constants/ChatActionTypes';

export function fetchChat(chatId, params, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/chats/${chatId}`,
    requestKey: fetchKey,
    body: params,
  });
}

export function fetchChats(params, fetchKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/chats',
    parentRequestKey: fetchKey,
    requestKey: page,
    body: params,
  });
}

export function createChatWithUser(userId) {
  return standardApiRequestAction({
    path: `/api/v1/chats/create_with_user/${userId}`,
    method: 'post',
  });
}

export function markChatAsViewed(chatId) {
  return standardApiRequestAction({
    path: `/api/v1/chats/${chatId}/viewed`,
    method: 'put',
  });
}

/**
 * MESSAGES
 */

export function fetchChatMessages(chatId, beforeDate, fetchKey) {
  return standardApiRequestAction({
    path: `/api/v1/chats/${chatId}/messages`,
    body: { limit: 30, before_date: beforeDate },
    requestKey: beforeDate || 0,
    parentRequestKey: fetchKey,
  });
}

export function sendChatMessage(chatId, chatMessage) {
  return standardApiRequestAction({
    path: `/api/v1/chats/${chatId}/messages`,
    method: 'post',
    body: { chat_message: chatMessage },
  });
}

/**
 *  POPUP CHATS
 */
export function showPopupChat(chatId) {
  return {
    type: types.SHOW_POPUP_CHAT,
    chatId,
  };
}

export function hidePopupChat(chatId) {
  return {
    type: types.HIDE_POPUP_CHAT,
    chatId,
  };
}

export function removePopupChat(chatId) {
  return {
    type: types.REMOVE_POPUP_CHAT,
    chatId,
  };
}

export function removeAllPopupChats() {
  return { type: types.REMOVE_ALL_POPUP_CHATS };
}
