import { withStyles } from '@mui/styles';
import { PureComponent } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

class LoadingIndicator extends PureComponent {
  render() {
    const { centered, classes, ...rest } = this.props;

    const loader = <CircularProgress className={classes.svg} {...rest} />;

    return centered ? <div className={classes.centered}>{loader}</div> : loader;
  }
}

const styles = ({ palette }) => ({
  centered: { textAlign: 'center' },
  svg: { color: palette.primary.main },
});

export default withStyles(styles)(LoadingIndicator);
