import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'START_ALL_SITE_TOURS',
  'END_ALL_SITE_TOURS',
  'ADD_SITE_TOUR_STEP',
  'REMOVE_SITE_TOUR_STEP',
  'VIEWED_SITE_TOUR',
  'VIEWED_SITE_TOURS',
]);

export default actionTypes;
