import { useDispatch, useSelector } from 'react-redux';

import SiteWideBannerPrintBooklet, { BOOKLET_BANNER_KEY } from './SiteWideBannerPrintBooklet';

import { clearAppStateKey, toggleSiteWideBanner } from 'my-actions/AppStateActions';

export const BANNER_TRANSITION_DURATION = 300;

const classes = {
  banner: ({ constants, palette, transitions, zIndex }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    height: constants.APPBAR_HEIGHT,
    width: '100%',
    backgroundColor: palette.common.white,
    zIndex: zIndex.appBar,
    transform: 'translateY(-100%)',
    transition: transitions.create('transform', { duration: BANNER_TRANSITION_DURATION }),

    // for oc booklet print to pdf
    '@media print': { display: 'none' },
  }),
  banner_open: {
    transform: 'none',
  },
};

export { BOOKLET_BANNER_KEY };
const BANNER_BY_KEY = {
  [BOOKLET_BANNER_KEY]: SiteWideBannerPrintBooklet,
};

export default function SiteWideBannerNext() {
  const dispatch = useDispatch();
  const banner = useSelector(state => state.appState.site_wide_banner);

  const BannerComponent = BANNER_BY_KEY[banner?.key];
  const hasBanner = !!BannerComponent;
  const isBannerOpen = !!banner?.isOpen;

  function closeBanner() {
    dispatch(toggleSiteWideBanner(false));
    setTimeout(() => dispatch(clearAppStateKey('site_wide_banner')), BANNER_TRANSITION_DURATION);
  }

  return (
    <div css={[classes.banner, isBannerOpen && classes.banner_open]}>
      {hasBanner && <BannerComponent {...banner.props} onClose={closeBanner} />}
    </div>
  );
}
