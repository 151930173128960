import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-fetch';
import { PureComponent } from 'react';

import LoadingPage from 'my-components/LoadingPage';

import ClientError from './ClientError';

export default class MainErrorBoundary extends PureComponent {
  state = { error: null, eventId: null, hasRetried: false };
  retriedRequests = {};

  static getDerivedStateFromError(error) {
    return { error };
  }
  componentDidCatch(error, errorInfo) {
    if (error.name === 'ChunkLoadError') {
      let resp = null;
      fetch(error.request)
        .then(response => {
          resp = response;
          return response.text();
        })
        .then(text => {
          if (resp.ok && !this.retriedRequests[error.request]) {
            this.retriedRequests[error.request] = true;
            this.setState({ fetchingChunk: false, error: null });
          } else {
            const eventId = Sentry.captureException(error, {
              tags: { 'chunk-load-error': resp.status },
              extra: {
                response: {
                  status: resp.status,
                  statusText: resp.statusText,
                  body: text?.slice(0, 1000),
                },
              },
            });
            this.setState({ fetchingChunk: false, eventId, hasRetried: true });
            if (resp.status === 404) {
              // trying to fetch old version of chunk. reload page
              window.location.reload(true);
            }
          }
        })
        .catch(e => {
          // console.log('CHUNK FETCH ERROR:', e);
          const eventId = Sentry.captureException(error, {
            tags: { 'chunk-load-error': 'error' },
            extra: { error: e },
          });
          this.setState({ eventId, hasRetried: true });
          // some other error... handling tbd
        });
    } else {
      const eventId = Sentry.captureException(error, { extra: errorInfo });
      this.setState({ eventId });
    }
  }
  render() {
    const { error, eventId, hasRetried } = this.state;

    if (error) {
      if (error.name === 'ChunkLoadError' && !hasRetried) {
        return <LoadingPage />;
      }
      return (
        <ClientError
          error={error}
          eventId={eventId}
          onResetError={() => this.setState({ error: null, eventId: null })}
        />
      );
    }
    return this.props.children;
  }
}
