import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_STUDY_ROOM_SUCCESS',
  'FETCH_STUDY_ROOM_FAIL',
  'ADD_STUDY_ROOM_PARTICIPANT',
  'FETCH_STUDY_ROOM_PARTICIPANTS_SUCCESS',
  'FETCH_STUDY_ROOM_PARTICIPANTS_FAIL',
  'UPDATE_STUDY_ROOM',
  'UPDATE_STUDY_ROOM_PARTICIPANT',
  'REMOVE_STUDY_ROOM_PARTICIPANTS',
  'DELETE_STUDY_ROOM_WHITEBOARD',
  'ADD_STUDY_ROOM_PARTICIPANT_TRACKS',
  'REMOVE_STUDY_ROOM_PARTICIPANT_TRACK',
  'STUDY_ROOM_RTC_DOMINANT_SPEAKER_CHANGED',
  'STUDY_ROOM_RTC_PARTICIPANT_CONNECTED',
  'STUDY_ROOM_RTC_PARTICIPANT_DISCONNECTED',
  'STUDY_ROOM_RTC_RECONNECTED',
  'STUDY_ROOM_RTC_RECONNECTING',
  'STUDY_ROOM_RTC_RECORDING_STARTED',
  'STUDY_ROOM_RTC_RECORDING_STOPPED',
  'STUDY_ROOM_RTC_TRACK_DISABLED',
  'STUDY_ROOM_RTC_TRACK_ENABLED',
  'STUDY_ROOM_RTC_TRACK_PUBLISHED',
  'STUDY_ROOM_RTC_TRACK_STARTED',
  'STUDY_ROOM_RTC_TRACK_SUBSCRIBED',
  'STUDY_ROOM_RTC_TRACK_SUBSCRIPTION_FAILED',
  'STUDY_ROOM_RTC_TRACK_UNPUBLISHED',
  'STUDY_ROOM_RTC_TRACK_UNSUBSCRIBED',
  'ADD_STUDY_ROOM_ATTACHMENT',
  'REMOVE_STUDY_ROOM_ATTACHMENT',
  'STUDY_ROOM_START_BILLING_REQUEST',
  'STUDY_ROOM_START_BILLING_REQUEST_ACCEPTED',
  'STUDY_ROOM_START_BILLING_REQUEST_DECLINED',
]);

export default actionTypes;
