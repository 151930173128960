import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_FAQ',
  'ADD_FAQS',
  'FETCH_FAQS_SUCCESS',
  'FETCH_FAQS_FAIL',
  'CREATING_FAQ',
  'CREATE_FAQ_SUCCESS',
  'CREATE_FAQ_FAIL',
  'UPDATING_FAQ',
  'UPDATE_FAQ_SUCCESS',
  'UPDATE_FAQ_FAIL',
  'DELETE_FAQ_SUCCESS',
  'DELETE_FAQ_FAIL',
  'UPDATE_FAQS_ORDERING_SUCCESS',
]);

export default actionTypes;
