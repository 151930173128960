import { Intercom } from 'my-core/tag-manager';

import types from '../constants/AppStateActionTypes';

export function toggleLoginForm(toggle) {
  // { page, prompt, redirect, hidden }
  return { type: types.TOGGLE_APPSTATE_LOGIN_FORM, toggle };
}

export function toggleSearch(toggle) {
  return { type: types.TOGGLE_APPSTATE_SEARCH, toggle };
}

export function toggleCheckoutForm(toggle) {
  return { type: types.TOGGLE_APPSTATE_CHECKOUT_FORM, toggle };
}

export function toggleContactUsForm(prepopulatedMessage) {
  // return {
  //   type: types.TOGGLE_APPSTATE_CONTACT_US_FORM, toggle
  // }
  return () => {
    Intercom('showNewMessage', prepopulatedMessage);
  };
}

export function togglePrivacyPolicy(toggle) {
  return { type: types.TOGGLE_APPSTATE_PRIVACY_POLICY, toggle };
}

export function toggleTermsAndConditions(toggle) {
  return { type: types.TOGGLE_APPSTATE_TERMS_AND_CONDITIONS, toggle };
}

export function toggleReauthenticationForm(toggle) {
  return { type: types.TOGGLE_APPSTATE_REAUTHENTICATION_FORM, toggle };
}

export function setAppBarColorOverride(color) {
  return { type: types.SET_APP_BAR_COLOR_OVERRIDE, color };
}

export function toggleSiteWideBanner(toggle, payload) {
  return { type: types.TOGGLE_SITE_WIDE_BANNER, toggle, payload };
}

export function clearAppStateKey(key) {
  return { type: types.CLEAR_APPSTATE_KEY, key };
}
