import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-fetch';
import ReactOnRails from 'react-on-rails';

class API {
  static instance = null;

  static getInstance() {
    API.instance ||= new API();
    return API.instance;
  }

  constructor() {
    ['post', 'delete', 'patch', 'put'].forEach(method => {
      this[`${method}JSON`] = (path, data) => {
        return this._jsonRequest(method, path, data);
      };
    });
  }

  getJSON(path, data) {
    const fullPath = `${path}?${param(data)}`;
    return this._jsonRequest('get', fullPath);
  }

  getHeaders(headers) {
    return new window.Headers({
      Accept: 'application/json',
      ...ReactOnRails.authenticityHeaders(),
      ...headers,
    });
  }

  _jsonRequest(type, path, data, formData = false) {
    const fetchOpts = {
      method: type.toUpperCase(),
      credentials: 'same-origin',
      headers: this.getHeaders(formData ? undefined : { 'Content-Type': 'application/json' }),
    };

    if (data) {
      fetchOpts.body = formData ? this.toFormData(data) : JSON.stringify(data);
    }
    return new Promise(this._performFetch(path, fetchOpts, true));
  }

  _performFetch = (path, fetchOpts, firstAttempt) => (resolve, reject) => {
    let status = null;
    fetch(path, fetchOpts)
      .then(response => {
        status = response.status;
        Sentry.addBreadcrumb({
          type: 'http',
          category: 'fetch',
          data: {
            method: fetchOpts.method,
            url: path,
            status_code: status,
          },
        });
        const contentType = response.headers.get('content-type');
        if (status !== 204 && contentType && contentType.match(/json/)) {
          return response.json();
        }
        return response.text();
      })
      .then(json => {
        if (status < 200 || status >= 300) {
          // special handling for CSRF error
          if (firstAttempt && status === 400 && json.error_code === 'csrf_auth_failed' && json.csrf_token) {
            const metaTag = document.querySelector('meta[name="csrf-token"]');
            if (metaTag) metaTag.content = json.csrf_token;
            fetchOpts.headers.set('X-CSRF-Token', json.csrf_token);
            this._performFetch(path, fetchOpts)(resolve, reject);
          } else {
            // some endpoints return errors directly in object.  Some store it in 'error' or 'errors' field
            // Normalize this so error object is always for form { status, body }
            reject({
              status,
              body:
                json.error ? json.error
                : json.errors ? json.errors
                : json,
              meta: json.meta,
            });
            // reject({ status, ...(jsonResp ? json : { error: json }) });
          }
        } else {
          resolve(json);
        }
      })
      .catch(err => {
        const error = err instanceof Error ? err.toString() : err;
        Sentry.addBreadcrumb({
          type: 'http',
          category: 'fetch',
          data: {
            method: fetchOpts.method,
            url: path,
            status_code: status,
            reason: error,
          },
        });

        reject({ status, error });
      });
  };

  putFormData(path, data) {
    return this._jsonRequest('PUT', path, data, true);
  }
  postFormData(path, data) {
    return this._jsonRequest('POST', path, data, true);
  }

  toFormData(obj, form, namespace) {
    const fd = form || new window.FormData();
    for (const property in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(property)) {
        const formKey = namespace ? `${namespace}[${property}]` : property;
        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          if (obj[property] instanceof Array && obj[property].length && typeof obj[property][0] !== 'object') {
            obj[property].forEach(val => fd.append(`${formKey}[]`, val));
          } else {
            this.toFormData(obj[property], fd, formKey);
          }
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  }
}

export function param(sourceObject) {
  const querystring = [];

  function add(key, value) {
    if (value !== undefined) {
      if (value === null) value = '';
      querystring.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  function buildParams(prefix, obj) {
    if (Array.isArray(obj)) {
      obj.forEach(val => buildParams(`${prefix}[]`, val));
    } else if (obj && typeof obj === 'object') {
      for (const name in obj) {
        buildParams(`${prefix}[${name}]`, obj[name]);
      }
    } else {
      add(prefix, obj);
    }
  }

  // encode params recursively.
  for (const prefix in sourceObject) {
    buildParams(prefix, sourceObject[prefix]);
  }
  return querystring.join('&').replace(/%20/g, '+');
}

export default API.getInstance();
