// import { addItems, removeItem } from './utils';
import { v4 as uuidV4 } from 'uuid';

import types from '../constants/StudyRoomActionTypes';
import whiteboardTypes from '../constants/WhiteboardActionTypes';

// TODO: Should be able to simplify and remove a lot of code as we no longer use handle redux for a lot of state

function addOrUpdateParticipant(studyRoom, clientId, cb) {
  return {
    ...studyRoom,
    participants:
      studyRoom.participants.find(p => p.client_id === clientId) ?
        studyRoom.participants.map(p => (p.client_id === clientId ? cb({ ...p, tracks: p.tracks || [] }) : p))
      : [...studyRoom.participants, cb({ client_id: clientId, tracks: [] })],
  };
}

function updateRTCParticipant(studyRoom, participant, cb) {
  const clientId = participant.identity.split(':')[1];
  return addOrUpdateParticipant(studyRoom, clientId, cb);
}

function updateRTCParticipantTrack(studyRoom, participant, trackSid, cb) {
  return updateRTCParticipant(studyRoom, participant, p => ({
    ...p,
    tracks: p.tracks.map(t => (t.sid === trackSid ? cb(t) : t)),
  }));
}

function hasParticipant(participant, studyRoom) {
  const clientId = participant.identity.split(':')[1];
  return studyRoom.participants.some(p => p.client_id === clientId);
}

const initialStudyRoom = {
  participants: [],
  whiteboards: [],
};

function studyRoom(state = initialStudyRoom, action) {
  switch (action.type) {
    case types.FETCH_STUDY_ROOM_SUCCESS:
    case types.UPDATE_STUDY_ROOM:
      return {
        ...state,
        ...action.studyRoom,
        participants:
          action.studyRoom.participants ?
            action.studyRoom.participants.map(p => ({
              ...state.participants.find(p2 => p2.client_id === p.client_id),
              ...p,
            }))
          : state.participants, // to avoid overwritting tracks data
      };

    case types.ADD_STUDY_ROOM_PARTICIPANT:
    case types.UPDATE_STUDY_ROOM_PARTICIPANT:
      return addOrUpdateParticipant(state, action.participant.client_id, p => ({ ...p, ...action.participant }));

    case types.FETCH_STUDY_ROOM_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.participants.map(p => ({
          ...state.participants.find(p2 => p2.client_id === p.client_id),
          ...p,
        })), // to avoid overwritting tracks data
      };

    case types.REMOVE_STUDY_ROOM_PARTICIPANTS:
      return {
        ...state,
        participants: state.participants.filter(p => !action.clientIds.includes(p.client_id)),
        billing_started_at: null,
      };

    case whiteboardTypes.ADD_WHITEBOARD:
      // if it includes override (initial wb load or renamed)
      // if not included then add (wb created)
      return {
        ...state,
        whiteboards:
          state.whiteboards.some(wb => wb.id === (action.id || action.whiteboard.id)) ?
            state.whiteboards.map(wb =>
              wb.id === (action.id || action.whiteboard.id) ?
                {
                  id: (action.whiteboard && action.whiteboard.id) || wb.id,
                  name: (action.whiteboard && action.whiteboard.name) || wb.name,
                  study_room_id: (action.whiteboard && action.whiteboard.study_room_id) || wb.study_room_id,
                }
              : wb,
            )
          : [
              ...state.whiteboards,
              {
                id: action.whiteboard.id,
                name: action.whiteboard.name,
                study_room_id: action.whiteboard.study_room_id,
              },
            ],
      };
    case types.DELETE_STUDY_ROOM_WHITEBOARD:
      return {
        ...state,
        whiteboards: state.whiteboards.filter(wb => wb.id !== action.whiteboardId),
        active_whiteboard_id:
          action.nextActiveId || (state.whiteboards.find(wb => wb.id !== action.whiteboardId) || {}).id || null,
        // after || is for demo when no `nextActiveId` is provided
        // action.whiteboardId == state.active_whiteboard_id ? undefined : state.active_whiteboard_id
      };

    case types.ADD_STUDY_ROOM_PARTICIPANT_TRACKS:
      return addOrUpdateParticipant(state, action.clientId, p => ({
        ...p,
        rtcConnected: true,
        tracks: [
          ...(p.tracks || []),
          ...action.tracks.map(t => ({
            sid: t.sid || uuidV4(),
            name: t.name,
            kind: t.kind,
            isEnabled: t.isEnabled,
            isStarted: true,
            isSubscribed: true,
            track: t,
          })),
        ],
      }));

    case types.REMOVE_STUDY_ROOM_PARTICIPANT_TRACK:
      return addOrUpdateParticipant(state, action.clientId, p => ({
        ...p,
        tracks: p.tracks.filter(t => t.sid !== action.track.sid),
      }));

    case types.STUDY_ROOM_RTC_DOMINANT_SPEAKER_CHANGED:
      return { ...state, dominant_speaker: action.participant.identity.split(':')[1] };
    case types.STUDY_ROOM_RTC_PARTICIPANT_CONNECTED:
      return updateRTCParticipant(state, action.participant, p => ({
        ...p,
        rtcConnected: true,
        tracks: [...action.participant.tracks.values()].map(p => ({
          sid: p.trackSid,
          name: p.trackName,
          kind: p.kind,
          isEnabled: p.isTrackEnabled,
          isStarted: p.track && p.track.isStarted,
          isSubscribed: p.isSubscribed,
          track: p.track,
        })),
      }));
    case types.STUDY_ROOM_RTC_PARTICIPANT_DISCONNECTED:
      if (hasParticipant(action.participant, state)) {
        return updateRTCParticipant(state, action.participant, p => ({ ...p, rtcConnected: false }));
      }
      return state;
    case types.STUDY_ROOM_RTC_RECONNECTED:
      return state; // TODO
    case types.STUDY_ROOM_RTC_RECONNECTING:
      return state; // TODO
    case types.STUDY_ROOM_RTC_RECORDING_STARTED:
      return state; // TODO
    case types.STUDY_ROOM_RTC_RECORDING_STOPPED:
      return state; // TODO
    case types.STUDY_ROOM_RTC_TRACK_DISABLED:
      if (hasParticipant(action.participant, state)) {
        return updateRTCParticipantTrack(state, action.participant, action.publication.trackSid, t => ({
          ...t,
          isEnabled: false,
        }));
      }
      return state;
    case types.STUDY_ROOM_RTC_TRACK_ENABLED:
      return updateRTCParticipantTrack(state, action.participant, action.publication.trackSid, t => ({
        ...t,
        isSubscribed: action.publication.isSubscribed,
        isEnabled: action.publication.isTrackEnabled,
        track: action.publication.track,
      }));
    case types.STUDY_ROOM_RTC_TRACK_PUBLISHED: {
      const track = action.publication.track;
      return updateRTCParticipant(state, action.participant, p => ({
        ...p,
        tracks: [
          ...(p.tracks || []),
          action.publication.isSubscribed ?
            {
              sid: track.sid,
              name: track.name,
              isEnabled: track.isEnabled,
              kind: track.kind,
              isStarted: track.isStarted,
              // isSubscribed: action.publication.isSubscribed,
              track: track,
            }
          : {
              sid: action.publication.trackSid,
              name: action.publication.trackName,
              kind: action.publication.kind,
            },
        ],
      }));
    }
    case types.STUDY_ROOM_RTC_TRACK_STARTED:
      return updateRTCParticipantTrack(state, action.participant, action.track.sid, t => ({
        ...t,
        isStarted: true,
        track: action.track,
      }));
    case types.STUDY_ROOM_RTC_TRACK_SUBSCRIBED:
      return updateRTCParticipantTrack(state, action.participant, action.track.sid, t => ({
        ...t,
        isSubscribed: true,
        isStarted: action.track.isStarted,
        isEnabled: action.track.isEnabled,
        track: action.track,
      }));
    case types.STUDY_ROOM_RTC_TRACK_SUBSCRIPTION_FAILED:
      return state; // TODO
    case types.STUDY_ROOM_RTC_TRACK_UNPUBLISHED:
      return updateRTCParticipant(state, action.participant, p => ({
        ...p,
        tracks: p.tracks.filter(t => t.sid !== action.publication.trackSid),
      }));
    case types.STUDY_ROOM_RTC_TRACK_UNSUBSCRIBED:
      if (hasParticipant(action.participant, state)) {
        return updateRTCParticipantTrack(state, action.participant, action.track.sid, t => ({
          ...t,
          isSubscribed: false,
        }));
      }
      return state;

    case types.ADD_STUDY_ROOM_ATTACHMENT:
      return {
        ...state,
        attachments: [action.attachment, ...state.attachments],
      };

    case types.REMOVE_STUDY_ROOM_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(a => a.id !== action.attachmentId),
      };

    case types.STUDY_ROOM_START_BILLING_REQUEST:
      return {
        ...state,
        billing_request_status: {
          pending: true,
          max_duration: action.max_duration,
          tutor_rate: action.tutor_rate,
        },
      };

    case types.STUDY_ROOM_START_BILLING_REQUEST_ACCEPTED:
      return {
        ...state,
        billing_request_status: {
          ...state.billing_request_status,
          pending: false,
          started_at: Date.now(),
        },
      };

    case types.STUDY_ROOM_START_BILLING_REQUEST_DECLINED:
      return {
        ...state,
        billing_request_status: {
          pending: false,
          decline_reason: action.reason,
          billing_started_at: null,
        },
      };
    default:
      return state;
  }
}

const initialState = {
  status: {
    fetch_demo: { success: true },
  },
  items: {
    demo: { id: 'demo', ...initialStudyRoom },
  },
  slug_map: {
    demo: 'demo',
  },
};

export default function studyRooms(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_STUDY_ROOM:
    case types.ADD_STUDY_ROOM_PARTICIPANT:
    case types.FETCH_STUDY_ROOM_PARTICIPANTS_SUCCESS:
    case types.UPDATE_STUDY_ROOM_PARTICIPANT:
    case types.REMOVE_STUDY_ROOM_PARTICIPANTS:
    case types.DELETE_STUDY_ROOM_WHITEBOARD:
    case types.ADD_STUDY_ROOM_PARTICIPANT_TRACKS:
    case types.REMOVE_STUDY_ROOM_PARTICIPANT_TRACK:
    case types.STUDY_ROOM_RTC_DOMINANT_SPEAKER_CHANGED:
    case types.STUDY_ROOM_RTC_PARTICIPANT_CONNECTED:
    case types.STUDY_ROOM_RTC_PARTICIPANT_DISCONNECTED:
    case types.STUDY_ROOM_RTC_RECONNECTED:
    case types.STUDY_ROOM_RTC_RECONNECTING:
    case types.STUDY_ROOM_RTC_RECORDING_STARTED:
    case types.STUDY_ROOM_RTC_RECORDING_STOPPED:
    case types.STUDY_ROOM_RTC_TRACK_DISABLED:
    case types.STUDY_ROOM_RTC_TRACK_ENABLED:
    case types.STUDY_ROOM_RTC_TRACK_PUBLISHED:
    case types.STUDY_ROOM_RTC_TRACK_STARTED:
    case types.STUDY_ROOM_RTC_TRACK_SUBSCRIBED:
    case types.STUDY_ROOM_RTC_TRACK_SUBSCRIPTION_FAILED:
    case types.STUDY_ROOM_RTC_TRACK_UNPUBLISHED:
    case types.STUDY_ROOM_RTC_TRACK_UNSUBSCRIBED:
    case types.ADD_STUDY_ROOM_ATTACHMENT:
    case types.REMOVE_STUDY_ROOM_ATTACHMENT:
    case types.STUDY_ROOM_START_BILLING_REQUEST:
    case types.STUDY_ROOM_START_BILLING_REQUEST_ACCEPTED:
    case types.STUDY_ROOM_START_BILLING_REQUEST_DECLINED:
      return {
        ...state,
        items: {
          ...state.items,
          [action.id || action.studyRoom.id]: studyRoom(state.items[action.id || action.studyRoom.id], action),
        },
      };
    case whiteboardTypes.ADD_WHITEBOARD:
      return {
        ...state,
        items: {
          ...state.items,
          [action.whiteboard.study_room_id]: studyRoom(state.items[action.whiteboard.study_room_id], action),
        },
      };

    case types.FETCH_STUDY_ROOM_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.studyRoom.id]: studyRoom(state.items[action.studyRoom.id], action),
        },
        status: { ...state.status, [`fetch_${action.studyRoom.slug}`]: { success: true } },
        slug_map: { ...state.slug_map, [action.studyRoom.slug]: action.studyRoom.id },
      };
    case types.FETCH_STUDY_ROOM_FAIL:
      return { ...state, status: { ...state.status, [`fetch_${action.slug}`]: { error: action.error } } };
    default:
      return state;
  }
}
