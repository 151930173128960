import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_USER',
  'ADD_USERS',

  'FETCH_USERS_SUCCESS',
  'FETCH_USERS_FAIL',

  'FETCH_INSTRUCTORS_SUCCESS',
  'FETCH_INSTRUCTORS_FAIL',

  'FETCH_STUDENT_TUTORS_SUCCESS',
  'FETCH_TUTOR_STUDENTS_SUCCESS',

  'FETCH_TUTOR_PROFILE_SUCCESS',
  'FETCH_TUTOR_PROFILE_FAIL',

  'FETCHING_INSTRUCTOR_PROFILE',
  'FETCH_INSTRUCTOR_PROFILE_SUCCESS',
  'FETCH_INSTRUCTOR_PROFILE_FAIL',

  'FETCH_STUDENT_DASHBOARD_STATS_SUCCESS',
  'FETCH_STUDENT_DASHBOARD_STATS_FAIL',

  'FETCH_USER_INVITE_DATA_SUCCESS',
  'FETCH_USER_INVITE_DATA_FAIL',

  'USER_LOGIN_SUCCESS',
  'USER_LOGIN_ERROR',
  'USER_SIGN_UP_ERROR',

  'USER_PASSWORD_RESET_SUCCESS',
  'USER_PASSWORD_RESET_FAIL',
  'USER_UPDATE_PASSWORD_FAIL',

  'USER_CONFIRMATION_RESEND_SUCCESS',
  'USER_CONFIRMATION_RESEND_FAIL',

  'ADMIN_FETCH_INSTRUCTORS_SUCCESS',
  'ADMIN_FETCH_INSTRUCTORS_FAIL',
]);

export default actionTypes;
