import { alpha, createTheme } from '@mui/material/styles';

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightBold = 700;
const fontWeightExtraBold = 800;
const fontWeightBlack = 900;

const opacity = [0.08, 0.16, 0.24, 0.32, 0.4, 0.48];

const common = {
  white: '#fff',
  black: '#00041A',
};
const grey = {
  50: '#F4F7FC',
  100: '#F0F2FB',
  200: '#E4E9F2',
  250: '#CBD1DA',
  300: '#C5CEE0',
  400: '#8F9BB3',
  500: '#8392A6',
  600: '#4D5567',
  650: '#3D4356',
  700: '#3B4056',
  750: '#2D324C',
  800: '#1D2241',
  900: '#000838',
  950: '#00062C',
  1000: common.black,
};

// blue
const primary = {
  background: '#EBF2FF', // 'rgba(179, 206, 255, 0.3)',
  light: '#A9CAFF',
  main: '#377FFF',
  medium: '#0050DA',
  dark: '#003792',
  extraDark: '#02235E',
  contrastText: common.white,
};
// pink
const secondary = {
  background: '#FFEBF0', // 'rgba(255, 194, 201, 0.3)',
  light: '#FFACC2',
  main: '#FF517B',
  medium: '#F51046',
  dark: '#A70027',
  extraDark: '#520114',
  contrastText: common.white,
};

const green = {
  background: '#E8FFED', // 'rgba(148, 255, 190, 0.3)',
  light: '#ADEFBB',
  main: '#04AE29',
  medium: '#11912D',
  dark: '#016C12',
  extraDark: '#003E0A',
  contrastText: common.white,
};
const yellow = {
  background: '#FFF5DA', // 'rgba(255, 227, 133, 0.3)',
  light: '#FCDE7F',
  main: '#F3BD01',
  medium: '#BC9003',
  dark: '#725800',
  extraDark: '#3E2F00',
  contrastText: common.black,
};

const orange = {
  background: '#FFEAE9', // 'rgba(254, 213, 210, 0.3)',
  light: '#FEC1BD',
  main: '#F93A2C',
  medium: '#D01508',
  dark: '#870000',
  extraDark: '#450000',
  contrastText: common.white,
};

const purple = {
  background: '#F3EBFD', // 'rgba(208, 176, 247, 0.3)',
  light: '#DBC2FA',
  main: '#A059F8',
  medium: '#6412CA',
  dark: '#30097A',
  extraDark: '#1D0251',
  contrastText: common.white,
};

const cyan = {
  background: '#E5FDFD', // 'rgba(183, 229, 230, 0.3)',
  light: '#A9E8EA',
  main: '#0FC8CB',
  medium: '#0BA5AA',
  dark: '#076366',
  extraDark: '#00292A',
  contrastText: common.white,
};

const skyblue = {
  background: '#E5F8FF', // 'rgba(168, 232, 255, 0.3)',
  light: '#B3EBFF',
  main: '#00B0F0',
  medium: '#018BBD',
  dark: '#005270',
  extraDark: '#002F40',
  contrastText: common.white,
};

const invertableColors = {
  primary,
  secondary,
  yellow,
  green,
  orange,
  purple,
  cyan,
  skyblue,
  // states
  success: green,
  error: orange,
  warning: yellow,
  info: primary,
};

const gradientDark = grey[900];
const gradientDarkTransparent = alpha(grey[900], 0);
const gradientLight = common.white;
const gradientLightTransparent = alpha(common.white, 0);
const backgroundGradients = {
  darkVertical: `linear-gradient(to bottom, ${gradientDark} 0%, ${gradientDarkTransparent} 100%)`,
  darkHorizontal: `linear-gradient(to right, ${gradientDark} 0%, ${gradientDarkTransparent} 100%)`,
  dark45Deg: `linear-gradient(45deg, ${gradientDark} 0%, ${gradientDarkTransparent} 100%)`,
  lightVertical: `linear-gradient(to bottom, ${gradientLight} 0%, ${gradientLightTransparent} 100%)`,
  lightHorizontal: `linear-gradient(to right, ${gradientLight} 0%, ${gradientLightTransparent} 100%)`,
  light45Deg: `linear-gradient(45deg, ${gradientLight} 0%, ${gradientLightTransparent} 100%)`,
};

const getModePalette = (mode = 'light') => ({
  mode,
  ...(mode === 'light' ?
    {
      background: {
        paper: common.white,
        default: grey[50],
        paper1: grey[100],
        paper2: grey[200],
        paper3: grey[300],
        dark: grey[900],
        primary: primary.main,
      },
      text: {
        primary: grey[900],
        secondary: grey[500],
        disabled: grey[250],
      },
      form: {
        input: 'rgba(0, 0, 0, 0.23)',
        inputHover: grey[900], // text.primary
      },
      logo: {
        light: primary.main,
        dark: grey[700],
      },
      ...invertableColors,
    }
  : {
      background: {
        paper: grey[800],
        default: grey[950],
        paper1: grey[750],
        paper2: grey[700],
        paper3: grey[650],
        dark: grey[900],
        primary: primary.medium,
      },
      text: {
        primary: common.white,
        secondary: grey[500],
        disabled: grey[600],
      },
      form: {
        input: 'rgba(255, 255, 255, 0.23)',
        inputHover: grey[50], // text.primary
      },
      action: { active: 'rgba(255, 255, 255, 0.75)' },
      logo: {
        light: primary.main,
        dark: common.white,
      },
      ...Object.fromEntries(
        Object.entries(invertableColors).map(([key, val]) => [
          key,
          { ...val, background: val.extraDark, light: val.dark, dark: val.light },
          // { ...val, background: val.dark, dark: val.background, light: val.medium },
        ]),
      ),
    }),
});

export const palette = {
  common,
  grey,

  ...getModePalette('light'),
  // contrastThreshold: 2.9, // effects how getContrastText function determines color
  backgroundGradients,

  // brands
  thirdParty: {
    facebook: {
      main: '#1778F2',
      contrastText: common.white,
    },
    linkedIn: {
      main: '#0073B0',
      contrastText: common.white,
    },
  },
  mapColors(fn) {
    return COLOR_NAMES.map(fn).reduce(Object.assign, {});
  },
};

function getShadows(mode = 'light') {
  const [shadowDark, shadowMain, shadowLight] =
    mode === 'light' ?
      ['rgba(150, 150, 150, 0.20)', 'rgba(150, 150, 150, 0.14)', 'rgba(150, 150, 150, 0.12)']
    : ['rgba(0, 4, 26, 0.20)', 'rgba(0, 4, 26, 0.14)', 'rgba(0, 4, 26, 0.12)'];
  const shadows = [
    'none',
    `0px 2px 1px -1px ${shadowDark},0px 1px 1px 0px ${shadowMain},0px 1px 3px 0px ${shadowLight}`,
    `0px 3px 1px -2px ${shadowDark},0px 2px 2px 0px ${shadowMain},0px 1px 5px 0px ${shadowLight}`,
    `0px 3px 3px -2px ${shadowDark},0px 3px 4px 0px ${shadowMain},0px 1px 8px 0px ${shadowLight}`,
    `0px 2px 4px -1px ${shadowDark},0px 4px 5px 0px ${shadowMain},0px 1px 10px 0px ${shadowLight}`,
    `0px 3px 5px -1px ${shadowDark},0px 5px 8px 0px ${shadowMain},0px 1px 14px 0px ${shadowLight}`,
    `0px 3px 5px -1px ${shadowDark},0px 6px 10px 0px ${shadowMain},0px 1px 18px 0px ${shadowLight}`,
    `0px 4px 5px -2px ${shadowDark},0px 7px 10px 1px ${shadowMain},0px 2px 16px 1px ${shadowLight}`,
    `0px 5px 5px -3px ${shadowDark},0px 8px 10px 1px ${shadowMain},0px 3px 14px 2px ${shadowLight}`,
    `0px 5px 6px -3px ${shadowDark},0px 9px 12px 1px ${shadowMain},0px 3px 16px 2px ${shadowLight}`,
    `0px 6px 6px -3px ${shadowDark},0px 10px 14px 1px ${shadowMain},0px 4px 18px 3px ${shadowLight}`,
    `0px 6px 7px -4px ${shadowDark},0px 11px 15px 1px ${shadowMain},0px 4px 20px 3px ${shadowLight}`,
    `0px 7px 8px -4px ${shadowDark},0px 12px 17px 2px ${shadowMain},0px 5px 22px 4px ${shadowLight}`,
    `0px 7px 8px -4px ${shadowDark},0px 13px 19px 2px ${shadowMain},0px 5px 24px 4px ${shadowLight}`,
    `0px 7px 9px -4px ${shadowDark},0px 14px 21px 2px ${shadowMain},0px 5px 26px 4px ${shadowLight}`,
    `0px 8px 9px -5px ${shadowDark},0px 15px 22px 2px ${shadowMain},0px 6px 28px 5px ${shadowLight}`,
    `0px 8px 10px -5px ${shadowDark},0px 16px 24px 2px ${shadowMain},0px 6px 30px 5px ${shadowLight}`,
    `0px 8px 11px -5px ${shadowDark},0px 17px 26px 2px ${shadowMain},0px 6px 32px 5px ${shadowLight}`,
    `0px 9px 11px -5px ${shadowDark},0px 18px 28px 2px ${shadowMain},0px 7px 34px 6px ${shadowLight}`,
    `0px 9px 12px -6px ${shadowDark},0px 19px 29px 2px ${shadowMain},0px 7px 36px 6px ${shadowLight}`,
    `0px 10px 13px -6px ${shadowDark},0px 20px 31px 3px ${shadowMain},0px 8px 38px 7px ${shadowLight}`,
    `0px 10px 13px -6px ${shadowDark},0px 21px 33px 3px ${shadowMain},0px 8px 40px 7px ${shadowLight}`,
    `0px 10px 14px -6px ${shadowDark},0px 22px 35px 3px ${shadowMain},0px 8px 42px 7px ${shadowLight}`,
    `0px 11px 14px -7px ${shadowDark},0px 23px 36px 3px ${shadowMain},0px 9px 44px 8px ${shadowLight}`,
    `0px 11px 15px -7px ${shadowDark},0px 24px 38px 3px ${shadowMain},0px 9px 46px 8px ${shadowLight}`,
  ];
  shadows.states = {
    static: shadows[4],
    clickable: shadows[10],
    hover: shadows[16],
  };
  return shadows;
}

const shape = { borderRadius: 20, borderRadiusSm: 12 };

typeof __DEV__ !== 'undefined' && // imported from storybookTheme.js which doesn't have __DEV__ webpack plugin
  __DEV__ &&
  (window.themePalette = {
    light: createTheme({ palette: { ...palette, ...getModePalette('light') } }).palette,
    dark: createTheme({ palette: { ...palette, ...getModePalette('dark') } }).palette,
  });
export default mode =>
  createTheme({
    palette: { ...palette, ...getModePalette(mode) },
    opacity,
    typography: {
      fontFamily: "'Mulish', 'Mulish-fallback-1', 'Mulish-fallback-2', sans-serif",
      fontWeightLight,
      fontWeightRegular,
      fontWeightBold,
      fontWeightExtraBold,
      fontWeightBlack,
      h1: {
        // page headers
        fontSize: 60, // 3.75rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      h2: {
        fontSize: 48, // 3rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      h3: {
        fontSize: 40, // 2.5rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      h4: {
        fontSize: 32, // 2rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      h5: {
        fontSize: 24, // 1.5rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      h6: {
        fontSize: 20, // 1.25rem
        fontWeight: fontWeightExtraBold,
        letterSpacing: 0,
      },
      subtitle1: {
        fontSize: 16, // 1rem
        fontWeight: fontWeightBold,
        letterSpacing: 0,
        lineHeight: 1.43,
      },
      subtitle2: {
        fontSize: 14, // 0.875rem
        fontWeight: fontWeightBold,
        letterSpacing: 0,
        lineHeight: 1.43,
      },
      body1: {
        fontSize: 16, // 1rem
        fontWeight: fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 1.43,
      },
      body2: {
        fontSize: 14, // 0.875rem
        fontWeight: fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 1.43,
      },
      body3: {
        fontSize: 20, // 1.25rem
        fontWeight: fontWeightRegular,
        letterSpacing: 0,
        lineHeight: 1.43,
      },
      button: {
        fontSize: 14, // 0.875rem
        fontWeight: fontWeightExtraBold,
        textTransform: 'none',
        letterSpacing: 0,
      },
      caption: {
        fontSize: 12, // 0.75rem
        fontWeight: fontWeightRegular,
        letterSpacing: 0,
      },
      overline: {
        fontSize: 11, // 0.6875rem
        fontWeight: fontWeightBlack,
        textTransform: 'uppercase',
        letterSpacing: 1,
        lineHeight: 1.1,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: { textAlign: 'center' },
          contained: {
            boxShadow: 'none',
            '&:hover, &:active': { boxShadow: 'none' },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          // body: { fontSize: 14 },
          button: {
            font: 'inherit',
          },
          a: {
            textDecoration: 'none',
            color: palette.primary.main,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: { '&:not(.MuiDialog-paperFullScreen)': { maxHeight: '95%' } },
        },
      },
      MuiLink: {
        styleOverrides: {
          button: { verticalAlign: 'unset' },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          rectangular: {
            borderRadius: shape.borderRadiusSm,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.75rem !important',
            borderRadius: shape.borderRadiusSm,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body3: 'p',
          },
        },
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true, // NOTE: remove if enabling SSR
        },
      },
      MuiInputBase: mode === 'dark' && {
        styleOverrides: {
          input: {
            '&::-webkit-calendar-picker-indicator': {
              filter: 'invert(1)',
            },
          },
        },
      },
    },
    constants: {
      APPBAR_HEIGHT: 56,
      APPBAR_HEIGHT_UNITS: 56 / 8,
      APPBAR_HEIGHT_DENSE: 48,
      APPBAR_HEIGHT_DENSE_UNITS: 48 / 8,
      // Use APPBAR_OFFSET when spacing wrt. area taken up by fixed content at top of page.
      // Normally it is same as APPBAR_HEIGHT, but may be overridden (eg. SiteWideBanner)
      APPBAR_OFFSET: 56,
      APPBAR_OFFSET_UNITS: 56 / 8,

      // Use when page elements to same max width
      WRAPPER_MAX_WIDTH_SM: 600,
      WRAPPER_MAX_WIDTH_MD: 940,
      WRAPPER_MAX_WIDTH_LG: 1120,
    },
    shadows: getShadows(mode),
    shape,
  });

export const COLOR_NAMES = [
  'primary',
  'secondary',
  'orange',
  'green',
  'yellow',
  'purple',
  'cyan',
  'skyblue',
  'success',
  'error',
  'warning',
  'info',
];
