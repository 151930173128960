import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'SHOW_POPUP_CHAT',
  'HIDE_POPUP_CHAT',
  'REMOVE_POPUP_CHAT',
  'REMOVE_ALL_POPUP_CHATS',
]);

export default actionTypes;
