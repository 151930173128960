import types from '../constants/StudyRoomErrorActionTypes';
import { addNewItems } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function studyRoomErrors(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_STUDY_ROOM_ERROR:
      return { ...state, status: { ...state.status, [action.requestKey]: { saving: true } } };
    case types.CREATE_STUDY_ROOM_ERROR_SUCCESS:
      return {
        ...state,
        items: addNewItems(state, action.studyRoomErrors),
        status: {
          ...state.status,
          [action.requestKey]: { id: action.studyRoomErrors[0].id, success: true },
        },
      };
    case types.CREATE_STUDY_ROOM_ERROR_FAIL:
      return { ...state, status: { ...state.status, [action.requestKey]: { error: action.error } } };

    default:
      return state;
  }
}
