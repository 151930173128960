import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_REPORT_SUCCESS',
  'FETCH_REPORT_FAIL',
  'FETCH_REPORTS_SUCCESS',
  'FETCH_REPORTS_FAIL',
  'CREATE_REPORT_SUCCESS',
  'CREATE_REPORT_FAIL',
  'UPDATE_REPORT_SUCCESS',
  'UPDATE_REPORT_FAIL',
  'DELETE_REPORT_SUCCESS',
  'DELETE_REPORT_FAIL',
]);

export default actionTypes;
