import { humanize } from 'my-core/my-utils';

export function getMessageFromError(error) {
  if (!error) return '';
  if (typeof error === 'string') return error;
  const { body, status } = error;
  if (status === 500 && !(__DEV__ && body))
    return 'There was a problem on the site.  Please contact us if this issue persists';
  if (status === 429) return 'Too many requests. Try again in a couple minutes';
  if (typeof body === 'string') return body;
  if (body && typeof body.message === 'string') return body.message;
  if (status === 401) return 'You are not authorized to perform this action';
  if (body) return humanizeErrorFields(body);
  return 'Error';
  // return Object.keys(error).filter(k => k !== 'status').map(k => error[k]).reduce((a,b) => a.concat(b)).join('\n');
}

export function humanizeErrorFields(errors) {
  if (!errors) return '';
  switch (typeof errors) {
    case 'string':
      return errors;
    case 'object':
      if (Array.isArray(errors)) return errors.map(humanizeErrorFields).join('\n');
      if (Array.isArray(errors.detail)) return errors.detail.join('\n');
      return Object.keys(errors)
        .map(k => {
          if (k === 'base') {
            return errors[k].join(', ');
          } else {
            return `${humanize(k.replace(/\./g, ' '))}: ${Array.isArray(errors[k]) ? errors[k].join(', ') : errors[k]}`;
          }
        })
        .join('\n');
  }
  return errors;
}

//Known issues that do not have a fix for now
const KNOWN_ISSUES = [
  {
    // this is a known error with nested draftjs editors
    errorName: 'TypeError',
    errorMessage: /Cannot read property 'getIn' of undefined/,
    errorSource: 'draftjs-editor',
  },
  {
    // known error with insta-widget
    errorName: 'ReferenceError',
    errorMessage: /jQuery is not defined/,
    errorSource: 'insta-widget',
  },
  {
    // webpack error - unknown cause no fix yet
    errorName: 'ChunkLoadError',
    errorSource: 'webpack',
    errorMessage: /Loading chunk/,
  },
];

export function getErrorTags(error) {
  if (!error) return;
  const knownIssue = KNOWN_ISSUES.find(
    ({ errorMessage, errorName }) =>
      (!errorName || errorName === error.name) && (!errorMessage || error.message.match(errorMessage)),
  );

  if (knownIssue) return { 'error-type': 'known-issue', 'error-source': knownIssue.errorSource };
  else return { 'error-type': 'unknown-issue' };
}

export function shouldIgnoreError(error) {
  if (!error) return false;
  if (
    error.name === 'TypeError' &&
    error.message === 'Failed to fetch' &&
    error.stack?.includes?.('googleads.g.doubleclick')
  )
    return true;
  return false;
}
