import announcementResponseTypes from '../constants/AnnouncementResponseActionTypes';
import ocUserTypes from '../constants/OnlineCourseUserActionTypes';
import studyRoomTypes from '../constants/StudyRoomActionTypes';
import tutorRequestTypes from '../constants/TutorRequestActionTypes';
import types from '../constants/UserActionTypes';
import { addItems, fetchItemsFail, fetchItemsSuccess, updateItem } from './utils';

function addUsers(items, state) {
  return addItems(items, user, types.ADD_USER, 'user', state);
}

function user(state = {}, action) {
  switch (action.type) {
    case types.ADD_USER:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.ADD_USER:
    case studyRoomTypes.ADD_STUDY_ROOM_PARTICIPANT:
      return addUsers([action.user], state);

    case types.ADD_USERS:
    case studyRoomTypes.FETCH_STUDY_ROOM_PARTICIPANTS_SUCCESS:
    case tutorRequestTypes.FETCH_STUDENT_TUTOR_REQUESTS_SUCCESS:
    case tutorRequestTypes.FETCH_TUTOR_TUTOR_REQUESTS_SUCCESS:
      return addUsers(action.users, state);

    case types.FETCH_USERS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_USERS_FAIL:
      return fetchItemsFail(state, action);

    case types.USER_LOGIN_SUCCESS:
      return addUsers([action.user], {
        ...state,
        status: { ...state.status, current_user: action.user.id, login: { success: true, id: action.user.id } },
      });
    case types.USER_LOGIN_ERROR:
      return { ...state, status: { ...state.status, login: { error: action.error } } };

    case types.USER_SIGN_UP_ERROR:
      return { ...state, status: { ...state.status, sign_up: { error: action.error } } };

    case types.USER_PASSWORD_RESET_SUCCESS:
      return { ...state, status: { ...state.status, password_reset: { success: true } } };
    case types.USER_PASSWORD_RESET_FAIL:
      return { ...state, status: { ...state.status, password_reset: { success: false, error: action.error } } };
    case types.USER_UPDATE_PASSWORD_FAIL:
      return { ...state, status: { ...state.status, update_password: { error: action.error } } };

    case types.USER_CONFIRMATION_RESEND_SUCCESS:
      return {
        ...state,
        status: { ...state.status, resend_confirmation: { success: true, error: null, email: action.email } },
      };
    case types.USER_CONFIRMATION_RESEND_FAIL:
      return { ...state, status: { ...state.status, resend_confirmation: { success: false, error: action.error } } };

    case types.FETCH_STUDENT_TUTORS_SUCCESS:
      return addUsers(action.users, {
        ...state,
        status: {
          ...state.status,
          [`fetched_student${action.studentId}_tutors`]: action.users.map(u => u.id),
        },
      });
    case types.FETCH_INSTRUCTORS_SUCCESS:
      return addUsers(action.instructors, {
        ...state,
        status: { ...state.status, fetched_instructors: { success: true, ids: action.instructors.map(i => i.id) } },
      });
    case types.FETCH_TUTOR_STUDENTS_SUCCESS:
      return addUsers(action.users, {
        ...state,
        status: {
          ...state.status,
          [`fetched_tutor${action.tutorId}_students`]: action.users.map(u => u.id),
        },
      });

    case types.FETCH_TUTOR_PROFILE_SUCCESS:
      return addUsers([action.tutor], {
        ...state,
        status: { ...state.status, [`fetch_tutor_${action.tutor.id}`]: { success: true } },
      });
    case types.FETCH_TUTOR_PROFILE_FAIL:
      return { ...state, status: { ...state.status, [`fetch_tutor_${action.id}`]: { error: action.error } } };

    case types.FETCHING_INSTRUCTOR_PROFILE:
      return { ...state, status: { ...state.status, [`fetch_instructor_${action.id}`]: { fetching: true } } };
    case types.FETCH_INSTRUCTOR_PROFILE_SUCCESS:
      return addUsers([action.instructor], {
        ...state,
        status: { ...state.status, [`fetch_instructor_${action.id}`]: { success: true } },
      });
    case types.FETCH_INSTRUCTOR_PROFILE_FAIL:
      return { ...state, status: { ...state.status, [`fetch_instructor_${action.id}`]: { error: action.error } } };

    case types.FETCH_STUDENT_DASHBOARD_STATS_SUCCESS:
      return addUsers([action.user], {
        ...state,
        status: { ...state.status, [`fetch_${action.user.id}_student_dashboard`]: { success: true } },
      });
    case types.FETCH_STUDENT_DASHBOARD_STATS_FAIL:
      return {
        ...state,
        status: { ...state.status, [`fetch_${action.id}_student_dashboard`]: { error: action.error } },
      };

    case types.FETCH_USER_INVITE_DATA_SUCCESS:
      return addUsers([action.user], {
        ...state,
        status: { ...state.status, fetch_user_invite_data: { success: true } },
      });
    case types.FETCH_USER_INVITE_DATA_FAIL:
      return { ...state, status: { ...state.status, fetch_user_invite_data: { error: action.error } } };

    case studyRoomTypes.FETCH_STUDY_ROOM_SUCCESS:
      return addUsers(action.studyRoom.members, state);

    case ocUserTypes.PURCHASE_ONLINE_COURSE_USER_SUCCESS:
      return addUsers([action.user], state);

    case types.ADMIN_FETCH_INSTRUCTORS_SUCCESS:
      return addUsers(action.instructors, {
        ...state,
        status: { ...state.status, admin_fetch_instructors: { success: true } },
      });
    case types.ADMIN_FETCH_INSTRUCTORS_FAIL:
      return {
        ...state,
        status: { ...state.status, admin_fetch_instructors: { success: false, error: action.error } },
      };

    case announcementResponseTypes.CREATE_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return updateItem(
        state.status.current_user,
        user => ({ ...user, pending_announcements: (user.pending_announcements || 1) - 1 }),
        state,
      );

    default:
      return state;
  }
}
