import standardApiRequestAction from 'my-core/standardApiRequestAction';

import types from '../constants/NotificationActionTypes';
import API from '../core/api';

export function addNotification(notification) {
  return { type: types.ADD_NOTIFICATION, notification };
}

export function fetchNotifications(fetchKey) {
  return standardApiRequestAction({
    path: '/api/v1/notifications',
    requestKey: fetchKey,
  });
}

export function markAsRead(type, groupKey) {
  return dispatch => {
    API.putJSON('/api/v1/notifications/mark_as_read', { type, group_key: groupKey })
      .then(() => {
        dispatch({ type: types.MARK_NOTIFICATIONS_AS_READ_SUCCESS, notificationType: type, groupKey });
      })
      .catch(error => {
        dispatch({ type: types.MARK_NOTIFICATIONS_AS_READ_FAIL, notificationType: type, groupKey, error });
      });
  };
}

export function markAllAsRead() {
  return dispatch => {
    API.putJSON('/api/v1/notifications/mark_all_as_read')
      .then(() => {
        dispatch({ type: types.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.MARK_ALL_NOTIFICATIONS_AS_READ_FAIL, error });
      });
  };
}

export function deleteNotificationGroup(type, groupKey) {
  return standardApiRequestAction({
    path: '/api/v1/notifications/delete_group',
    method: 'delete',
    body: { type, group_key: groupKey },
  });
}

export function removeNotification(id) {
  return {
    type: types.REMOVE_NOTIFICATION,
    id,
  };
}
