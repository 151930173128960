import { memo } from 'react';
import { useDispatch } from 'react-redux';

import Footer from './Footer';

import { togglePrivacyPolicy, toggleTermsAndConditions } from 'my-actions/AppStateActions';

export default memo(function FooterCtnr() {
  const dispatch = useDispatch();

  function showPrivacyPolicy() {
    dispatch(togglePrivacyPolicy(true));
  }

  function showTermsAndConditions() {
    dispatch(toggleTermsAndConditions(true));
  }

  return <Footer onShowPrivacyPolicy={showPrivacyPolicy} onShowTermsAndConditions={showTermsAndConditions} />;
});
