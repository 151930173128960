import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import sortBy from 'sort-by';

import Message from './Message';

import * as messageActions from 'my-actions/MessageActions';

class MessageManager extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const messages = Object.values(nextProps.messages).sort(sortBy('created_at'));

    if (messages.length > 1) {
      return { open: false, nextMessage: messages[1], message: messages[0] };
    }
    if (messages.length === 1) {
      return prevState.message ?
          { nextMessage: null, message: messages[0] }
        : { open: true, nextMessage: null, message: messages[0] };
    }
    return {
      open: false,
      message: null,
      nextMessage: null,
    };
  }

  handleClose = (/*evt, reason*/) => {
    // if (reason === 'clickaway') return null;
    this.setState({ open: false });
  };
  handleExited = mId => {
    this.props.messageActions.deleteMessage(mId);
    if (this.state.nextMessage) {
      this.setState(({ nextMessage }) => ({ nextMessage: null, message: nextMessage, open: true }));
    }
  };

  render() {
    const { message, open } = this.state;

    if (!message) return null;

    return (
      <Message
        message={message}
        onClose={this.handleClose}
        onExited={() => this.handleExited(message.id)}
        open={open}
      />
    );
  }
}

export default connect(
  state => ({ messages: state.messages.items }),
  dispatch => ({ messageActions: bindActionCreators(messageActions, dispatch) }),
)(MessageManager);
