import API from 'my-core/api';

import types from '../constants/SiteTourActionTypes';

export function startAllSiteTours() {
  return { type: types.START_ALL_SITE_TOURS };
}
export function endAllSiteTours() {
  return { type: types.END_ALL_SITE_TOURS };
}
export function addSiteTourStep(siteTourId, step) {
  return { type: types.ADD_SITE_TOUR_STEP, siteTourId, step };
}

export function removeSiteTourStep(siteTourId, stepId) {
  return { type: types.REMOVE_SITE_TOUR_STEP, siteTourId, stepId };
}

export function viewedSiteTour(siteTourId) {
  return dispatch => {
    API.putJSON('/site_tours/viewed_tour', { site_tour_id: siteTourId });
    dispatch({ type: types.VIEWED_SITE_TOUR, siteTourId }); // dispatch no matter what so we don't end up with looping tour
  };
}

export function viewedSiteTours(siteTourIds) {
  return dispatch => {
    API.putJSON('/site_tours/viewed_tours', { site_tour_ids: siteTourIds });
    dispatch({ type: types.VIEWED_SITE_TOURS, siteTourIds }); // dispatch no matter what so we don't end up with looping tour
  };
}
