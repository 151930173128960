import { makeStyles } from '@mui/styles';

import { useTitle } from 'my-core/hooks';

import Footer from 'my-components/Footer';

import TermsAndConditionsContent from './TermsAndConditionsContent';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ constants, spacing }) => ({
    root: {
      maxWidth: 960,
      margin: [[spacing(constants.APPBAR_HEIGHT_UNITS + 4), 'auto', spacing(2)]],
      padding: spacing(0, 2),
    },
  }),
  { name: 'TermsAndConditionsPage' },
);

function TermsAndConditionsPage(props) {
  const classes = useStyles(props);
  useTitle('Terms and Conditions');

  return (
    <>
      <div className={classes.root}>
        <Typography gutterBottom variant="h4">
          Website Terms Of Use
        </Typography>
        <TermsAndConditionsContent classes={{ root: classes.policy }} />
      </div>
      <Footer />
    </>
  );
}
export default TermsAndConditionsPage;
