import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: {},
    logoTick: { fill: palette.logo.dark },
    logoDash: { fill: palette.logo.light },
  }),
  { name: 'WizeLogo' },
);

function WizeLogo(props) {
  const { classes: cp, className, ...rest } = props;
  const classes = useStyles(props);
  return (
    <svg
      className={classNames(classes.root, className)}
      viewBox="0 0 61.02 40.46"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        className={classes.logoTick}
        d="M33.51,0h4.83L24.84,40.46H14.28L8,22.7H13.7A4.8,4.8,0,0,1,18.25,26l1.54,4.59L29,3.28A4.8,4.8,0,0,1,33.51,0Z"
      />
      <path
        className={classes.logoTick}
        d="M51.63,3.28,42.47,30.56,40.94,26a4.8,4.8,0,0,0-4.56-3.28H30.69L37,40.46H47.52L61,0H56.19A4.8,4.8,0,0,0,51.63,3.28Z"
      />
      <path
        className={classes.logoDash}
        d="M5.66,0H0L3.45,9.77a5.76,5.76,0,0,0,5.43,3.85h5L10.18,3.19A4.8,4.8,0,0,0,5.66,0Z"
      />
    </svg>
  );
}

WizeLogo.propTypes = {
  className: PropTypes.string,
};

export default WizeLogo;
