import types from '../constants/AttachmentActionTypes';
import { addItems, fetchItemSuccess, removeItem } from './utils';

function addAttachments(items, state) {
  return addItems(items, attachment, types.ADD_ATTACHMENT, 'attachment', state);
}

function attachment(state = {}, action) {
  switch (action.type) {
    case types.ADD_ATTACHMENT:
      return {
        ...state,
        ...action.attachment,
      };
    default:
      return state;
  }
}

const initialState = {
  status: {
    uploads: [],
    fetch: {},
  },
  items: {},
};

export default function attachments(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ATTACHMENT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.id || action.attachment.id]: attachment(state.items[action.id || action.attachment.id], action),
        },
      };

    case types.FETCH_ATTACHMENT_SUCCESS:
      return fetchItemSuccess(state, action);

    case types.FETCH_ATTACHMENTS_SUCCESS:
      return addAttachments(action.attachments, {
        ...state,
        status: {
          ...state.status,
          fetch: {
            ...state.status.fetch,
            [action.fetchKey]: {
              pages: {
                ...state.status.fetch[action.fetchKey]?.pages,
                [action.page]: action.attachments.map(c => c.id),
              },
              error: null,
              total: action.total,
            },
          },
        },
      });
    case types.FETCH_ATTACHMENTS_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          fetch: {
            ...state.status.fetch,
            [action.fetchKey]: { ...state.status.fetch[action.fetchKey], error: action.error },
          },
        },
      };

    case types.REMOVE_ATTACHMENT:
      return removeItem(state, action.id);

    case types.ATTACHMENT_UPLOAD_STARTED:
      return {
        ...state,
        status: {
          ...state.status,
          uploads: [
            ...state.status.uploads,
            { cId: action.cId, request: action.request, file: action.file, progress: 0 },
          ],
        },
      };
    case types.ATTACHMENT_UPDATE_PROGRESS:
      return {
        ...state,
        status: {
          ...state.status,
          uploads: state.status.uploads.map(u => (u.cId === action.cId ? { ...u, progress: action.progress } : u)),
        },
      };
    case types.ATTACHMENT_UPLOAD_SUCCESS:
      return addAttachments([action.attachment], {
        ...state,
        status: {
          ...state.status,
          create: { success: true, id: action.attachment.id, cId: action.cId },
          uploads: state.status.uploads.filter(u => u.cId !== action.cId),
        },
      });
    case types.ATTACHMENT_UPLOAD_CANCEL:
      return {
        ...state,
        status: {
          ...state.status,
          uploads: state.status.uploads.filter(u => u.cId !== action.cId),
        },
      };
    case types.ATTACHMENT_UPLOAD_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          create: { cId: action.cId, error: action.error },
          uploads: state.status.uploads.filter(u => u.cId !== action.cId),
        },
      };

    case types.UPDATE_ATTACHMENT_SUCCESS:
      return addAttachments([action.attachment], {
        ...state,
        status: { ...state.status, update: { success: action.attachment.id, error: null } },
      });
    case types.UPDATE_ATTACHMENT_FAIL:
      return { ...state, status: { ...state.status, update: { success: false, error: action.error } } };

    default:
      return state;
  }
}
