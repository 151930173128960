import types from '../constants/TutorAvailabilityActionTypes';
import { addNewItems, fetchingItems, fetchItemsFail, fetchItemsSuccess } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function tutorAvailabilities(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TUTOR_AVAILABILITIES:
      return fetchingItems(state, action);
    case types.FETCH_TUTOR_AVAILABILITIES_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_TUTOR_AVAILABILITIES_FAIL:
      return fetchItemsFail(state, action);

    case types.BATCH_UPDATE_TUTOR_AVAILABILITIES:
      return {
        ...state,
        status: { ...state.status, [action.updateKey]: { fetching: true } },
      };
    case types.BATCH_UPDATE_TUTOR_AVAILABILITIES_SUCCESS: {
      const items = addNewItems(state, action.tutorAvailabilities);
      action.meta.deleted_ids?.forEach(id => delete items[id]);
      return {
        ...state,
        items,
        status: { ...state.status, [action.updateKey]: { success: true } },
      };
    }
    case types.BATCH_UPDATE_TUTOR_AVAILABILITIES_FAIL:
      return {
        ...state,
        status: { ...state.status, [action.updateKey]: { error: action.error } },
      };

    default:
      return state;
  }
}
