import PropTypes from 'prop-types';
import { cloneElement, PureComponent } from 'react';

import Popover from '@mui/material/Popover';

export default class DropDownButton extends PureComponent {
  state = { anchorEl: null };

  showMenu = e => {
    const { onToggleOpen } = this.props;
    this.setState({ anchorEl: e.currentTarget });
    onToggleOpen?.(true);
  };

  hideMenu = () => {
    const { onToggleOpen } = this.props;
    this.setState({ anchorEl: null });
    onToggleOpen?.(false);
  };

  handleButtonMouseEvent = e => {
    const { button, mouseEvent = 'onClick' } = this.props;
    this.showMenu(e);
    button.props[mouseEvent]?.(e);
    this.props[mouseEvent]?.(e);
  };

  handlePopoverMouseEvent = e => {
    const { keepOpen, mouseEvent = 'onClick' } = this.props;
    if (!keepOpen) this.hideMenu();
    this.props[mouseEvent]?.(e);
  };

  render() {
    const {
      button,
      disableAutoClose,
      keepOpen,
      mouseEvent = 'onClick',
      onClick,
      onMouseDown,
      onMouseUp,
      onToggleOpen,
      ...rest
    } = this.props;

    const { anchorEl } = this.state;
    return (
      <>
        {cloneElement(button, { [mouseEvent]: this.handleButtonMouseEvent })}
        <Popover
          anchorEl={anchorEl}
          onClose={this.hideMenu}
          open={!!anchorEl}
          {...(disableAutoClose ? {} : { [mouseEvent]: this.handlePopoverMouseEvent })}
          {...rest}
        />
      </>
    );
  }
}

DropDownButton.propTypes = {
  keepOpen: PropTypes.bool,
};
