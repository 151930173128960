export const loadedScripts = {};
const erroredScripts = {};
const callbacks = {};
let idCount = 0;

export function insertScript(src, options = {}) {
  const id = idCount++;
  const { async, attrs, onError, onLoad, skipCache } = options;
  const scriptKey = src + (skipCache ? `--${id}` : '');

  function off() {
    delete callbacks[scriptKey][id];
  }

  if (loadedScripts[scriptKey]) {
    onLoad?.();
    return off;
  }
  if (erroredScripts[scriptKey]) {
    onError?.();
    return off;
  }

  if (callbacks[scriptKey]) {
    callbacks[scriptKey][id] = { onLoad, onError };

    return off;
  }

  (callbacks[scriptKey] ||= {})[id] = { onLoad, onError };

  const s = window.document.createElement('script');
  s.type = 'text/javascript';
  s.async = async ?? true;
  if (attrs) {
    Object.entries(attrs).forEach(([key, value]) => s.setAttribute(key, value));
  }
  s.src = src;

  s.onload = () => {
    loadedScripts[scriptKey] = true;
    const urlCallbacks = callbacks[scriptKey];

    if (!urlCallbacks) return;
    Object.entries(urlCallbacks).forEach(([id, { onLoad }]) => {
      onLoad?.();
      delete callbacks[scriptKey][id];
    });
  };
  s.onerror = () => {
    erroredScripts[scriptKey] = true;
    const urlCallbacks = callbacks[scriptKey];
    if (!urlCallbacks) return;
    Object.entries(urlCallbacks).forEach(([id, { onError }]) => {
      onError?.();
      delete callbacks[scriptKey][id];
    });
  };
  const lastScript = window.document.getElementsByTagName('script')[0];
  lastScript.parentNode.insertBefore(s, lastScript);

  return off;
}
