import warning from 'warning';

export function addItems(items, addItemCb, actionType, key, state) {
  if (!items) return state;
  warning(Array.isArray(items), 'items passed to addItems as an object is deprecated. Should be an array');
  if (!Array.isArray(items)) {
    items = Object.values(items);
  }
  return {
    ...state,
    items: items.reduce(
      (mem, item) => {
        if (item) {
          mem[item.id] = addItemCb(mem[item.id], { type: actionType, [key]: item });
        }
        return mem;
      },
      { ...state.items },
    ),
  };
}

export function addItemsToState(state, items) {
  if (items?.length) {
    return {
      ...state,
      items: addNewItems(state, items),
    };
  }
  return state;
}

export function addNewItems(state, newItems) {
  return (newItems || []).reduce(
    (mem, item) => {
      if (item?.id) {
        mem[item.id] = { ...state.items[item.id], ...item };
      }
      return mem;
    },
    { ...state.items },
  );
}

export function removeItem(state, itemId) {
  let newState = { ...state, items: { ...state.items } };
  delete newState.items[itemId];
  return newState;
}

export function removeItems(state, testFn) {
  let newState = { ...state, items: { ...state.items } };
  Object.keys(newState.items).forEach(k => {
    if (testFn(newState.items[k])) delete newState.items[k];
  });
  return newState;
}

export function updateItem(itemId, addItemCb, state, action) {
  return {
    ...state,
    items: {
      ...state.items,
      [itemId]: addItemCb(state.items[itemId], action),
    },
  };
}

export function fetchItemSuccess(state, action) {
  const fetchKey = action.fetchKey || `fetch_${action.item.id}`;
  return {
    ...state,
    items: addNewItems(state, [action.item]),
    status: { ...state.status, [fetchKey]: { success: true, id: action.item.id, ...action.meta } },
  };
}
export function fetchItemFail(state, action) {
  const fetchKey = action.fetchKey || `fetch_${action.id}`;
  return {
    ...state,
    status: { ...state.status, [fetchKey]: { error: action.error } },
  };
}

export function fetchingItems(state, action) {
  return {
    ...state,
    status: {
      ...state.status,
      fetch: { ...state.status.fetch, [action.fetchKey]: { ...state.status.fetch[action.fetchKey], fetching: true } },
    },
  };
}
export function fetchItemsSuccess(state, action) {
  const fetchStatus = state.status.fetch[action.fetchKey] || {};
  return {
    ...state,
    items: addNewItems(state, action.items),
    status: {
      ...state.status,
      fetch: {
        ...state.status.fetch,
        [action.fetchKey]: {
          ...fetchStatus,
          pages: { ...fetchStatus.pages, [action.page]: (action.items || []).map(i => i.id) },
          error: null,
          fetching: false,
          total: action.total,
          last_fetch_empty: !action.items?.length,
          fetched_at: Date.now(),
          ...action.meta,
        },
      },
    },
  };
}

export function fetchItemsFail(state, action) {
  const fetchStatus = state.status.fetch[action.fetchKey] || {};
  return {
    ...state,
    status: {
      ...state.status,
      fetch: { ...state.status.fetch, [action.fetchKey]: { ...fetchStatus, fetching: false, error: action.error } },
    },
  };
}

export function clearFetchCache(state) {
  return { ...state, status: { ...state.status, fetch: {} } };
}

export function createItemSuccess(state, action, keepFetchResults) {
  const fetchStatus = action.fetchKey && (state.status.fetch[action.fetchKey] || {});
  return {
    ...state,
    items: {
      ...state.items,
      [action.item.id]: { ...state.items[action.item.id], ...action.item },
    },
    status: {
      ...state.status,
      fetch: {
        ...(keepFetchResults ? state.status.fetch : {}),
        ...(action.fetchKey ?
          {
            [action.fetchKey]: {
              ...fetchStatus,
              pages: { ...fetchStatus.pages, 0: [action.item.id, ...(fetchStatus.pages?.[0] || [])] },
              // newItems: [action.item.id, ...(fetchStatus.newItems || [])]
            },
          }
        : {}),
      },
      create: { success: action.item.id, id: action.item.id },
    },
  };
}

export function createItemFail(state, action) {
  return { ...state, status: { ...state.status, create: { error: action.error } } };
}

export function updateItemSuccess(state, action, defaultUpdateKey) {
  const updateKey = defaultUpdateKey || action.updateKey || `update_${action.item.id}`;
  return {
    ...state,
    items: {
      ...state.items,
      [action.item.id]: { ...state.items[action.item.id], ...action.item },
    },
    status: {
      ...state.status,
      [updateKey]: { success: true },
    },
  };
}

export function updateItemFail(state, action, defaultUpdateKey) {
  const updateKey = defaultUpdateKey || action.updateKey || `update_${action.id}`;
  return { ...state, status: { ...state.status, [updateKey]: { error: action.error } } };
}

export function removeItemSuccess(state, action, keepFetchResults) {
  const fetchStatus = action.fetchKey && (state.status.fetch[action.fetchKey] || {});
  const items = { ...state.items };
  delete items[action.id];
  return {
    ...state,
    items,
    status: {
      ...state.status,
      [`delete_${action.id}`]: { success: true },
      fetch: {
        ...(keepFetchResults ? state.status.fetch : {}),
        ...(action.fetchKey ?
          {
            [action.fetchKey]: {
              ...fetchStatus,
              pages: { ...fetchStatus.pages, 0: ((fetchStatus.pages || {})[0] || []).filter(i => i !== action.id) },
              // newItems: [action.item.id, ...(fetchStatus.newItems || [])]
            },
          }
        : {}),
      },
    },
  };
}

export function removeItemFail(state, action) {
  return { ...state, status: { ...state.status, [`delete_${action.id}`]: { error: action.error } } };
}

//Resource Reactions Utils

export function createReaction(reactionableType, action, state) {
  const { item } = action;
  if (item.reactionable_type === reactionableType && state.items[item.reactionable_id]) {
    return updateItem(item.reactionable_id, i => changeEntityReactionCounts(i, item.reaction_id, 1), state);
  }

  return state;
}

export function deleteReaction(reactionableType, action, state) {
  if (action.reactionable.type === reactionableType && state.items[action.reactionable.id]) {
    return updateItem(action.reactionable.id, i => changeEntityReactionCounts(i, action.reactionId, -1), state);
  }
  return state;
}

export function changeEntityReactionCounts(entity, reactionId, change) {
  return {
    ...entity,
    reaction_counts: {
      ...entity.reaction_counts,
      [reactionId]: Math.max((entity.reaction_counts?.[reactionId] || 0) + change, 0),
    },
  };
}

//Vote utils

export function createVote(votableType, action, state) {
  const vote = action.item;
  if (vote.votable_type === votableType && state.items[vote.votable_id]) {
    return updateItem(
      vote.votable_id,
      p => ({
        ...p,
        vote_counts: { ...p.vote_counts, up: (p.vote_counts.up || 0) + 1 },
      }),
      state,
    );
  }
  return state;
}
export function deleteVote(votableType, action, state) {
  if (action.votable.type === votableType && state.items[action.votable.id]) {
    return updateItem(
      action.votable.id,
      p => ({
        ...p,
        vote_counts: { ...p.vote_counts, up: (p.vote_counts.up || 1) - 1 },
      }),
      state,
    );
  }
  return state;
}
