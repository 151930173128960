import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { appBarNavLinkClicked } from 'my-core/gtm-events';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette, spacing }) => ({
    root: {
      minWidth: 150,
      maxWidth: '100%',
    },
    menuList: {
      position: 'relative',
      padding: spacing(1.5, 0),
      '& + &:before': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: spacing(2),
        right: spacing(2),
        height: 1,
        backgroundColor: palette.divider,
      },
    },
    listItem: { display: 'block' },
    buttonWrapper: {
      padding: spacing(0, 2),
      '& + $buttonWrapper': { marginTop: spacing(1) },
    },
    button: {
      padding: spacing(1, 4),
    },
    icon: {
      marginRight: spacing(1),
    },
  }),
  { name: 'UserMenu' },
);

function UserMenu(props) {
  const { items, setOpen } = props;
  const classes = useStyles(props);

  return (
    <Paper className={classes.root} elevation={0} square>
      {items
        .map(
          (list, listIndex) =>
            list.length && (
              <div key={listIndex} className={classes.menuList}>
                {list.map(({ button, href, icon, label, onClick, path }) => {
                  const commonProps =
                    path ?
                      {
                        component: Link,
                        to: path,
                        onClick: () => {
                          appBarNavLinkClicked(label);
                          setOpen(false);
                        },
                      }
                    : href ?
                      {
                        component: 'a',
                        href,
                        target: '_blank',
                        rel: 'noreferrer',
                        onClick: () => {
                          appBarNavLinkClicked(label);
                          setOpen(false);
                        },
                      }
                    : {
                        onClick: e => {
                          setOpen(false);
                          appBarNavLinkClicked(label);
                          onClick(e);
                        },
                      };
                  return button ?
                      <div key={label} className={classes.buttonWrapper}>
                        <Button
                          className={classes.button}
                          fullWidth
                          size="small"
                          startIcon={icon && <FontAwesomeIcon icon={icon} />}
                          variant={button}
                          {...commonProps}
                        >
                          {label}
                        </Button>
                      </div>
                    : <MenuItem key={label} component="div" dense {...commonProps}>
                        {icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
                        {label}
                      </MenuItem>;
                })}
              </div>
            ),
        )
        .filter(Boolean)}
    </Paper>
  );
}

UserMenu.propTypes = {
  items: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired,
};
export default UserMenu;
