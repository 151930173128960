// export const EXTENSION_ID = 'bagnoefakdmlnkjnnakjcaackkgdmbgi';
export const EXTENSION_ID = 'hhjohbcndidecmihgnnjmchjkncmgfhl';

function sendMessage(message, callback) {
  if (typeof window.chrome?.runtime?.sendMessage !== 'function') {
    callback(false);
  } else {
    window.chrome.runtime.sendMessage(EXTENSION_ID, message, response => {
      callback(response);
    });
  }
}

export function getExtensionVersion(callback) {
  sendMessage({ type: 'VERSION' }, response => {
    callback(response && response.version);
  });
}

export function getScreenShareStreamId(callback) {
  sendMessage({ type: 'GET_SCREEN_SHARE_STREAM' }, streamId => {
    callback(streamId);
  });
}
