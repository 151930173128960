import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DialogResponsive(props) {
  const { breakpoint = 'sm', children, disableMobileClose, onClose, ...rest } = props;
  const fullScreen = useMediaQuery(({ breakpoints }) => breakpoints.down(breakpoint));

  if (fullScreen)
    return (
      <Dialog {...rest} fullScreen onClose={onClose} TransitionComponent={Transition}>
        {onClose && !disableMobileClose && (
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        )}
        {children}
      </Dialog>
    );
  return (
    <Dialog onClose={onClose} {...rest}>
      {children}
    </Dialog>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

DialogResponsive.propTypes = {
  breakpoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableMobileClose: PropTypes.bool,
  onClose: PropTypes.func,
};
