import * as Sentry from '@sentry/react';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { thunk } from 'redux-thunk';

import announcementResponses from '../reducers/announcementResponses';
import announcements from '../reducers/announcements';
import appState from '../reducers/appState';
import attachments from '../reducers/attachments';
import chatMessages from '../reducers/chatMessages';
import chats from '../reducers/chats';
import comments from '../reducers/comments';
import courseLessons from '../reducers/courseLessons';
import courseSections from '../reducers/courseSections';
import faqs from '../reducers/faqs';
import forumPosts from '../reducers/forumPosts';
import forumReplies from '../reducers/forumReplies';
import messages from '../reducers/messages';
import notifications from '../reducers/notifications';
import onlineCourseActivityShares from '../reducers/onlineCourseActivityShares';
import onlineCourseAnnouncements from '../reducers/onlineCourseAnnouncements';
import onlineCourseFeedbacks from '../reducers/onlineCourseFeedbacks';
import onlineCoursePolls from '../reducers/onlineCoursePolls';
import onlineCourseQuestions from '../reducers/onlineCourseQuestions';
import onlineCourses from '../reducers/onlineCourses';
import onlineCourseSurveys from '../reducers/onlineCourseSurveys';
import onlineCourseUsers from '../reducers/onlineCourseUsers';
import platform from '../reducers/platform';
import quizQuestions from '../reducers/quizQuestions';
import quizzes from '../reducers/quizzes';
import reports from '../reducers/reports';
import resourceReactions from '../reducers/resourceReactions';
import resourceViews from '../reducers/resourceViews';
import search from '../reducers/search';
import siteTours from '../reducers/siteTours';
import standardApiRequests, { combinedReducerEnhancer } from '../reducers/standardApiRequests';
import studyRoomErrors from '../reducers/studyRoomErrors';
import studyRooms from '../reducers/studyRooms';
import tutorAvailabilities from '../reducers/tutorAvailabilities';
import tutorFeedbacks from '../reducers/tutorFeedbacks';
import tutorRequests from '../reducers/tutorRequests';
import users from '../reducers/users';
import votes from '../reducers/votes';
import whiteboards from '../reducers/whiteboards';
import workShiftUsers from '../reducers/workShiftUsers';

const reducer = combineReducers({
  apiRequests: standardApiRequests,
  activityBookmarks: standardApiRequests,
  appState,
  attachments,
  announcements,
  announcementResponses,
  blogPosts: standardApiRequests,
  chats,
  chatMessages,
  classRepCourses: standardApiRequests,
  classRepCourseReports: standardApiRequests,
  comments,
  contestEntries: standardApiRequests,
  contractorInfos: standardApiRequests,
  courseAssessments: standardApiRequests,
  courseAssessmentUserNotes: standardApiRequests,
  courseAssignments: standardApiRequests,
  courseAssignmentUsers: standardApiRequests,
  courses: standardApiRequests,
  courseInstructors: standardApiRequests,
  courseLessons,
  courseNotesPackages: standardApiRequests,
  courseSchedules: standardApiRequests,
  courseScheduleEvents: standardApiRequests,
  courseSections,
  discountCodes: standardApiRequests,
  ejEssays: standardApiRequests,
  ejTemplates: standardApiRequests,
  emails: standardApiRequests,
  faqs,
  flashCards: standardApiRequests,
  flashCardDecks: standardApiRequests,
  forumPosts,
  forumReplies,
  invoices: standardApiRequests,
  jobApplicants: standardApiRequests,
  jobApplicantNotes: standardApiRequests,
  jobPositions: standardApiRequests,
  messages,
  notifications,
  onlineCourses,
  onlineCourseActivities: standardApiRequests,
  onlineCourseActivityLinks: standardApiRequests,
  onlineCourseActivityFeedbacks: standardApiRequests,
  onlineCourseActivityShares,
  onlineCourseAnnouncements,
  onlineCourseChapters: standardApiRequests,
  onlineCourseFeedbacks,
  onlineCoursePolls,
  onlineCourseQuestions,
  onlineCourseQuestionReplies: standardApiRequests,
  onlineCourseSections: standardApiRequests,
  onlineCourseSurveys,
  onlineCourseUsers,
  partners: standardApiRequests,
  partnerUsers: standardApiRequests,
  platform,
  reports,
  resourceViews,
  quizzes,
  quizQuestions,
  quizQuestionSubmissions: standardApiRequests,
  scholarships: standardApiRequests,
  scholarshipApplications: standardApiRequests,
  schools: standardApiRequests,
  search,
  siteTours,
  stripeProducts: standardApiRequests,
  studentTutorContracts: standardApiRequests,
  studyBuddyQuestions: standardApiRequests,
  studyBuddyResponses: standardApiRequests,
  studyRooms,
  studyRoomErrors,
  studyGroups: standardApiRequests,
  studyGroupUsers: standardApiRequests,
  studySessions: standardApiRequests,
  studySessionUsers: standardApiRequests,
  subscriptions: standardApiRequests,
  tokenCredits: standardApiRequests,
  tokenPurchases: standardApiRequests,
  tutorAvailabilities,
  tutorFeedbacks,
  tutorRequests,
  tutoringSessionStudentFeedbacks: standardApiRequests,
  tutoringSessionTutorFeedbacks: standardApiRequests,
  users,
  userCourses: standardApiRequests,
  votes,
  resourceReactions,
  whiteboards,
  workShifts: standardApiRequests,
  workShiftUsers,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      trace: true,
      traceLimit: 25,
    })
  : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  sentryReduxEnhancer,
  // other store enhancers if any
);

export default initialState => {
  window.REDUX_INITIAL_STATE = initialState;
  return createStore(combinedReducerEnhancer(reducer), initialState, enhancer);
};
