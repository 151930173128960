import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import { faFacebookSquare, faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ spacing }) => ({
    btn: {
      width: 30,
      height: 30,
      '& + &': { marginLeft: spacing(1) },
    },
  }),
  { name: 'SocialMediaButtons' },
);
const LINKS_CONFIG = [
  {
    key: 'instagram',
    icon: faInstagram,
    url: 'https://www.instagram.com/wizeprep/',
  },
  {
    key: 'youtube',
    icon: faYoutube,
    url: 'https://www.youtube.com/channel/UC_PXdjw6d2o1KcTMUtcDgoQ',
  },
  {
    key: 'facebook',
    icon: faFacebookSquare,
    url: 'https://www.facebook.com/wizeprep',
  },
  {
    key: 'linkedin',
    icon: faLinkedin,
    url: 'https://www.linkedin.com/company/25070493/',
  },
  { key: 'tiktok', icon: faTiktok, url: 'https://www.tiktok.com/@wizeprep' },
];

function SocialMediaButtons(props) {
  const { ButtonProps, IconProps } = props;
  const classes = useStyles(props);

  return LINKS_CONFIG.map(({ icon, key, url }) => (
    <IconButton
      key={key}
      component="a"
      href={url}
      name={key}
      rel="noreferrer"
      target="_blank"
      {...ButtonProps}
      className={classNames(classes.btn, ButtonProps?.className)}
    >
      <FontAwesomeIcon icon={icon} size="xs" {...IconProps} />
    </IconButton>
  ));
}

SocialMediaButtons.propTypes = {
  ButtonProps: PropTypes.object,
  IconProps: PropTypes.object,
};
export default SocialMediaButtons;
