import { StyledEngineProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import { trackCoreWebVitals } from 'my-core/gtm-events';

import Root from 'my-components/Root';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function MainApp() {
  const mainStore = ReactOnRails.getStore('mainStore');
  useEffect(trackCoreWebVitals, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={mainStore}>
          <Root />
        </Provider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}
