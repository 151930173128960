import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'CREATE_RESOURCE_REACTION_SUCCESS',
  'CREATE_RESOURCE_REACTION_FAIL',
  'UPDATE_RESOURCE_REACTION_SUCCESS',
  'UPDATE_RESOURCE_REACTION_FAIL',
  'DELETE_RESOURCE_REACTION_SUCCESS',
  'DELETE_RESOURCE_REACTION_FAIL',
]);

export default actionTypes;
