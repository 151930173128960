import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'SET_CURRENT_SEARCH_QUERY',
  'PERFORMING_SEARCH',
  'FETCH_SEARCH_RESULTS_SUCCESS',
  'FETCH_SEARCH_RESULTS_FAIL',
]);

export default actionTypes;
