// import { Howl } from 'howler';
import chatNotificationMP3 from 'sounds/chat-notification.mp3';
import chatNotificationWav from 'sounds/chat-notification.wav';
import tokensAddedMP3 from 'sounds/coins.mp3';
import tokensAddedWav from 'sounds/coins.wav';
import ringtoneMP3 from 'sounds/ringtone.mp3';
import ringtoneWav from 'sounds/ringtone.wav';

function createSound(opts) {
  let isPlaying = false;
  let howlObj = null;
  function play() {
    isPlaying = true;
    if (howlObj) {
      howlObj.play();
    } else {
      createHowl(obj => isPlaying && obj.play());
    }
  }

  function createHowl(callback) {
    import(/* webpackChunkName: "howler" */ 'howler').then(({ Howl }) => {
      if (!howlObj) howlObj = new Howl(opts);
      callback?.(howlObj);
    });
  }

  function stop() {
    if (isPlaying && howlObj) {
      howlObj.stop();
    }
    isPlaying = false;
  }

  return { play, stop, preload: createHowl };
}

export const ringtone = createSound({
  src: [ringtoneMP3, ringtoneWav],
  loop: true,
});

export const chatNotification = createSound({
  src: [chatNotificationMP3, chatNotificationWav],
  loop: false,
});

export const tokensAddedSound = createSound({
  src: [tokensAddedMP3, tokensAddedWav],
  loop: false,
});
