//github.com/rstacruz/react-meta-elements/blob/master/src/index.ts
import { useEffect } from 'react';

export default function useTitle(title) {
  useEffect(() => {
    if (!title) return;
    const oldTitle = document.title;
    document.title = title;
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
}
