import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'TOGGLE_WORK_SHIFT_SIGNUP',
  'TOGGLE_WORK_SHIFT_SIGNUP_SUCCESS',
  'TOGGLE_WORK_SHIFT_SIGNUP_FAIL',
  'RECEIVE_WORK_SHIFT_SIGNUP_PUSH',
]);

export default actionTypes;
