import { v4 as uuidV4 } from 'uuid';

import { getMessageFromError } from 'my-core/error-utils';

import types from '../constants/MessageActionTypes';

export function addMessage(messageArg) {
  const message = typeof messageArg === 'string' ? { content: messageArg } : messageArg;
  return {
    type: types.ADD_MESSAGE,
    message: {
      id: uuidV4(),
      ...message,
    },
  };
}

export function addErrorMessage(error) {
  return addMessage({ content: typeof error === 'string' ? error : getMessageFromError(error), type: 'error' });
}

export function deleteMessage(messageId) {
  return {
    type: types.REMOVE_MESSAGE,
    id: messageId,
  };
}
