import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_ONLINE_COURSE',
  'ADD_ONLINE_COURSES',

  'FETCHING_ONLINE_COURSES',
  'FETCH_ONLINE_COURSE_SUCCESS',
  'FETCH_ONLINE_COURSE_FAIL',

  'FETCH_ONLINE_COURSE_BOOKLET_SUCCESS',
  'FETCH_ONLINE_COURSE_BOOKLET_FAIL',

  'FETCH_ONLINE_COURSE_INSTRUCTOR_OVERVIEW_SUCCESS',
  'FETCH_ONLINE_COURSE_INSTRUCTOR_OVERVIEW_FAIL',

  'REMOVE_ONLINE_COURSE',
  'CREATE_ONLINE_COURSE_SUCCESS',
  'CREATE_ONLINE_COURSE_FAIL',

  'UPDATE_ONLINE_COURSE_FETCHING',
  'UPDATE_ONLINE_COURSE_SUCCESS',
  'UPDATE_ONLINE_COURSE_FAIL',

  'FETCH_USER_ONLINE_COURSES',
  'FETCH_USER_ONLINE_COURSES_SUCCESS',
  'FETCH_USER_ONLINE_COURSES_FAIL',

  'FETCH_ONLINE_COURSE_STATS_SUCCESS',
  'FETCH_ONLINE_COURSE_STATS_FAIL',

  'FETCH_ONLINE_COURSE_EDITOR_ACTIVITIES_SUCCESS',
  'FETCH_ONLINE_COURSE_EDITOR_ACTIVITIES_FAIL',

  // ADMIN ACTION TYPES
  'FETCH_ADMIN_ONLINE_COURSES_SUCCESS',
  'FETCH_ADMIN_ONLINE_COURSES_FAIL',

  'ADD_USER_TO_ONLINE_COURSE_SUCCESS',
  'ADD_USER_TO_ONLINE_COURSE_FAIL',
]);

export default actionTypes;
