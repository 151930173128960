import types from '../constants/WorkShiftUserActionTypes';
import { addItemsToState, removeItem } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function workShiftUsers(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_WORK_SHIFT_SIGNUP: {
      const existingWSU = Object.values(state.items).find(
        wsu => wsu.user_id === action.userId && wsu.work_shift_id === action.workShiftId,
      );
      if (existingWSU) {
        return removeItem(
          {
            ...state,
            status: {
              ...state.status,
              toggleSignupCache: { ...state.status.toggleSignupCache, [action.cacheKey]: existingWSU },
            },
          },
          existingWSU.id,
        );
      } else {
        return addItemsToState(state, [
          { id: action.cacheKey, user_id: action.userId, work_shift_id: action.workShiftId },
        ]);
      }
    }
    case types.TOGGLE_WORK_SHIFT_SIGNUP_SUCCESS:
      return removeItem(addItemsToState(state, action.workShiftUsers), action.cacheKey);
    case types.TOGGLE_WORK_SHIFT_SIGNUP_FAIL: {
      const status = { ...state.status, signupError: { error: action.error } };
      if (state.items[action.cacheKey]) {
        return removeItem({ ...state, status }, action.cacheKey);
      } else {
        return addItemsToState({ ...state, status }, [state.status.toggleSignupCache[action.cacheKey]]);
      }
    }

    case types.RECEIVE_WORK_SHIFT_SIGNUP_PUSH:
      return removeItem(addItemsToState(state, action.workShiftUsers), action.deletedId);

    default:
      return state;
  }
}
