import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'ADD_ONLINE_COURSE_USER',

  'FETCH_ONLINE_COURSE_USERS_SUCCESS',
  'FETCH_ONLINE_COURSE_USERS_FAIL',

  'UPDATE_ONLINE_COURSE_USER_SUCCESS',
  'UPDATE_ONLINE_COURSE_USER_FAIL',

  'UPDATE_ONLINE_COURSE_USER_PROGRESS',
  'UPDATE_ONLINE_COURSE_USER_PROGRESS_SUCCESS',
  'UPDATE_ONLINE_COURSE_USER_PROGRESS_FAIL',

  'PURCHASE_ONLINE_COURSE_USER_SUCCESS',
  'PURCHASE_ONLINE_COURSE_USER_FAIL',

  'ADD_USER_TO_ONLINE_COURSE_SUCCESS',
  'ADD_USER_TO_ONLINE_COURSE_FAIL',

  'DELETE_ONLINE_COURSE_USER_SUCCESS',
  'DELETE_ONLINE_COURSE_USER_FAIL',

  'REFUND_ONLINE_COURSE_USER_SUCCESS',
  'REFUND_ONLINE_COURSE_USER_FAIL',

  'SEND_ONLINE_COURSE_USER_RECEIPT_SUCCESS',
  'SEND_ONLINE_COURSE_USER_RECEIPT_FAIL',

  'FETCH_ADMIN_ONLINE_COURSE_USERS_SUCCESS',
  'FETCH_ADMIN_ONLINE_COURSE_USERS_FAIL',

  'EXPIRE_ALL_ONLINE_COURSE_USER_ACCESS_SUCCESS',
  'EXPIRE_ALL_ONLINE_COURSE_USER_ACCESS_FAIL',
]);

export default actionTypes;
