import loadable from '@loadable/component';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { isMcatCourse } from 'my-core/course-utils';
import { loadOneTapSignIn } from 'my-core/google-sign-in';
import {
  accountSettingsPath,
  coursesPath,
  mcatLandingPath,
  partnerPath,
  studentMcatDashboardPath,
  studentSignupPath,
} from 'my-core/routes';
import { mcatIcon, SCHOOL_TYPES_CONFIG } from 'my-core/school-utils';
import { SITE_TOURS } from 'my-core/site-tour';
import { throttle } from 'my-utils';

import { BANNER_TRANSITION_DURATION } from 'my-components/Root/SiteWideBanner';
import SiteTourStep from 'my-components/SiteTourStep';
// import AppBarLivePrepNavLink from './AppBarLivePrepNavLink';
// import AppBarSearchMenu from './AppBarSearchMenu';
// import SearchMenu from 'my-components/SearchMenu';
import MorphFontAwesomeIcon from 'my-elements/MorphFontAwesomeIcon';
import MuiAvatar from 'my-elements/MuiAvatar';
import PopoverResponsive from 'my-elements/PopoverResponsive';

import AppBarDropDownLink from './AppBarDropDownLink';
import AppBarNavLink from './AppBarNavLink';
// import AppBarTextbooksLink from './AppBarTextbooksLink';
import Logo from './Logo';
import UserMenu from './UserMenu';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';

import { faBars, faBell, faComment, faCompass, faSun, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faMoon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessagesMenu = loadable(() => import(/* webpackChunkName: "messages-menu" */ './MessagesMenu'));
const NotificationMenu = loadable(() => import(/* webpackChunkName: "notification-menu" */ './NotificationMenu'));

export const APPBAR_SIDEDRAWER_CONTAINER_ID = 'appbar_prepend_placeholder';

const useStyles = makeStyles(
  ({ breakpoints, constants, palette, spacing, transitions: { create, duration }, typography }) => {
    const darkRootSelector = '$root_color &, $root_medium &, $root_dark &';
    const defaultTransitionProps = { duration: duration.standard };
    return {
      root: {
        height: constants.APPBAR_HEIGHT,
        backgroundColor: 'transparent',
        marginTop: 0,
        transition: [
          create('margin-top', { duration: BANNER_TRANSITION_DURATION }),
          create('height', { easing: 'ease-in' }),
        ],

        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: palette.background.paper,
          borderBottom: [[1, 'solid', palette.divider]],
          transition: create('opacity', defaultTransitionProps),
        },
        '&$root_light, &$root_color, &$root_medium, &$root_dark': {
          '&:before': { opacity: 0 },
        },

        // don't need appbar when printing (ie. course-instructors/:id/contract)
        '@media print': { display: 'none' },
      },
      root_light: {},
      root_color: {},
      root_medium: {},
      root_dark: {},
      root_siteBannerActive: { marginTop: constants.APPBAR_HEIGHT },
      // root_searchFocused: {
      //   height: 90,
      //   transition: [create('margin-top'), create('height', { delay: duration.shorter, easing: 'ease-out' })]
      // },

      toolbar: {
        minHeight: constants.APPBAR_HEIGHT,
        [breakpoints.down('sm')]: { padding: spacing(0, 1) },
      },
      logoTick: {
        transition: create(['fill'], defaultTransitionProps),
        '$root_dark &, $root_color &': { fill: palette.common.white },
        '$root_medium &': { fill: palette.grey[700] },
      },
      logoDash: {
        transition: create(['fill'], defaultTransitionProps),
        '$root_color &': { fill: palette.common.white },
      },

      navLink: {
        ...typography.button,
        flex: '0 0 auto',
        color: palette.text.primary,
        padding: [[6, 8]],
        transition: create(['color'], defaultTransitionProps),
        '&$navLink_active, &:hover': {
          backgroundColor: 'transparent', // for dropdown btn
          color: palette.text.secondary,
        },
        '$root_dark &, $root_color &': {
          color: palette.common.white,
          '&$navLink_active, &:hover': { color: palette.text.secondary },
          '&::before': { backgroundColor: palette.common.white },
        },
        '$root_medium &': {
          color: palette.common.black,
          '&$navLink_active, &:hover': { color: 'rgba(0,0,0,0.5)' },
          '&::before': { backgroundColor: palette.common.black },
        },
        '& .MuiButton-endIcon': {
          [breakpoints.down('sm')]: { display: 'none' },
          '& > svg': {
            fontSize: 12,
            transition: create(['transform'], { duration: duration.shorter }),
          },
        },
        [breakpoints.down('sm')]: { fontSize: 13 },
      },
      navLink_open: {
        '& svg': { transform: 'rotate(180deg)' },
      },
      navLink_active: {},
      button: {
        flex: '0 0 auto',
        minWidth: 0,
        [breakpoints.down('sm')]: { fontSize: 13, padding: [[4, 12]] },
      },
      button_text: {
        transition: create(['color'], defaultTransitionProps),
        [darkRootSelector]: {
          color: palette.common.white,
          '&:hover': { backgroundColor: 'rgba(255,255,255,0.2)' },
        },
      },
      button_contained: {
        '$root_color &, $root_medium &': {
          backgroundColor: palette.common.white,
          color: palette.primary.main,
          transition: create(['background-color', 'color'], defaultTransitionProps),
          '&:hover': { backgroundColor: palette.grey[200] },
        },
      },

      spacer: { flex: '1 1 0' },

      // this will change the color when the app bar is transparent and background is not 'light'
      whiteColorOnNonLight: {
        transition: create(['color'], defaultTransitionProps),
        '$root_dark &, $root_color &': {
          // [darkRootSelector]: {
          color: palette.common.white,
        },
        '$root_medium &': {
          color: palette.common.black,
        },
      },

      marginLeft: { marginLeft: spacing(1) },
      marginRight: { marginRight: spacing(1) },
    };
  },
  { name: 'MyAppBar' },
);

export default function MyAppBar(props) {
  const {
    color,
    currentUser,
    darkMode,
    hasChats,
    homePath,
    location,
    onChangeDarkMode,
    onGoogleLoginSuccess,
    onSignOut,
    onStartSiteTour,
    onToggleLoginForm,
    onToggleSignUpForm,
    routes,
    siteBannerActive,
  } = props;
  const classes = useStyles();

  const rootRef = useRef(null);

  const isXsOnly = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'));
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  const [isScrolled, setIsScrolled] = useState(false);
  // attach event handler scroll event
  useEffect(() => {
    const throttledScrollHandler = throttle(() => {
      const scrollTop = (document.scrollingElement || document.body).scrollTop;
      setIsScrolled(scrollTop > 55);
    }, 200);

    window.addEventListener('scroll', throttledScrollHandler);
    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
    };
  }, []);

  const [signUpText, loginProps, loginButtonProps, signupButtonProps] = useMemo(() => {
    if (currentUser.guest) {
      const isMcatPage = location.pathname.includes('mcat');
      const isTeacherPage = location.pathname.endsWith('for-educators');
      const loginProps = {
        role: isTeacherPage ? 'teacher' : undefined,
        messagingProduct: isMcatPage ? 'mcat' : undefined,
      };
      return [
        'Get Started for Free',
        loginProps,
        { onClick: () => onToggleLoginForm(loginProps) },
        isMcatPage ?
          { path: studentSignupPath({ product: 'mcat', redirect: studentMcatDashboardPath() }) }
        : { onClick: () => onToggleSignUpForm(loginProps) },
      ];
    }
    return [];
  }, [currentUser.guest, location.pathname, onToggleLoginForm, onToggleSignUpForm]);

  useEffect(() => {
    if (currentUser.guest) {
      const { redirect, role } = loginProps;
      loadOneTapSignIn(
        response => onGoogleLoginSuccess(response, redirect, role),
        () => console.error('one-tap google sign in error'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to fire one time
  }, []);

  // const [searchFocused, setSearchFocused] = useState(false);
  // const colorVariant = isScrolled || searchFocused ? '' : color;

  const colorVariant = isScrolled ? '' : color;

  // const isCanadian = (currentUser.school?.country_code || currentUser.country_code) === 'CA';
  const schoolType = currentUser.school?.type;
  const courseLinks = useMemo(
    () =>
      [
        (!schoolType || schoolType === 'high_school') && {
          icon: SCHOOL_TYPES_CONFIG.high_school.icon,
          path: coursesPath({ school_type: 'high_school' }),
          label: 'High School',
        },
        ...(!schoolType || schoolType === 'undergrad' ?
          [
            {
              icon: SCHOOL_TYPES_CONFIG.undergrad.icon,
              path: coursesPath({ school_type: 'undergrad' }),
              label: 'University',
            },
            {
              icon: mcatIcon,
              path:
                currentUser.user_courses.some(uc => isMcatCourse(uc.course)) ?
                  studentMcatDashboardPath()
                : mcatLandingPath(),
              label: 'MCAT',
            },
          ]
        : []),
      ].filter(Boolean),
    [currentUser.user_courses, schoolType],
  );

  const navLinkClasses = {
    root: classes.navLink,
    root_active: classes.navLink_active,
    root_open: classes.navLink_open,
  };

  return (
    <AppBar
      className={classNames(classes.root, classes[`root_${colorVariant}`], {
        // [classes.root_searchFocused]: searchFocused,
        [classes.root_siteBannerActive]: siteBannerActive,
      })}
      color="inherit"
      elevation={0}
      ref={rootRef}
    >
      <Toolbar className={classes.toolbar}>
        <Logo logoDashClassName={classes.logoDash} logoTickClassName={classes.logoTick} to={homePath} />
        <div className={classes.marginRight} id={APPBAR_SIDEDRAWER_CONTAINER_ID} />
        {renderNavLinks()}
        {isMdDown ?
          <AppBarDropDownLink
            classes={navLinkClasses}
            items={courseLinks}
            label="Courses"
            location={location}
            to={coursesPath()}
          />
        : courseLinks.map(({ label, path }, idx) => (
            <AppBarNavLink key={idx} classes={navLinkClasses} label={label} location={location} path={path} />
          ))
        }
        {/*!isXsOnly && isCanadian && (currentUser.guest || currentUser.school?.type === 'undergrad') && (
          <AppBarLivePrepNavLink classes={navLinkClasses} location={location} />
        )*/}
        {/* {currentUser.guest && <AppBarTextbooksLink classes={navLinkClasses} pathname={location.pathname} />} */}
        {isXsOnly && <div className={classes.spacer} />}
        {/*location.pathname.startsWith('/search') ? null : isXsOnly ? (
          <SearchMenu
            classes={{ root: classNames(classes.marginRight, classes.marginLeft) }}
            colorVariant={colorVariant}
            currentUser={currentUser}
          />
        ) : (
          <AppBarSearchMenu
            classes={{ root: classNames(classes.marginRight, classes.marginLeft) }}
            currentUser={currentUser}
            focused={searchFocused}
            onFocus={setSearchFocused}
          />
        )*/}
        {!isXsOnly && <div className={classes.spacer} />}
        {renderDarkModeToggle()}
        {renderSiteTourStep()}
        {renderFixedItems()}
      </Toolbar>
    </AppBar>
  );

  /**
   *
   * these render functions don't do much but they help break out some of the
   * logic so it is clear what this component actually renders
   *
   */

  function renderNavLinks() {
    if (isMdDown) return;

    const mainLinks = routes.main?.map((route, i) => (
      <AppBarNavLink key={i} classes={navLinkClasses} location={location} {...route} />
    ));

    if (!routes.partners.length) {
      return mainLinks;
    }

    const partnerLinks =
      routes.partners.length === 1 ?
        <AppBarNavLink
          classes={navLinkClasses}
          label={routes.partners[0].partner.name}
          location={location}
          path={partnerPath(routes.partners[0].partner)}
        />
      : <AppBarNavLink
          classes={navLinkClasses}
          label="Partners"
          location={location}
          paths={routes.partners.map(({ partner }) => ({
            label: partner.name,
            path: partnerPath(partner),
          }))}
        />;

    return (
      <>
        {mainLinks}
        {partnerLinks}
      </>
    );
  }

  function renderDarkModeToggle() {
    if (!isXsOnly)
      return (
        <Tooltip title={`Enable ${darkMode ? 'light' : 'dark'} mode`}>
          <IconButton className={classes.whiteColorOnNonLight} onClick={() => onChangeDarkMode(!darkMode)}>
            <MorphFontAwesomeIcon icon={darkMode ? faMoon : faSun} size="sm" />
          </IconButton>
        </Tooltip>
      );
  }

  function renderSiteTourStep() {
    if (!onStartSiteTour) return;

    return (
      <SiteTourStep step={SITE_TOURS.SITE_TOUR_HELP.steps.final} tourId={SITE_TOURS.SITE_TOUR_HELP.id}>
        {id => (
          <Tooltip title="Site Tour">
            <IconButton className={classes.whiteColorOnNonLight} id={id} onClick={onStartSiteTour}>
              <FontAwesomeIcon icon={faCompass} size="sm" />
            </IconButton>
          </Tooltip>
        )}
      </SiteTourStep>
    );
  }

  function renderFixedItems() {
    const darkModeToggleItem = isXsOnly && [
      {
        icon: darkMode ? faMoon : faSun,
        label: `Enable ${darkMode ? 'Light' : 'Dark'} Mode`,
        onClick: () => onChangeDarkMode(!darkMode),
      },
    ];
    if (currentUser.guest) {
      return (
        <>
          {!isXsOnly && (
            <Button
              className={classNames(classes.button, classes.button_text)}
              id="appbar_login"
              {...(loginButtonProps.path ? { component: Link, to: loginButtonProps.path } : loginButtonProps)}
              variant="text"
            >
              Log in
            </Button>
          )}
          <Button
            className={classNames(classes.button, classes.button_contained, classes.marginLeft)}
            id="appbar_signup"
            {...(signupButtonProps.path ? { component: Link, to: signupButtonProps.path } : signupButtonProps)}
            variant="contained"
          >
            {signUpText}
          </Button>
          {isMdDown && (
            <PopoverResponsive
              button={({ open, ref, setOpen }) => (
                <IconButton
                  className={classNames(classes.marginLeft, classes.whiteColorOnNonLight)}
                  onClick={() => setOpen(!open)}
                  ref={ref}
                >
                  <FontAwesomeIcon fixedWidth icon={open ? faTimes : faBars} size="sm" />
                </IconButton>
              )}
              component={UserMenu}
              componentProps={{
                items: [
                  routes.main?.flatMap(r => r.paths || r),
                  routes.resources,
                  darkModeToggleItem,
                  isXsOnly && [
                    { button: 'outlined', label: 'Log in', ...loginButtonProps },
                    { button: 'contained', label: signUpText, ...signupButtonProps },
                  ],
                ].filter(Boolean),
              }}
            />
          )}
        </>
      );
    }

    return (
      <>
        {hasChats && (
          <MessagesMenu
            className={classes.whiteColorOnNonLight}
            colorVariant={colorVariant}
            fallback={
              <IconButton className={classes.whiteColorOnNonLight} disabled>
                <FontAwesomeIcon icon={faComment} size="sm" />
              </IconButton>
            }
            isXS={isXsOnly}
            userId={currentUser.id}
          />
        )}
        <NotificationMenu
          className={classes.whiteColorOnNonLight}
          colorVariant={colorVariant}
          currentUser={currentUser}
          fallback={
            <IconButton className={classes.whiteColorOnNonLight} disabled>
              <FontAwesomeIcon icon={faBell} size="sm" />
            </IconButton>
          }
        />
        <PopoverResponsive
          button={
            <Button
              className={classNames(classes.button, classes.whiteColorOnNonLight)}
              color="inherit"
              size="small"
              variant="text"
            >
              <FontAwesomeIcon className={classes.marginRight} icon={faCaretDown} />
              <MuiAvatar alt="more links" size={30} src="thumb" user={currentUser} />
            </Button>
          }
          component={UserMenu}
          componentProps={{
            items: [
              [{ path: accountSettingsPath(), label: 'My Account' }],
              darkModeToggleItem,
              ...(isMdDown ?
                [
                  [...(routes.main?.flatMap(r => r.paths || r) || []), ...(routes.collapsed || [])].filter(Boolean),
                  routes.partners.length &&
                    routes.partners.map(pu => ({
                      label: pu.partner.name,
                      path: partnerPath(pu.partner),
                    })),
                ]
              : [routes.collapsed]),
              routes.role,
              routes.resources,
              [{ button: 'outlined', label: 'Sign Out', onClick: onSignOut }],
            ].filter(Boolean),
          }}
        />
      </>
    );
  }
}
