import { numberToCurrency } from 'my-utils';

import booksIcon from 'images/icons/books.svg';
import brainIcon from 'images/icons/brain.svg';
import rocketIcon from 'images/icons/rocketship.svg';
import walletIcon from 'images/icons/wallet.svg';

export function hasAnyRoles(user, roles) {
  if (!user?.roles) return false;
  return (Array.isArray(roles) ? roles : [roles]).some(r => user.roles.includes(r));
}

export function hasManagerRole(user) {
  return hasAnyRoles(user, ['blog_manager', 'jobs_manager', 'marketing_manager']);
}

export function getUserFirstName(user) {
  return user?.first_name || user?.name?.split(' ')[0];
}
export function getUserLastName(user) {
  // /\s+([^\s]+)\s*$/ -- everything after last space
  // /\s+(.+)$/        -- everything after first space
  return user?.last_name || /\s+(.+)$/.exec(user?.name)?.[1];
}
export function getUserFullName(user) {
  if (!user) return;
  return user.name || [user.first_name, user.last_name].filter(Boolean).join(' ');
}
// basically the 'display_name' or 'first name + last initial'
export function getUserDisplayName(user) {
  if (!user) return false;
  if (user.display_name) return user.display_name;
  const firstName = getUserFirstName(user);
  if (!firstName) return false;
  const initial = getUserLastName(user)?.charAt(0) || '';
  return `${firstName} ${initial}`;
}

export function getUserGreeting(user) {
  const hour = new Date().getHours();
  const name = getUserFirstName(user);

  let greeting = '';
  if (hour >= 23 || hour <= 2) greeting = 'Late Night';
  else if (hour <= 11) greeting = 'Good Morning';
  else if (hour <= 16) greeting = 'Good Afternoon';
  else greeting = 'Good Evening';

  return `${greeting}${name ? `, ${name}` : ''} 😄`;
}

export function isUserAccountSetup(user, role = 'student') {
  switch (role) {
    case 'tutor':
      return !!user.tutored_courses?.length;
    case 'student':
      return !!(
        user.school_id &&
        user.year &&
        (['high_school', 'k_to_8'].includes(user.school_type) || (user.faculty && user.degree))
      );
  }
  return true;
}

export const STUDY_STATS_CONFIG = [
  { key: 'mcat_coaching_sessions', label: 'Med-School Coaching Sessions Attended', mcat: true },
  { key: 'mcat_cars', label: 'Weekly CARS Passages Completed', mcat: true },
  { key: 'mcat_events', label: 'MCAT Events Attended', mcat: true },
  { key: 'hours_studied', label: 'Hours Studied' },
  { key: 'booster_savings', label: '$ Saved on Boosters', formatter: x => numberToCurrency(x) },
  { key: 'courses_studied', label: 'Courses Studied' },
  { key: 'practice_questions_attempted', label: 'Questions Attempted' },
  { key: 'questions_asked', label: 'Q&A Questions' },
];

export const FACULTIES_CONFIG = {
  sciences: { label: 'Science', icon: brainIcon },
  commerce: { label: 'Business', icon: walletIcon },
  engineering: { label: 'Engineering', icon: rocketIcon },
  arts: { label: 'Arts', icon: booksIcon },
  other: { label: 'Other' },
};

export const ROLES_CONFIG = {
  admin: {
    key: 'admin',
    label: 'Admin',
  },
  instructor: {
    key: 'instructor',
    label: 'Instructor',
  },
  ss_instructor: {
    key: 'ss_instructor',
    label: 'Live-Prep Instructor',
  },
  tutor: {
    key: 'tutor',
    label: 'Tutor',
  },
  study_buddy_admin: {
    key: 'study_buddy_admin',
    label: 'Study Buddy Admin',
  },
  content_creator: {
    key: 'content_creator',
    label: 'Content Creator',
  },
  job_applicant: {
    key: 'job_applicant',
    label: 'Job Applicant',
  },
  class_rep: {
    key: 'class_rep',
    label: 'Class Rep',
  },
  school_manager: {
    key: 'school_manager',
    label: 'School Manager',
  },
  marketing_rep: {
    key: 'marketing_rep',
    label: 'Marketing Rep',
  },
  forum_moderator: {
    key: 'forum_moderator',
    label: 'Forum Moderator',
  },
  partner_member: {
    key: 'partner_member',
    label: 'Partner Member',
  },
  content_qa_specialist: {
    key: 'content_qa_specialist',
    label: 'QA Specialist',
  },
  teacher: {
    key: 'teacher',
    label: 'Teacher',
  },
  brand_ambassador: {
    key: 'brand_ambassador',
    label: 'Brand Ambassador',
  },
  marketing_manager: {
    key: 'marketing_manager',
    label: 'Marketing Manager',
  },
  jobs_manager: {
    key: 'jobs_manager',
    label: 'Jobs Manager',
  },
  blog_manager: {
    key: 'blog_manager',
    label: 'Blog Manager',
  },
  mcat_rep: {
    key: 'mcat_rep',
    label: 'MCAT Rep',
  },
  mcat_instructor: {
    key: 'mcat_instructor',
    label: 'MCAT Instructor',
  },
  mcat_sales_rep: {
    key: 'mcat_sales_rep',
    label: 'MCAT Sales Rep',
  },
};
