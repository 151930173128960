const ONLINE_COURSE_CONFIG = [
  {
    selector: onlineCourse => onlineCourse.activities?.find(a => a.type === 'intro'),
    type: 'activity',
    key: 'intro',
    title: 'Intro',
    pos: 'I',
  },
  {
    selector: onlineCourse => onlineCourse.chapters,
    type: 'group',
    title: 'Course Chapters',
    key: 'chapter',
    children: chapters =>
      chapters.map((chapter, chaptIdx) => ({
        selector: chapter,
        type: 'chapter',
        key: `${chaptIdx + 1}`,
        pos: `${chaptIdx + 1}`,
        children: CHAPTER_CONFIG,
      })),
  },
  {
    selector: onlineCourse => onlineCourse.activities?.filter(a => a.activity_type === 'Quiz'),
    type: 'group',
    key: 'mock-exam',
    pos: 'ME',
    title: 'Mock Exams',
    children: mockExams =>
      mockExams.map((exam, actIdx) => ({
        selector: exam,
        type: 'activity',
        key: `${actIdx + 1}`,
        pos: `${actIdx + 1}`,
      })),
  },
  // { type: 'group', key: 'mock-exam', children: [{ type: 'activity', path: '1', activity: {} }] }
];

const CHAPTER_CONFIG = [
  {
    selector: chapter => chapter.activities?.find(a => a.type === 'intro'),
    type: 'activity',
    key: 'intro',
    title: 'Intro',
    pos: 'I',
  },
  {
    selector: chapter => chapter.sections?.filter(s => s.type === 'core'),
    type: 'group',
    title: 'Core Sections',
    key: 'core',
    children: sections =>
      sections.map((section, sectIdx) => ({
        selector: section,
        type: 'section',
        key: `${sectIdx + 1}`,
        pos: `${sectIdx + 1}`,
        children: section =>
          section.activities.map((activity, actIdx) => ({
            selector: activity,
            type: 'activity',
            key: `${actIdx + 1}`,
            pos: `${actIdx + 1}`,
          })),
      })),
  },
  {
    selector: chapter => chapter.activities?.find(a => a.activity_type === 'Quiz'),
    type: 'activity',
    key: 'checkpoint-quiz',
    title: 'Checkpoint Quiz',
    pos: 'Q',
  },
  {
    selector: chapter => chapter.sections?.find(s => s.type === 'additional'),
    type: 'section',
    key: 'additional-practice',
    pos: 'P',
    children: section =>
      section.activities.map((activity, actIdx) => ({
        selector: activity,
        type: 'activity',
        key: `${actIdx + 1}`,
        pos: `${actIdx + 1}`,
      })),
  },
];

const PRACTICE_MODE_CONFIG = [
  {
    selector: onlineCourse => onlineCourse.chapters,
    type: 'group',
    title: 'Course Chapters',
    key: 'chapter',
    children: chapters =>
      chapters.map((chapter, chaptIdx) => ({
        selector: chapter,
        type: 'chapter',
        key: `${chaptIdx + 1}`,
        pos: `${chaptIdx + 1}`,
        children: chapter =>
          chapter.sections
            .filter(s => s.type === 'core')
            .map((section, sectIdx) => ({
              selector: section,
              type: 'section',
              key: `${sectIdx + 1}`,
              pos: `${sectIdx + 1}`,
            })),
      })),
  },
];

export default function getOnlineCourseContentMap(onlineCourse) {
  return buildContentMap(onlineCourse, ONLINE_COURSE_CONFIG);
}
export function getChapterContentMap(chapter) {
  return buildContentMap(chapter, CHAPTER_CONFIG);
}
export function getPracticeModeContentMap(onlineCourse) {
  return buildContentMap(onlineCourse, PRACTICE_MODE_CONFIG);
}
export function getChaptersWithSectionsContentMap(onlineCourse) {
  return buildContentMap(onlineCourse, [{ ...PRACTICE_MODE_CONFIG[0], selector: oc => oc.chapters_with_sections }]);
}

function buildContentMap(root, config) {
  const result = {
    type: 'root',
    data: root,
    children: [],
  };
  result.children._map = {};
  // const result = [];
  // result._map = {};
  recurseAddItems(root, config, result);
  return result;
}

function recurseAddItems(object, list, parent, posStack = [], fullPath = '') {
  let idx = 0;
  list.forEach(fragConfig => {
    const { children, key, pos, selector, ...rest } = fragConfig;
    const frag = typeof selector === 'function' ? selector(object) : selector;
    if (frag) {
      const newPosStack = pos ? [...posStack, pos].filter(Boolean) : posStack;
      const newPath = `${fullPath}/${key}`;
      const fragObj = {
        ...rest,
        parent,
        key,
        childIndex: idx++,
        fullPath: newPath,
        pos,
        data: frag,
        fullPos: pos && newPosStack.join('.'),
      };
      if (children) {
        fragObj.children = [];
        fragObj.children._map = {};
        // const childrenRes = [];
        // childrenRes._map = {};
        // childrenRes.parent = fragObj;
        if (typeof children === 'function') {
          recurseAddItems(frag, children(frag), fragObj, newPosStack, newPath);
        } else if (Array.isArray(children)) {
          recurseAddItems(frag, children, fragObj, newPosStack, newPath);
        }
        // fragObj.children = childrenRes;
      }
      fragObj.data = frag;
      parent.children.push(fragObj);
      parent.children._map[fragObj.key] = fragObj;
    }
  });
}

export function getComponentAtPath(keys, ocMap) {
  return keys.filter(Boolean).reduce((mem, key) => {
    const obj = mem?.children?._map?.[key];
    // if (idx < keys.length - 1) return obj.children;
    return obj;
  }, ocMap);
}

export function allChildren(node) {
  if (!node.children) return [];
  return node.children.concat(node.children.flatMap(allChildren));
}

export function findChild(node, matcher) {
  if (!node.children) return;
  for (const child of node.children) {
    if (matcher(child)) return child;
    const matchingChild = findChild(child, matcher);
    if (matchingChild) return matchingChild;
  }
  return;
}

export function allParents(node) {
  const res = [];
  let parent = node.parent;
  while (parent) {
    res.push(parent);
    parent = parent.parent;
  }
  return res;
}

export function findAncestor(node, test) {
  while (node && !test(node)) {
    node = node.parent;
  }
  return node;
}

export function getNext(node, filter) {
  let nextComp = _getNext(node);
  while (filter && nextComp && !filter(nextComp)) {
    nextComp = _getNext(nextComp);
  }
  return nextComp;
}

function _getNext(node) {
  if (node.children?.length) {
    return node.children[0];
  }
  let parent = node.parent;
  while (parent) {
    if (node.childIndex < parent.children.length - 1) {
      return parent.children[node.childIndex + 1];
    }
    node = parent;
    parent = parent.parent;
  }
  return null;
}

export function getPrev(node, filter) {
  let prevComp = _getPrev(node);
  while (filter && prevComp && !filter(prevComp)) {
    prevComp = _getPrev(prevComp);
  }
  return prevComp;
}

function _getPrev(node) {
  let parent = node.parent;
  if (!parent || node.childIndex === 0) return parent;
  let result = parent.children[node.childIndex - 1];
  while (result.children?.length) {
    result = result.children[result.children.length - 1];
  }
  return result;
}

export function renderContentMap(contentMap, renderer) {
  function childRenderer(content) {
    return renderer(content, () => content.children?.map(childRenderer));
  }
  return childRenderer(contentMap); // contentMap.children.map(childRenderer);
}

export function filterOnlineCourseContentsByQuery(contentMap, query) {
  query = query?.trim().toLowerCase();
  if (!query) return contentMap;
  return filterOnlineCourseContent(
    contentMap,
    node => ['activity', 'chapter', 'section'].includes(node.type) && node.data.title.toLowerCase().includes(query),
  );
}

export function filterOnlineCourseContent(contentMap, filterFn) {
  if (!filterFn || !contentMap) return contentMap;

  const filteredChildren = contentMap.children
    ?.map(child => {
      if (filterFn(child)) return child;
      const filteredChild = filterOnlineCourseContent(child, filterFn);
      if (filteredChild.children?.length) return filteredChild;
    })
    .filter(Boolean);

  if (filteredChildren) filteredChildren._map = filteredChildren.reduce((mem, c) => (mem[c.key] = c) && mem, {});

  return {
    ...contentMap,
    children: filteredChildren,
  };
}

export function getNodeTitleText(node) {
  let text = node.data.title;
  if (!text || text.length < 20 || text.match(/^(practice|example)$/i)) {
    text = [findAncestor(node.parent, n => n.data.title)?.data?.title, text].filter(Boolean).join(': ');
  }
  return text;
}
