import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { appBarNavLinkClicked } from 'my-core/gtm-events';
import { isTouchEnabled } from 'my-core/user-agent-utils';

import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ breakpoints, palette }) => ({
    root: { position: 'relative' },
    root_active: {},
    root_open: {},

    paper: {
      position: 'absolute',
      width: 'max-content',
      maxWidth: '90vw',
      transformOrigin: 'top',
      right: -80,
      [breakpoints.down('sm')]: { right: -100 },
    },
    listItem: {
      color: palette.text.primary,
      '&:hover, &$listItem_active': { backgroundColor: palette.action.hover },
    },
    listItem_active: {},
    listItemIcon: { minWidth: 40 },
  }),
  { name: 'AppBarDropDownLink' },
);

export default function AppBarDropDownLink(props) {
  const { active: activeProp, fetching, items, label, location, onOpen, to } = props;
  const classes = useStyles(props);
  const canHover = !isTouchEnabled();

  const matchPath = useCallback(
    path => {
      if (!path) return false;
      const [pathname, search] =
        typeof path === 'string' ? path.split('?') : [path.pathname, path.search?.substring(1)];
      return location.pathname === pathname && (!search || location.search.includes(search));
    },
    [location],
  );

  const itemMatches = useMemo(() => items.map(i => i && matchPath(i.path)), [items, matchPath]);
  const active = useMemo(
    () => activeProp || matchPath(to) || itemMatches.some(Boolean),
    [activeProp, matchPath, to, itemMatches],
  );

  const [buttonOpen, setButtonOpen] = useState(false);

  if (items.length === 1)
    return (
      <Link
        className={classNames(classes.root, { [classes.root_active]: active })}
        onClick={() => appBarNavLinkClicked(label)}
        to={items[0].path}
      >
        {label}
      </Link>
    );

  return (
    <div className={classes.root} onMouseLeave={() => setButtonOpen(false)}>
      <Button
        className={classNames(classes.root, {
          [classes.root_open]: buttonOpen,
          [classes.root_active]: active,
        })}
        component={canHover && to ? Link : undefined}
        endIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" />}
        onClick={() => {
          setButtonOpen(!buttonOpen);
          appBarNavLinkClicked(label);
        }}
        onMouseEnter={
          canHover ?
            () => {
              onOpen?.();
              setButtonOpen(true);
            }
          : undefined
        }
        to={canHover ? to : undefined}
      >
        {label}
      </Button>
      <Grow in={buttonOpen}>
        <Paper className={classes.paper} elevation={4}>
          <List component="nav">
            {items.map((item, idx) => {
              if (fetching) {
                return (
                  <ListItem key={idx}>
                    <ListItemText primary={<Skeleton width={80 + Math.floor(Math.random() * 80)} />} />
                  </ListItem>
                );
              }
              return (
                <ListItem
                  key={idx}
                  className={classNames(classes.listItem, { [classes.listItem_active]: itemMatches[idx] })}
                  component={Link}
                  onClick={() => {
                    setButtonOpen(false);
                    appBarNavLinkClicked(item.label);
                  }}
                  to={item.path}
                >
                  {item.icon && (
                    <ListItemIcon className={classes.listItemIcon}>
                      <FontAwesomeIcon fixedWidth icon={item.icon} size="lg" />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grow>
    </div>
  );
}
