import types from '../constants/OnlineCourseActivityShareActionTypes';
import { addNewItems } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function onlineCourseActivityShares(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_ONLINE_COURSE_ACTIVITY_SHARE:
      return { ...state, status: { ...state.status, [action.createKey]: { creating: true } } };
    case types.CREATE_ONLINE_COURSE_ACTIVITY_SHARE_SUCCESS:
      return {
        ...state,
        items: addNewItems(state, action.onlineCourseActivityShares),
        status: { ...state.status, [action.createKey]: { success: true, id: action.onlineCourseActivityShares[0].id } },
      };
    case types.CREATE_ONLINE_COURSE_ACTIVITY_SHARE_FAIL:
      return { ...state, status: { ...state.status, [action.createKey]: { error: action.error } } };

    default:
      return state;
  }
}
