import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'CREATE_COURSE_SECTION_SUCCESS',
  'CREATE_COURSE_SECTION_FAIL',
  'UPDATE_COURSE_SECTION_SUCCESS',
  'UPDATE_COURSE_SECTION_FAIL',
  'DELETE_COURSE_SECTION_SUCCESS',
  'DELETE_COURSE_SECTION_FAIL',
]);

export default actionTypes;
