import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';

const rootCss = css({
  '& .gear': {
    transformOrigin: 'center',
    transformBox: 'fill-box',
    animation: 'maintenanceGears 10s linear infinite',
  },

  '@keyframes maintenanceGears': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});

export default function Maintenance({ className }) {
  const { palette } = useTheme();

  return (
    <svg className={className} css={rootCss} viewBox="0 0 600 600">
      <title>maintenance</title>
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="a" x1="300" x2="300" y1="475" y2="440">
          <stop offset="0" stopColor={palette.grey[400]} />
          <stop offset="1" stopColor={palette.grey[800]} />
        </linearGradient>
      </defs>
      {/* <ellipse cx="300.31" cy="498.46" rx="206.21" ry="12.98" style={{ fill: palette.grey[200], opacity: '0.4' }} /> */}
      <path d="M246.57,454a57.56,57.56,0,0,1-3.09,32.12H357.14q-8-16.07-5.56-32.12Z" fill="url(#a)" />
      <path
        d="M490.56,404H109.44V180.29a3,3,0,0,1,3-3H487.53a3,3,0,0,1,3,3Z"
        style={{ fill: palette.background.paper, stroke: palette.logo.dark, strokeWidth: 12.55 }}
      />
      <rect
        height="12.35"
        rx="3.67"
        ry="3.67"
        style={{ fill: palette.grey[400] }}
        width="176.66"
        x="208.89"
        y="486.11"
      />
      <path
        d="M496.85,410.32v38.53a12.13,12.13,0,0,1-12.27,12H115.42a12.13,12.13,0,0,1-12.27-12V410.32Z"
        style={{ fill: palette.grey[400] }}
      />
      <path
        className="gear"
        d="M562,206.55l5.69-25.2-22-8.37-2.44-10.73,17.88-16.67L547,123.51,524.61,133l-7.72-5.28V103.31l-24.8-5.74-9.55,22.81-10.16,1.42-16.67-17.48-21.34,14,8.94,22-5.89,9.14-24-.81L408.05,173l22.66,9.39,1.63,11L414.86,209.6l14.23,21.34,22-9.14,8.74,6.3L459,251.88,484.37,257,492.7,235l11-2,16.46,17.68,21.54-14-9.75-21.95,5.89-8.13Zm-74.14.84A30.13,30.13,0,1,1,518,177.27,30.13,30.13,0,0,1,487.87,207.39Z"
        style={{ fill: palette.secondary.main }}
      />
      <path
        className="gear"
        d="M147,468.81l2.88-12.75-11.11-4.24-1.23-5.43,9-8.43-7.2-11.17L128,431.59l-3.91-2.68V416.57l-12.54-2.9-4.84,11.54-5.14.72-8.43-8.84-10.8,7.09,4.53,11.11-3,4.63-12.14-.42-2.72,12.32,11.46,4.75.83,5.56-8.85,8.22,7.2,10.8,11.11-4.63,4.42,3.19-.41,12,12.85,2.57,4.22-11.11,5.55-1,8.33,8.94,10.9-7.09-4.94-11.11,3-4.11Zm-37.51.42A15.24,15.24,0,1,1,124.68,454,15.24,15.24,0,0,1,109.44,469.23Z"
        style={{ fill: palette.secondary.main }}
      />
      <path
        d="M304.63,258.87h7.43l-20.77,62.26H275l-9.66-27.33h8.77a7.39,7.39,0,0,1,7,5.08l2.37,7.06,14.17-42A7.38,7.38,0,0,1,304.63,258.87Z"
        style={{ fill: palette.logo.dark }}
      />
      <path
        d="M332.52,263.92l-14.1,42-2.35-7a7.4,7.4,0,0,0-7-5.05h-8.76l9.71,27.3h16.19l20.75-62.26h-7.41A7.37,7.37,0,0,0,332.52,263.92Z"
        style={{ fill: palette.logo.dark }}
      />
      <path
        d="M261.77,258.87h-8.71l5.31,15a8.87,8.87,0,0,0,8.36,5.93h7.69l-5.69-16.05A7.39,7.39,0,0,0,261.77,258.87Z"
        style={{ fill: palette.logo.light }}
      />
    </svg>
  );
}
