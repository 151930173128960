import { faSchool, faStethoscope, faUniversity } from '@fortawesome/pro-light-svg-icons';

export const SCHOOL_TYPES_CONFIG = {
  k_to_8: { label: 'K - 8', icon: faSchool },
  high_school: { label: 'High School', icon: faSchool },
  undergrad: { label: 'Undergrad', icon: faUniversity },
  // graduate: { label: 'Graduate', icon: faGraduationCap }
};

export const mcatIcon = faStethoscope;
