import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';

const Root = styled('div', { shouldForwardProp: prop => prop !== 'topLevel' })(
  ({ theme: { breakpoints, constants, spacing }, topLevel }) => ({
    maxWidth: constants.WRAPPER_MAX_WIDTH_LG + parseInt(spacing(6)),
    width: '100%',
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    ...(topLevel ? { paddingTop: spacing(constants.APPBAR_HEIGHT_UNITS + 2) } : {}),
    [breakpoints.only('xs')]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  }),
);

export default forwardRef(function Container(props, ref) {
  const { children, className, topLevel } = props;

  return (
    <Root className={className} ref={ref} topLevel={topLevel}>
      {children}
    </Root>
  );
});
