import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';

// import { useEffect } from 'react';
// import api from 'my-core/api';
import trendingSvg from './error-background.svg';
import Maintenance from './Maintenance';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    root: {
      height: '100vh',
      width: '100vw',
      overflow: 'auto',

      display: 'flex',
      padding: spacing(6, 4),

      backgroundColor: palette.primary.background,
    },

    trending: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100vw',
    },

    content: {
      position: 'relative',
      maxWidth: 900,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      zIndex: 1,
    },
    message: {
      width: '100%',
      maxWidth: 500,
      marginTop: 60, // because the maintance image is not properly vertically aligned
      color: palette.primary.dark,
    },
    buttons: {
      color: 'initial',
      '& > * + *': { marginLeft: spacing(2) },
    },
    maintenance: {
      flex: 1,
      width: 450,
    },

    [breakpoints.down('md')]: {
      content: { flexDirection: 'column' },
      message: { marginTop: 0 },
      maintenance: {
        flex: 'auto',
        width: 250,
      },
      trending: { display: 'none' },
    },
    [breakpoints.only('xs')]: {
      buttons: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': { marginLeft: 0, marginTop: spacing(1) },
      },
    },
  }),
  { name: 'ClientError' },
);

function ClientError(props) {
  const { error, eventId, onResetError } = props;
  const classes = useStyles(props);

  const isRetryable = error.name === 'ChunkLoadError'; // chunk failed to load

  // useEffect(() => {
  //   if (error)
  //     api.postJSON('/error-reporting', { message: error.message, error: error.toString(), stack: error.stack });
  // }, [error]);

  // Add "id" to root so error can be detected by headless tools (pdf booklet print process)
  return (
    <div className={classes.root} id="app-client-error">
      <img alt="trending line graph" className={classes.trending} src={trendingSvg} />
      <div className={classes.content}>
        <div className={classes.message}>
          <Typography color="inherit" gutterBottom variant="h3">
            Uh oh! There was an error on this page
          </Typography>
          <Typography color="inherit" paragraph variant="body2">
            Head on back to the homepage, and feel free to leave some feedback before you go, it will help us fix the
            problem faster!
          </Typography>
          <div className={classes.buttons}>
            <Button component="a" href={window.location.origin} size="large" variant="contained">
              Homepage
            </Button>
            {!!eventId && (
              <Button
                color="inherit"
                onClick={() => Sentry.showReportDialog({ eventId: eventId })}
                size="large"
                variant="contained"
              >
                Report Feedback
              </Button>
            )}
            {(__DEV__ || isRetryable) && (
              <Button color="secondary" onClick={onResetError} size="large" variant="contained">
                Retry
              </Button>
            )}
          </div>
        </div>

        <Maintenance className={classes.maintenance} />
      </div>
    </div>
  );
}

export default ClientError;
