import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_ANNOUNCEMENT_RESPONSE_SUCCESS',
  'FETCH_ANNOUNCEMENT_RESPONSE_FAIL',
  'FETCH_ANNOUNCEMENT_RESPONSES_SUCCESS',
  'FETCH_ANNOUNCEMENT_RESPONSES_FAIL',
  'CREATE_ANNOUNCEMENT_RESPONSE_SUCCESS',
  'CREATE_ANNOUNCEMENT_RESPONSE_FAIL',
  'CREATE_ANNOUNCEMENT_RESPONSE_COMMENT_SUCCESS',
  'CREATE_ANNOUNCEMENT_RESPONSE_COMMENT_FAIL',
  'UPDATE_ANNOUNCEMENT_RESPONSE_SUCCESS',
  'UPDATE_ANNOUNCEMENT_RESPONSE_FAIL',
  'RESOLVE_ANNOUNCEMENT_RESPONSE_SUCCESS',
  'RESOLVE_ANNOUNCEMENT_RESPONSE_FAIL',
  'DELETE_ANNOUNCEMENT_RESPONSE_SUCCESS',
  'DELETE_ANNOUNCEMENT_RESPONSE_FAIL',
]);

export default actionTypes;
