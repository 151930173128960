import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'TOGGLE_APPSTATE_LOGIN_FORM',
  'TOGGLE_APPSTATE_SIGN_UP_FORM',
  'TOGGLE_APPSTATE_SEARCH',
  'TOGGLE_APPSTATE_CONTACT_US_FORM',
  'TOGGLE_APPSTATE_PRIVACY_POLICY',
  'TOGGLE_APPSTATE_TERMS_AND_CONDITIONS',
  'TOGGLE_APPSTATE_REAUTHENTICATION_FORM',
  'TOGGLE_APPSTATE_CHECKOUT_FORM',
  'SET_APP_BAR_COLOR_OVERRIDE',
  'TOGGLE_SITE_WIDE_BANNER',
  'CLEAR_APPSTATE_KEY',
]);

export default actionTypes;
