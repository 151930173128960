import { memo } from 'react';

import Loader from 'my-elements/animation/Loader';

export default memo(function LoadingPage() {
  return (
    <div css={({ constants }) => ({ paddingTop: constants.APPBAR_OFFSET + 30 })}>
      <Loader />
    </div>
  );
});
