import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'FETCH_TUTOR_FEEDBACK',
  'FETCH_TUTOR_FEEDBACK_SUCCESS',
  'FETCH_TUTOR_FEEDBACK_FAIL',

  'CREATE_TUTOR_FEEDBACK',
  'CREATE_TUTOR_FEEDBACK_SUCCESS',
  'CREATE_TUTOR_FEEDBACK_FAIL',

  'UPDATE_TUTOR_FEEDBACK',
  'UPDATE_TUTOR_FEEDBACK_SUCCESS',
  'UPDATE_TUTOR_FEEDBACK_FAIL',
]);

export default actionTypes;
