import onlineCourseTypes from '../constants/OnlineCourseActionTypes';
import { addItemsToState, clearFetchCache } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function courseLessons(state = initialState, action) {
  switch (action.type) {
    case onlineCourseTypes.FETCH_ONLINE_COURSE_BOOKLET_SUCCESS:
    case onlineCourseTypes.FETCH_ONLINE_COURSE_EDITOR_ACTIVITIES_SUCCESS:
      return addItemsToState(state, action.courseLessons);

    case onlineCourseTypes.CREATE_ONLINE_COURSE_SUCCESS:
    case onlineCourseTypes.UPDATE_ONLINE_COURSE_SUCCESS:
      return addItemsToState(clearFetchCache(state), action.courseLessons);

    default:
      return state;
  }
}
