import types from '../constants/FaqActionTypes';
// import { addItems, removeItem } from './utils';
import {
  createItemFail,
  createItemSuccess,
  fetchItemsFail,
  fetchItemsSuccess,
  removeItemFail,
  removeItemSuccess,
  updateItemFail,
  updateItemSuccess,
} from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function faqs(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FAQS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_FAQS_FAIL:
      return fetchItemsFail(state, action);

    case types.CREATING_FAQ: {
      return { ...state, status: { ...state.status, creating: true, created: false } };
    }
    case types.CREATE_FAQ_SUCCESS:
      return createItemSuccess(state, action);
    case types.CREATE_FAQ_FAIL:
      return createItemFail(state, action);

    case types.UPDATE_FAQ_SUCCESS:
      return updateItemSuccess(state, action);
    case types.UPDATE_FAQ_FAIL:
      return updateItemFail(state, action);

    case types.DELETE_FAQ_SUCCESS:
      return removeItemSuccess(state, action);
    case types.DELETE_FAQ_FAIL:
      return removeItemFail(state, action);

    case types.UPDATE_FAQS_ORDERING_SUCCESS: {
      const newItems = {};
      Object.keys(state.items).forEach(faqId => {
        const faq = state.items[faqId];
        newItems[faqId] = {
          ...faq,
          order: action.order[faqId] === undefined ? faq.order : action.order[faqId],
        };
      });
      return {
        ...state,
        items: newItems,
      };
    }
    // return {
    //   ...state,
    //   items: Object.keys(state.items).reduce(
    //     (mem, id) => (mem[id] = { ...state.items[id], order: action.order[id] === undefined ? state.items[id].order : action.order[id] }) && mem,
    //     {}
    //   )
    // };
    default:
      return state;
  }
}
