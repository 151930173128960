import { findAncestor } from './online-course-content-map';
import { getIsActiveOrTrialing } from './subscription-utils';

import {
  faEyeSlash,
  faGraduationCap,
  faMinusOctagon,
  faRocket,
  faSackDollar,
  faShieldCheck,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';

export function getOnlineCourseTitle(onlineCourse) {
  const { course_code, name } = onlineCourse || {};
  if (course_code && name) return `${course_code}: ${name}`;
  if (course_code) return course_code;
  if (name) return name;
  return '(Untitled)';
}

export function isSubscriptionOffering(onlineCourse) {
  return onlineCourse?.purchase_type === 'subscription';
}

export function canViewFullOnlineCourse(
  onlineCourse,
  onlineCourseUser,
  subscription,
  userCourse,
  courseSubscription,
  packageType = 'pro',
  // canViewBooklet = false
) {
  if (onlineCourse.user_access_level >= ACCESS_LEVELS.FULL_VIEW) return true;
  if (onlineCourse.user_access_level < ACCESS_LEVELS.LIMITED_VIEW) return false;

  if (isOcuActive(onlineCourseUser)) return true;

  switch (onlineCourse.purchase_type) {
    case 'standard': {
      const [price] = getPackagePricing(onlineCourse, packageType);
      return price === 0 && !!onlineCourseUser;
    }
    case 'subscription':
      return getIsActiveOrTrialing(subscription);
    case 'course_bundle':
      return userCourse?.status === 'active' || userCourse?.mcat_elite_515_info?.payment_status === 'full_paid'; // || courseSubscription?.status === 'active'; // disable courseSubscription access for now to account for LSAC Prep Plus flow
  }
  return false;
}

export function canViewOnlineCourseBooklet(
  onlineCourse,
  onlineCourseUser,
  subscription,
  userCourse,
  courseSubscription,
) {
  return canViewFullOnlineCourse(
    onlineCourse,
    onlineCourseUser,
    subscription,
    userCourse,
    courseSubscription,
    'pro',
    true,
  );
}

function isOcuActive(ocu) {
  return (
    ocu?.package_type === 'pro' &&
    // (ocu?.package_type === 'pro_practice' || ocu?.package_type === packageType) &&
    ocu.status === 'active'
  );
}

export function isPreviewableActivity(node, tokenPurchases) {
  return !!(
    node?.type === 'activity' &&
    ((node.parent?.type === 'root' &&
      (node.parent.data.show_instructor_intro || node.parent.data.status === 'published') &&
      node.key === 'intro') ||
      findAncestor(node, n => n.data.can_preview) ||
      tokenPurchases?.some(tp => tp.item_id === node.data.activity_id && tp.item_type === node.data.activity_type))
  );
}

export function getHasPreview(onlineCourseContent) {
  if (!onlineCourseContent) return false;
  return !!(
    onlineCourseContent.has_preview ||
    onlineCourseContent.can_preview ||
    onlineCourseContent.chapters?.some(getHasPreview) ||
    onlineCourseContent.sections?.some(getHasPreview) ||
    onlineCourseContent.activities?.some(a => a.can_preview)
  );
}

export function canViewPracticeSection(
  onlineCourse,
  onlineCourseUser,
  subscription,
  userCourse,
  courseSubscription,
  sectionNode,
) {
  if (!isPracticeModeEnabled(onlineCourse)) return false;
  if (canViewFullOnlineCourse(onlineCourse, onlineCourseUser, subscription, userCourse, courseSubscription, 'practice'))
    return true;

  return !!findAncestor(sectionNode, n => n.data.can_preview);
}

export function isPurchasable(oc) {
  return ['partner_members', 'public'].includes(oc.read_access);
}

export function isPracticeModeEnabled(oc) {
  return !!oc?.packages_config?.practice?.enabled;
}

export function getAllOCActivities(oc) {
  return [
    ...(oc.activities || []),
    ...(oc.chapters || []).flatMap(ch => [...ch.activities, ...ch.sections.flatMap(s => s.activities)]),
  ];
}
export function getAllOCActivityIds(oc) {
  return (
    oc.all_oc_activity_ids || [
      ...(oc.activities || []).map(a => a.id),
      ...(oc.chapters || []).flatMap(ch => [
        ...(ch.activities || []).map(a => a.id),
        ...ch.sections.flatMap(s => s.activities.map(a => a.id)),
      ]),
    ]
  );
}

export function getPackagePricing(onlineCourse, packageType) {
  if (!onlineCourse) return [];
  const isPro = packageType === 'pro';
  const { packages_config, price, regular_price } = onlineCourse;
  const packageConfig = packages_config?.[packageType];

  let pkgRegPrice = isPro ? regular_price : parseFloat(packageConfig.regular_price || 0);
  const pkgPrice = isPro ? price : parseFloat(packageConfig.price || 0);

  if ((pkgRegPrice || 0) < pkgPrice) pkgRegPrice = false;
  return [pkgPrice, pkgRegPrice];
}

export function getMockExamActivity(onlineCourse) {
  return onlineCourse.activities.find(oca => oca.type === 'mock_exam');
}

export const ONLINE_COURSE_TYPES = {
  exam_prep: {
    value: 'exam_prep',
    label: 'Exam Prep',
    abbrv: 'EP',
    color: 'cyan',
    has_assessment_date: true,
  },
  tutorial: {
    value: 'tutorial',
    label: 'Tutorial',
    abbrv: 'Tut',
    color: 'cyan',
  },
  template: {
    value: 'template',
    label: 'Template',
    abbrv: 'Tpl',
    color: 'orange',
  },
  textbook: {
    value: 'textbook',
    label: 'Textbook',
    abbrv: 'TB',
    color: 'purple',
  },
  class_pass: {
    value: 'class_pass',
    label: 'Smart Prep',
    abbrv: 'SP',
    color: 'primary',
  },
  ss_booklet: {
    value: 'ss_booklet',
    label: 'Study Session Booklet',
    abbrv: 'SS',
    color: 'secondary',
  },
};

export const ONLINE_COURSE_STATUSES = {
  hidden: {
    value: 'hidden',
    label: 'Hidden',
    description: 'Course is hidden from all students.  They cannot view any details or pre-register yet.',
    idx: 0,
    icon: faEyeSlash,
  },
  preparing: {
    value: 'preparing',
    label: 'Preparing',
    description:
      'Students can see some selective details about the course and pre-register, but they cannot view any course contents.',
    idx: 1,
    icon: faWrench,
  },
  published: {
    value: 'published',
    label: 'Published',
    description: 'Students can register and start viewing the course.',
    idx: 2,
    icon: faRocket,
  },
  closed: {
    value: 'closed',
    label: 'Closed',
    description: 'Course is closed.  Students cannot view this course, and instructors can no longer make edits.',
    idx: 3,
    icon: faMinusOctagon,
  },
};

export const ONLINE_COURSE_PACKAGE_TYPES = {
  none: {
    color: 'default',
    name: 'None',
  },
  pro: {
    color: 'primary',
    name: 'Learn',
  },
  practice: {
    color: 'green',
    name: 'Practice',
  },
  pro_practice: {
    color: 'purple',
    name: 'Learn-Practice Bundle',
  },
};

export const ONLINE_COURSE_PURCHASE_TYPES = {
  standard: {
    label: 'A-la-carte',
    icon: faSackDollar,
  },
  subscription: {
    label: 'Included in subscription',
    icon: faShieldCheck,
  },
  course_bundle: {
    label: 'Included with course purchase',
    icon: faGraduationCap,
  },
  // school_bundle: {
  //   label: 'School Bundle'
  // }
};

export const ACCESS_LEVELS = {
  NONE: 0,
  OVERVIEW: 10,
  LIMITED_VIEW: 20,
  FULL_VIEW: 30,
  STATS: 40,
  EDIT: 50,
  ADMIN: 60,
};

export const COPY_ACCESS_OPTIONS = {
  private: 'Private',
  wize_profs: 'Instructors',
};

export const READ_ACCESS_OPTIONS = {
  private: 'Private',
  wize_profs: 'Wize Profs',
  public: 'Public',
};

export const TEXTBOOK_LEVELS_CONFIG = {
  high_school: { label: 'High School', key: 'high_school', slug: 'high-school', school_type: 'high_school' },
  ap: { label: 'AP', key: 'ap', slug: 'ap', school_type: 'high_school' },
  undergrad: { label: 'University', key: 'undergrad', slug: 'undergrad', school_type: 'undergrad' },
};

export const PRACTICE_MODE_WELCOME_VIDEO = {
  urls: {
    hls: 'https://d3rw207pwvlq3a.cloudfront.net/shared/practice-mode-intro-v2/hlsPlaylist.m3u8',
    original: 'https://d3rw207pwvlq3a.cloudfront.net/shared/practice-mode-intro-v2/original/practice-mode-intro-v2.mp4',
  },
  poster: 'https://d3rw207pwvlq3a.cloudfront.net/shared/practice-mode-intro-v2/images/img_poster.0000000.jpg',
  videoId: 'practice-mode-welcome',
};
