import announcementActionTypes from '../constants/AnnouncementActionTypes';
import types from '../constants/AnnouncementResponseActionTypes';
import {
  createItemFail,
  createItemSuccess,
  fetchItemFail,
  fetchItemsFail,
  fetchItemsSuccess,
  fetchItemSuccess,
  removeItemFail,
  removeItemSuccess,
  updateItemFail,
  updateItemSuccess,
} from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function announcementResponses(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return fetchItemSuccess(state, action);
    case types.FETCH_ANNOUNCEMENT_RESPONSE_FAIL:
      return fetchItemFail(state, action);

    case types.FETCH_ANNOUNCEMENT_RESPONSES_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_ANNOUNCEMENT_RESPONSES_FAIL:
      return fetchItemsFail(state, action);

    case types.CREATING_FAQ: {
      return { ...state, status: { ...state.status, creating: true, created: false } };
    }
    case types.CREATE_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return createItemSuccess(state, action);
    case types.CREATE_ANNOUNCEMENT_RESPONSE_FAIL:
      return createItemFail(state, action);

    case types.UPDATE_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return updateItemSuccess(state, action);
    case types.UPDATE_ANNOUNCEMENT_RESPONSE_FAIL:
      return updateItemFail(state, action);

    case types.RESOLVE_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.announcementResponseId]: { ...state.items[action.announcementResponseId], reply_status: 'resolved' },
        },
      };

    case types.RESOLVE_ANNOUNCEMENT_RESPONSE_FAIL:
      return state;

    case types.DELETE_ANNOUNCEMENT_RESPONSE_SUCCESS:
      return removeItemSuccess(state, action);
    case types.DELETE_ANNOUNCEMENT_RESPONSE_FAIL:
      return removeItemFail(state, action);

    case types.CREATE_ANNOUNCEMENT_RESPONSE_COMMENT_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.item.commentable_id]: {
            ...state.items[action.item.commentable_id],
            comments: [...state.items[action.item.commentable_id].comments, action.item],
          },
        },
        status: {
          ...state.status,
          createComment: {
            success: true,
          },
        },
      };

    case types.CREATE_ANNOUNCEMENT_RESPONSE_COMMENT_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          createComment: {
            error: action.error,
          },
        },
      };

    case announcementActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        items: action.items.reduce(
          (mem, n) => {
            if (n.current_user_response) {
              return {
                ...mem,
                [n.current_user_response.id]: { ...n.current_user_response },
              };
            }
            return mem;
          },
          { ...state.items },
        ),
      };

    case announcementActionTypes.FETCH_ANNOUNCEMENT_SUCCESS:
      if (action.item.current_user_response) {
        return {
          ...state,
          items: {
            ...state.items,
            [action.item.current_user_response.id]: { ...action.item.current_user_response },
          },
        };
      }
      return state;

    default:
      return state;
  }
}
