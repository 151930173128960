import types from '../constants/ReportActionTypes';
import {
  createItemFail,
  createItemSuccess,
  fetchItemFail,
  fetchItemsFail,
  fetchItemsSuccess,
  fetchItemSuccess,
  removeItemFail,
  removeItemSuccess,
  updateItemFail,
  updateItemSuccess,
} from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_REPORT_SUCCESS:
      return fetchItemSuccess(state, action);
    case types.FETCH_REPORT_FAIL:
      return fetchItemFail(state, action);

    case types.FETCH_REPORTS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_REPORTS_FAIL:
      return fetchItemsFail(state, action);

    case types.CREATE_REPORT_SUCCESS:
      return createItemSuccess(state, action);
    case types.CREATE_REPORT_FAIL:
      return createItemFail(state, action);

    case types.UPDATE_REPORT_SUCCESS:
      return updateItemSuccess(state, action);
    case types.UPDATE_REPORT_FAIL:
      return updateItemFail(state, action);

    case types.DELETE_REPORT_SUCCESS:
      return removeItemSuccess(state, action);
    case types.DELETE_REPORT_FAIL:
      return removeItemFail(state, action);

    default:
      return state;
  }
}
