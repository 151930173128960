import reactionTypes from '../constants/ResourceReactionActionTypes';
import { createReaction, deleteReaction } from './utils';

const initialState = {
  status: {},
  items: {},
};

export default function chatMessages(state = initialState, action) {
  switch (action.type) {
    case reactionTypes.CREATE_RESOURCE_REACTION_SUCCESS: {
      return createReaction('ChatMessage', action, state);
    }
    case reactionTypes.DELETE_RESOURCE_REACTION_SUCCESS: {
      return deleteReaction('ChatMessage', action, state);
    }

    default:
      return state;
  }
}
