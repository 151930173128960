import { v4 as uuidV4 } from 'uuid';

import types from '../constants/PlatformActionTypes';

const initialState = {
  clientId: uuidV4(),
};

export default function platform(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_PLATFORM:
      return {
        ...state,
        ...action.platform,
      };
    default:
      return state;
  }
}
