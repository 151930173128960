import queryString from 'query-string';
import { createPath } from 'react-router-dom';

import { STUDY_SESSION_TYPES } from 'my-core/study-session-utils';

import { hasAnyRoles } from './user-utils';

function getSlugOrId(obj) {
  return typeof obj === 'object' ? obj.slug || obj.id : obj;
}

export function pathToString(path) {
  return typeof path === 'string' ? path : createPath(path || window.location);
}

export const aboutPath = () => '/about';
export const accountSettingsPath = () => '/profile';
export const adminDashboardPath = () => '/admin';
export const announcementsPath = () => '/announcements';
export const careersPath = () => '/careers';
export const chatPagePath = chatId => `/chats${chatId ? `/${chatId}` : ''}`;
export const courseAssignmentPath = ca => `/course-assignments/${ca.id}`;
export const classRepWizardPath = crc => `/class-rep-wizard/${getSlugOrId(crc)}`;
export const contentCreatorDashboardPath = () => '/content-creator-dashboard';
export const educatorsLandingPath = () => '/for-educators';
export const highSchoolLandingPath = () => '/high-school';
export const instructorDashboardPath = () => '/instructor-dashboard';
export const instructorDashboardOCQuestionPath = ocqId =>
  `${instructorDashboardPath()}?page=oc_questions&ocq_id=${ocqId}&status`;
export const invitesPagePath = () => '/invites';
export const jobApplicantPath = () => '/job-application';
export const livePrepLandingPath = () => '/live-prep';
export const livePrepSchoolPath = school => `/live-prep/schools/${getSlugOrId(school)}`;
export const marketingRepDashboardPath = () => '/marketing-rep-dashboard';
export const mcatRepDashboardPath = () => '/mcat-rep-dashboard';
export const brandAmbassadorDashboardPath = () => '/brand-ambassador-dashboard';
export const mcatEliteLandingPath = () => '/mcat'; // '/mcat-elite-515';
export const mcatFreeResources = () => '/mcat/free-resources';
export const mcatLandingEventsPath = () => '/mcat/events';
export const mcatEventsPath = eventType => `/mcat/events/${eventType}`;
export const mcatExamDates = () => '/mcat/exam-dates';
export const mcatLandingPath = mcatEliteLandingPath;
export const mcatCoursePagePath = () => '/courses/mcat';
export const mcatLandingPricingPath = () => `${mcatEliteLandingPath()}#pricing`;
export const mcatCarsPassagePath = weekNum => `/cars-passages/week-${weekNum}`;
export const medSchoolCalculatorPath = () => '/med-school-calculator';
export const termsAndConditionsPath = () => '/terms-and-conditions';
export const privacyPolicyPath = () => '/privacy-policy';

export const flashCardsPath = (slugOrId, returnPath) =>
  `/flash-cards/${getSlugOrId(slugOrId)}${returnPath ? `?returnPath=${returnPath}` : ''}`;
export const flashCardsEditPath = slugOrId => `/flash-cards/${getSlugOrId(slugOrId)}/edit`;

export const onlineCoursePath = ocOrId => `/online-courses/${ocOrId?.id || ocOrId}`;
export const onlineCoursePracticeModePath = ocOrId => `${onlineCoursePath(ocOrId)}/practice-mode`;
export const onlineCoursePracticeModeQuestionPath = (ocOrId, sectId, qId, courseSlug) => ({
  pathname: courseSlug ? studentCoursePagePath(courseSlug) : onlineCoursePath(ocOrId),
  search: queryString.stringify({ qid: qId, sect_id: sectId, ...(courseSlug && { practice_mode: true }) }),
});
export const onlineCourseActivityPath = (ocOrId, actType, actId, courseSlug) => ({
  pathname: courseSlug ? studentCoursePagePath(courseSlug) : onlineCoursePath(ocOrId),
  search: queryString.stringify({ activity_type: actType, activity_id: actId }),
});
export const onlineCourseSectionPath = (ocOrId, sectId, courseSlug) => ({
  pathname: courseSlug ? studentCoursePagePath(courseSlug) : onlineCoursePath(ocOrId),
  search: queryString.stringify({ sect_id: sectId }),
});
export const onlineCourseChapterPath = (ocOrId, chapId) => ({
  pathname: onlineCoursePath(ocOrId),
  search: queryString.stringify({ chap_id: chapId }),
});
export const onlineCourseQuestionPath = ({
  activity_id,
  activity_type,
  course_slug,
  online_course_id,
  online_course_type,
  pm_section_id,
  question_id,
  quiz_question_id,
  reply_id,
}) => {
  const courseSlug = course_slug !== 'mcat' && online_course_type === 'class_pass' && course_slug;
  return {
    pathname:
      courseSlug ? studentCoursePagePath(courseSlug)
      : pm_section_id ? onlineCoursePracticeModePath(online_course_id)
      : onlineCoursePath(online_course_id),
    search: queryString.stringify({
      ...(pm_section_id ?
        { sect_id: pm_section_id, qid: activity_id, ...(courseSlug && { practice_mode: true }) }
      : {
          activity_id,
          activity_type,
        }),
      replyId: reply_id || undefined,
      questionId: question_id || undefined,
      qqId: quiz_question_id || undefined,
    }),
  };
};

export const onDemandLandingPath = () => '/on-demand';
export const parentsLandingPath = () => '/for-parents';
export const partnerPath = partner => `/partners/${getSlugOrId(partner)}`;
export const partnershipsPath = () => '/partnerships';
export const subscriptionLandingPath = () => '/wizeprep-plus';
export const scholarshipsPath = () => '/scholarships';
export const scholarshipPath = scholarship => `/scholarships/${getSlugOrId(scholarship)}`;
export const pricingPath = () => '/pricing';

export const schoolPath = school => `/schools/${getSlugOrId(school)}`;
export const schoolsPath = () => searchSchoolsPath();
export const searchPagePath = () => '/search';

const searchTypeQueryPath = (type, query) => ({
  pathname: searchPagePath(),
  search: queryString.stringify({ type, query: query || undefined }),
});
export const searchCoursesPath = query => searchTypeQueryPath('course', query);
export const searchSchoolsPath = query => searchTypeQueryPath('school', query);
export const searchLibraryPath = (query, user) => ({
  pathname: searchPagePath(),
  search: queryString.stringify({
    type: 'topic',
    query: query || undefined,
    sid: user?.school_id,
    school_label: user?.school_name,
  }),
});

export const studentDashboardPath = () => '/dashboard';
export const studentMcatDashboardPath = () => `${studentDashboardPath()}?tab=mcat`;
export const classRepDashboardPath = () => `${studentDashboardPath()}?tab=class-rep`;
export const mcatStudySheetsPath = () => `${studentMcatDashboardPath()}&view_sheets=true`;
export const studentOnboardingPath = params => `/onboarding${params ? `?${queryString.stringify(params)}` : ''}`;
export const studentSignupPath = params => `/sign-up${params ? `?${queryString.stringify(params)}` : ''}`;
export const checkoutPath = params => `/checkout${params ? `?${queryString.stringify(params)}` : ''}`;

export const studyRoomDemoPath = () => '/study-rooms/demo';

export const teacherDashboardPath = () => '/teacher-dashboard';
export const teacherDashboardCourseMembersPath = course => `/teacher-dashboard/courses/${course.id}/students`;
export const teacherDashboardLessonPlannerPath = course => `/teacher-dashboard/courses/${course.id}/planner`;
export const teacherDashboardGradeBookPath = course => `/teacher-dashboard/courses/${course.id}/grade-book`;

export const tutoringDashboardPath = () => '/my-tutoring';
export const tutoringLandingPath = () => '/tutoring';
export const universityLandingPath = () => '/university';
export const tokensDashboardPath = () => '/tokens';
export const coursesPath = filters => ({
  pathname: '/courses',
  search: filters ? queryString.stringify(filters) : undefined,
});

export const essayWriterPath = () => '/wize-writer-dashboard';
export const essayWriterEssayPath = essay => `${essayWriterPath()}/essays/${essay.id}`;
export const essayWriterTemplatePath = template => `${essayWriterPath()}/templates/${template.id}`;
export const essayWriterLandingPagePath = () => '/wize-writer';

export function adminUserPath(userId) {
  return `/admin/users/${userId}`;
}

export function blogPath(blog) {
  return `/blog${blog ? `/${getSlugOrId(blog)}` : ''}`;
}

export function bloggerPagePath(author) {
  return `/blog${author ? '/@' + author.slug : ''}`;
}

export function contestPath() {
  return '/contest';
}

export const coursePath = course => `/courses/${getSlugOrId(course)}`;
export const courseBookletPath = course => `${coursePath(course)}/booklet`;
export const courseClassRepApplyPath = course => `${coursePath(course)}/class-rep-apply`;

export const onlineCourseCoursePath = oc => `/courses/${oc.course_slug || oc.course?.slug || oc.course_id}#on-demand`;

export const studentCoursePagePath = course => `/in-course-experience/${getSlugOrId(course)}`;
export const studentCoursePageResumeOnDemandPath = course => `${studentCoursePagePath(course)}?resume=true`;
export const studentCoursePageAssessmentPath = (course, assessment) =>
  `${studentCoursePagePath(course)}/${studentCoursePageAssessmentRelativePath(assessment)}`;

export const studentCoursePageAssessmentActivityPath = (course, assessment, activity) =>
  `${assessment ? studentCoursePageAssessmentPath(course, assessment) : `${studentCoursePagePath(course)}/${studentCoursePageOnDemandRelativePath()}`}${activity.fullPath}`;
export const studentCoursePageStudySessionPath = (course, studySession) =>
  `${studentCoursePagePath(course)}/${STUDY_SESSION_TYPES[studySession.type]?.slug}/${studySession.id}`;

export const studentCoursePageAssessmentRelativePath = assessment => `assessments/${getSlugOrId(assessment)}`;
export const studentCoursePageOnDemandRelativePath = () => 'on-demand';
export const studentCoursePageGradeBoostersRelativePath = () => 'grade-boosters';
export const studentCoursePageMockExamRelativePath = oc => `mocks/${getSlugOrId(oc)}`;
export const studentCoursePageFlashCardsRelativePath = deck => `flash-cards/${getSlugOrId(deck)}`;

export function homeworkHelpQuestionsPath() {
  return {
    pathname: studentDashboardPath(),
    search: queryString.stringify({ page: 'ask-a-question' }),
  };
}

export function instructorProfilePath(instructor) {
  return `/instructors/${getSlugOrId(instructor)}`;
}

export function onlineCourseEditPath(ocOrId, search = {}) {
  return {
    pathname: `/online-courses/${ocOrId?.id || ocOrId}/manager/editor`,
    search: queryString.stringify(search),
  };
}
export function onlineCourseManagerPath(ocOrId) {
  return `/online-courses/${ocOrId?.id || ocOrId}/manager`;
}

export function onlineCourseNewPath(defaultParams = {}) {
  return {
    to: '/online-courses/new',
    state: { defaultParams },
  };
}

export function onlineCourseResumePath(ocOrId) {
  return {
    pathname: onlineCoursePath(ocOrId),
    search: 'resume=true',
  };
}

export function onlineCoursePrintPath({ id }) {
  return `/online-courses/${id}/print`;
}

export function tutoringUserPath(user) {
  if (user.guest) return tutoringLandingPath();
  // partner member set up tutoring from home page
  if (hasAnyRoles(user, 'partner_member')) {
    return '/';
  }
  return tutoringDashboardPath();
}

export function tutorProfilePath(slugOrId) {
  return `/instructors/${slugOrId}`;
}

export function tutorSearchPath(subject) {
  return subject ? `/tutors?subject=${subject}` : '/tutors';
}

export function quizQuestionsManagerPath(params) {
  return { pathname: '/online-course-activities/questions', search: queryString.stringify(params) };
}
