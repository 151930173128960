import reactionTypes from '../constants/ResourceReactionActionTypes';
import { createReaction, deleteReaction } from './utils';

const initialState = {
  status: {},
  items: {},
};

export default function forumReplies(state = initialState, action) {
  switch (action.type) {
    case reactionTypes.CREATE_RESOURCE_REACTION_SUCCESS: {
      return createReaction('ForumReply', action, state);
    }
    case reactionTypes.DELETE_RESOURCE_REACTION_SUCCESS: {
      return deleteReaction('ForumReply', action, state);
    }
    default:
      return state;
  }
}
