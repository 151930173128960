import types from '../constants/TutorRequestActionTypes';
import { addNewItems, fetchingItems, fetchItemsFail, fetchItemsSuccess } from './utils';

const initialState = {
  status: { fetch: {} },
  items: {},
};

export default function tutorRequests(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_STUDENT_TUTOR_REQUESTS:
      return fetchingItems(state, action);
    case types.FETCH_STUDENT_TUTOR_REQUESTS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_STUDENT_TUTOR_REQUESTS_FAIL:
      return fetchItemsFail(state, action);

    case types.FETCH_TUTOR_TUTOR_REQUESTS:
      return fetchingItems(state, action);
    case types.FETCH_TUTOR_TUTOR_REQUESTS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case types.FETCH_TUTOR_TUTOR_REQUESTS_FAIL:
      return fetchItemsFail(state, action);

    case types.PURCHASE_TUTOR_REQUESTS:
      return {
        ...state,
        status: { ...state.status, purchase: { ...state.status.purchase, [action.updateKey]: { purchasing: true } } },
      };
    case types.PURCHASE_TUTOR_REQUESTS_SUCCESS:
      return {
        ...state,
        items: addNewItems(state, action.tutorRequests),
        status: {
          ...state.status,
          purchase: { ...state.status.purchase, [action.updateKey]: { success: true } },
        },
      };
    case types.PURCHASE_TUTOR_REQUESTS_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          purchase: { ...state.status.purchase, [action.updateKey]: { error: action.error } },
        },
      };

    default:
      return state;
  }
}
