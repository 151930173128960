import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Only start loading after a delay, since fallback is fully functional, and MorphSVG plugin adds to bundle size
const AsyncMorphFontAwesomeIcon = loadable(
  () =>
    new Promise(resolve => {
      setTimeout(() => import('./AsyncMorphFontAwesomeIcon').then(resolve).catch(() => resolve(FontAwesomeIcon)), 2000);
    }),
);

export default function MorphFontAwesomeIcon(props) {
  return <AsyncMorphFontAwesomeIcon {...props} fallback={<FontAwesomeIcon {...props} />} />;
}
