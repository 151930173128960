export const SITE_TOURS = {
  SITE_TOUR_HELP: {
    id: 'site_tour_help',
    steps: {
      final: {
        fixed: true,
        order: 999,
        placement: 'bottom',
        text: 'Anytime you want to view the site tour, just click this button.',
        title: 'View the Tour',
      },
    },
  },
  ONLINE_COURSE_OVERVIEW: {
    id: 'online_course_overview',
    steps: {
      // welcome: {
      //   order: 0,
      //   text: "Let's take a tour of the course overview page to show you how to get the most out of this course",
      //   title: 'Welcome to your Wizeprep course!'
      // },
      // booklet: {
      //   order: 1,
      //   placement: 'bottom-start',
      //   text: "Here you'll find the course booklet. You can print it and follow along or save it as a PDF for later.",
      //   title: 'Download the Booklet'
      // },
      // question: {
      //   order: 2,
      //   placement: 'bottom-start',
      //   text: 'Directly message the Wizeprep Prof if you need any help.',
      //   title: 'Have a Question?'
      // },
      // curriculum: {
      //   order: 2,
      //   offset: 100,
      //   placement: 'top-end',
      //   text:
      //     'Use this section to review the contents of the course. Start at the beginning or go directly to a specific activity.',
      //   title: 'Course Curriculum'
      // },
      // faves: {
      //   order: 3,
      //   offset: 80,
      //   placement: 'bottom-end',
      //   text: 'Click the bookmark icon to save items you want to go back to later.',
      //   title: 'Bookmarks 🔖'
      // },
      // progress: {
      //   order: 4,
      //   placement: 'bottom-end',
      //   text: "Keep track of how you're progressing in the course",
      //   title: 'Track Your Progress'
      // },
      // start: {
      //   order: 5,
      //   placement: 'bottom-start',
      //   text: 'Get on the path to better grades and begin studying here.',
      //   title: 'Start the Course'
      // }
    },
  },
  ONLINE_COURSE_EXPERIENCE: {
    id: 'online_course_experience',
    steps: {
      welcome: {
        order: 0,
        text: "Let's show you around.",
        title: 'Welcome to your course!',
      },
      menu: {
        order: 2,
        title: 'Curriculum',
        text: 'Easily navigate between chapters, sections, and activities to find the help you need.',
      },
      learnPracticeToggle: {
        order: 3,
        title: 'Learn Mode & Practice Mode',
        text: 'Toggle to quickly move from Practice Mode to Learn Mode.',
      },
      home: {
        order: 4,
        title: 'Course Overview',
        text: 'Return to the course overview page to access all of the available resources for your course.',
      },
      print: {
        order: 5,
        title: 'Print Notes',
        text: 'Follow along by printing the course notes or saving as a PDF.',
      },
      share: {
        order: 6,
        title: 'Share',
        text: 'Provide your friends with free access to specific lessons.',
      },
      layout: {
        order: 7,
        title: 'Orientation',
        text: 'Personalize your experience by adjusting the page layout.',
      },
      // faves: {
      //   order: 3,
      //   placement: 'bottom-end',
      //   text:
      //     'Something you want to save for later? Favorite it by clicking the star next to the activity. You can find all your favorites here.',
      //   title: 'Bookmarks 🔖'
      // },
      // toggleMenu: {
      //   order: 4,
      //   placement: 'bottom-start',
      //   text: 'Click the arrow to close the menu and focus on the activity.',
      //   title: 'Close Menu'
      // }
    },
  },
  ONLINE_COURSE_VIDEO: {
    id: 'online_course_video',
    steps: {
      autoplay: {
        order: 10,
        offset: 110,
        placement: 'top-start',
        text: 'Activate the autoplay toggle for the activities to auto-advance and the video to play automatically.',
        title: 'Autoplay',
      },
      settings: {
        order: 10,
        offset: 110,
        placement: 'top-start',
        text: 'Open the settings to moderate your viewing experience.',
        title: 'Settings',
      },
      speed: {
        order: 11,
        offset: 110,
        placement: 'top-end',
        text: 'Speed up or slow down the videos to learn at your own pace.',
        title: 'Adjust Playback Speed',
      },
      bookmarks: {
        order: 12,
        placement: 'top-end',
        text: 'Jump to marked points in the video.',
        title: 'Bookmarks',
      },
      questions: {
        order: 20,
        placement: 'top',
        text: 'Ask it here and your Wizeprep Prof will see it. This is the best place to ask your questions about the activity you are doing.',
        title: 'Have a question?',
      },
    },
  },
  STUDY_ROOM: {
    id: 'study_room',
    steps: {
      chat: {
        order: 0,
        placement: 'bottom-end',
        text: 'Choose how you interact with the other study room participants by utilizing video, text, and audio.',
        title: 'Chat',
      },
      snapshot: {
        order: 1,
        placement: 'bottom-end',
        text: 'Hold your homework up to the camera on your device and take a photo of it! The image will appear on the whiteboard. **Camera must be activated**',
        title: 'Snapshot',
      },
      tools: {
        order: 2,
        placement: 'right-start',
        text: 'Use the whiteboard tools to draw, create shapes, add text, erase, change the background, undo or redo',
        title: 'Whiteboard Tools',
      },
      tabs: {
        order: 3,
        placement: 'bottom',
        text: 'Add, rename, and remove your boards to stay organized.',
        title: 'Manage Tabs',
      },
      attachments: {
        order: 4,
        placement: 'bottom',
        text: 'Upload attachments that you would like to upload to the whiteboard',
        title: 'Attachments',
      },
      download: {
        order: 5,
        placement: 'bottom-start',
        text: 'All the work in this study room is auto-saved to your dashboard. However, if you would like to save the whiteboard as an image you can do so by clicking here.',
        title: 'Save your lesson',
      },
      final: {
        order: 99,
        placement: 'bottom',
        text: "You're ready to go! Should you need a refresher, just click the button here to see the tour again",
        title: 'End of Tour!',
      },
    },
  },
};

// export const STEPS = {
//   toc: {
//     fixed: true,
//     order: 0,
//     placement: 'right-start',
//     text: 'Quickly navigate to any chapter, section, or quiz.',
//     title: 'Table of Contents'
//   },
//   autoplay: {
//     fixed: true,
//     order: 1,
//     placement: 'bottom-end',
//     text: 'Switch on to automatically move through activities',
//     title: 'Autoplay'
//   },
//   studyMode: {
//     fixed: true,
//     order: 2,
//     placement: 'bottom',
//     text: 'Remove distractions by entering a full-screen course mode',
//     title: 'Study Mode'
//   },
//   qa: {
//     fixed: false,
//     offset: 110,
//     order: 3,
//     placement: 'top',
//     text: 'Have a question for the instructor? Ask it here.',
//     title: 'Q & A'
//   },
// };
