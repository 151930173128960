import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';

import { addSiteTourStep, removeSiteTourStep } from 'my-actions/SiteTourActions';

export default function useSiteTourStep({ disabled = false, step, tourId }) {
  const dispatch = useDispatch();

  const [elementId] = useState(() => `site-tour--${tourId}-${step.order}`);

  useEffect(() => {
    if (disabled) return;
    const stepId = uuidV4();
    dispatch(addSiteTourStep(tourId, { ...step, id: stepId, selector: elementId }));
    return () => {
      dispatch(removeSiteTourStep(tourId, stepId));
    };
  }, [disabled, dispatch, elementId, step, tourId]);

  return elementId;
}
