import { getSubscriptionStatus } from 'my-core/subscription-utils';

import {
  faAtomAlt,
  faBook,
  faBookOpen,
  faBriefcase,
  faCalculator,
  faChalkboardTeacher,
  faChartLine,
  faChartScatter,
  faCoins,
  faDna,
  faFlask,
  faGlasses,
  faHeadSideBrain,
  faKidneys,
  faLaptopCode,
  faLeaf,
  faLightbulbOn,
  faMegaphone,
  faMicroscope,
  faPuzzlePiece,
  faTools,
  faUsersClass,
  faUserTie,
} from '@fortawesome/pro-regular-svg-icons';

import mcatImg from 'images/artwork/banner_mcat.svg';
import onDemandImg from 'images/artwork/banner_on-demand.svg';
import uOfAImg from 'images/school-illustrations/alberta.svg';
import defaultSchoolImg from 'images/school-illustrations/any.svg';
import uOfCImg from 'images/school-illustrations/calgary.svg';
import concordiaImg from 'images/school-illustrations/concordia.svg';
import michiganImg from 'images/school-illustrations/michigan.svg';
import purdueImg from 'images/school-illustrations/purdue.svg';
import sanDiegoImg from 'images/school-illustrations/san-diego.svg';
import ubcImg from 'images/school-illustrations/ubc.svg';
import westernImg from 'images/school-illustrations/western.svg';
// SUBJECT BANNERS
import accountingBannerImg from 'images/subjects/banners/accounting.jpg';
import biochemBannerImg from 'images/subjects/banners/biochem.jpg';
import biologyBannerImg from 'images/subjects/banners/biology.jpg';
import businessBannerImg from 'images/subjects/banners/business.jpg';
import chemistryBannerImg from 'images/subjects/banners/chemistry.jpg';
import compsciBannerImg from 'images/subjects/banners/compsci.jpg';
import economicsBannerImg from 'images/subjects/banners/economics.jpg';
import engineeringBannerImg from 'images/subjects/banners/engineering.jpg';
import englishBannerImg from 'images/subjects/banners/english.jpg';
import financeBannerImg from 'images/subjects/banners/finance.jpg';
import generalBannerImg from 'images/subjects/banners/general.jpg';
import logicBannerImg from 'images/subjects/banners/logic.jpg';
import marketingBannerImg from 'images/subjects/banners/marketing.jpg';
import mathBannerImg from 'images/subjects/banners/mathematics.jpg';
import orgchemBannerImg from 'images/subjects/banners/orgchem.jpg';
import physicsBannerImg from 'images/subjects/banners/physics.jpg';
import physiologyBannerImg from 'images/subjects/banners/physiology.jpg';
import psychologyBannerImg from 'images/subjects/banners/psychology.jpg';
import readingBannerImg from 'images/subjects/banners/reading.jpg';
import reasoningBannerImg from 'images/subjects/banners/reasoning.jpg';
import statisticsBannerImg from 'images/subjects/banners/statistics.jpg';
// SUBJECT SMALL COVERS
import accountingCoverSmallImg from 'images/subjects/covers-small/accounting.jpg';
import biochemCoverSmallImg from 'images/subjects/covers-small/biochem.jpg';
import biologyCoverSmallImg from 'images/subjects/covers-small/biology.jpg';
import businessCoverSmallImg from 'images/subjects/covers-small/business.jpg';
import chemistryCoverSmallImg from 'images/subjects/covers-small/chemistry.jpg';
import compsciCoverSmallImg from 'images/subjects/covers-small/compsci.jpg';
import economicsCoverSmallImg from 'images/subjects/covers-small/economics.jpg';
import engineeringCoverSmallImg from 'images/subjects/covers-small/engineering.jpg';
import englishCoverSmallImg from 'images/subjects/covers-small/english.jpg';
import financeCoverSmallImg from 'images/subjects/covers-small/finance.jpg';
import generalCoverSmallImg from 'images/subjects/covers-small/general.jpg';
import logicCoverSmallImg from 'images/subjects/covers-small/logic.jpg';
import marketingCoverSmallImg from 'images/subjects/covers-small/marketing.jpg';
import mathCoverSmallImg from 'images/subjects/covers-small/mathematics.jpg';
import orgchemCoverSmallImg from 'images/subjects/covers-small/orgchem.jpg';
import physicsCoverSmallImg from 'images/subjects/covers-small/physics.jpg';
import physiologyCoverSmallImg from 'images/subjects/covers-small/physiology.jpg';
import psychologyCoverSmallImg from 'images/subjects/covers-small/psychology.jpg';
import readingCoverSmallImg from 'images/subjects/covers-small/reading.jpg';
import reasoningCoverSmallImg from 'images/subjects/covers-small/reasoning.jpg';
import statisticsCoverSmallImg from 'images/subjects/covers-small/statistics.jpg';
// SUBJECT COVERS
import accountingCoverImg from 'images/subjects/covers/accounting.jpg';
import biochemCoverImg from 'images/subjects/covers/biochem.jpg';
import biologyCoverImg from 'images/subjects/covers/biology.jpg';
import businessCoverImg from 'images/subjects/covers/business.jpg';
import chemistryCoverImg from 'images/subjects/covers/chemistry.jpg';
import compsciCoverImg from 'images/subjects/covers/compsci.jpg';
import economicsCoverImg from 'images/subjects/covers/economics.jpg';
import engineeringCoverImg from 'images/subjects/covers/engineering.jpg';
import englishCoverImg from 'images/subjects/covers/english.jpg';
import financeCoverImg from 'images/subjects/covers/finance.jpg';
import generalCoverImg from 'images/subjects/covers/general.jpg';
import logicCoverImg from 'images/subjects/covers/logic.jpg';
import marketingCoverImg from 'images/subjects/covers/marketing.jpg';
import mathCoverImg from 'images/subjects/covers/mathematics.jpg';
import orgchemCoverImg from 'images/subjects/covers/orgchem.jpg';
import physicsCoverImg from 'images/subjects/covers/physics.jpg';
import physiologyCoverImg from 'images/subjects/covers/physiology.jpg';
import psychologyCoverImg from 'images/subjects/covers/psychology.jpg';
import readingCoverImg from 'images/subjects/covers/reading.jpg';
import reasoningCoverImg from 'images/subjects/covers/reasoning.jpg';
import statisticsCoverImg from 'images/subjects/covers/statistics.jpg';
// SUBJECT ICONS
import accountingIcon from 'images/subjects/icons/accounting.svg';
import biochemIcon from 'images/subjects/icons/biochem.svg';
import biologyIcon from 'images/subjects/icons/biology.svg';
import businessIcon from 'images/subjects/icons/business.svg';
import chemistryIcon from 'images/subjects/icons/chemistry.svg';
import compsciIcon from 'images/subjects/icons/compsci.svg';
import economicsIcon from 'images/subjects/icons/economics.svg';
import engineeringIcon from 'images/subjects/icons/engineering.svg';
import englishIcon from 'images/subjects/icons/english.svg';
import financeIcon from 'images/subjects/icons/finance.svg';
import generalIcon from 'images/subjects/icons/general.svg';
import logicIcon from 'images/subjects/icons/logic.svg';
import marketingIcon from 'images/subjects/icons/marketing.svg';
import mathIcon from 'images/subjects/icons/mathematics.svg';
import orgchemIcon from 'images/subjects/icons/orgchem.svg';
import physicsIcon from 'images/subjects/icons/physics.svg';
import physiologyIcon from 'images/subjects/icons/physiology.svg';
import psychologyIcon from 'images/subjects/icons/psychology.svg';
import readingIcon from 'images/subjects/icons/reading.svg';
import reasoningIcon from 'images/subjects/icons/reasoning.svg';
import statisticsIcon from 'images/subjects/icons/statistics.svg';
import wizeIcon from 'images/subjects/icons/wize.svg';
// SUBJECT THUMBNAILS
import accountingThumb from 'images/subjects/thumbnails/accounting.jpg';
import biochemThumb from 'images/subjects/thumbnails/biochem.jpg';
import biologyThumb from 'images/subjects/thumbnails/biology.jpg';
import businessThumb from 'images/subjects/thumbnails/business.jpg';
import chemistryThumb from 'images/subjects/thumbnails/chemistry.jpg';
import compsciThumb from 'images/subjects/thumbnails/compsci.jpg';
import economicsThumb from 'images/subjects/thumbnails/economics.jpg';
import engineeringThumb from 'images/subjects/thumbnails/engineering.jpg';
import englishThumb from 'images/subjects/thumbnails/english.jpg';
import financeThumb from 'images/subjects/thumbnails/finance.jpg';
import generalThumb from 'images/subjects/thumbnails/general.jpg';
import logicThumb from 'images/subjects/thumbnails/logic.jpg';
import marketingThumb from 'images/subjects/thumbnails/marketing.jpg';
import mathThumb from 'images/subjects/thumbnails/mathematics.jpg';
import orgchemThumb from 'images/subjects/thumbnails/orgchem.jpg';
import physicsThumb from 'images/subjects/thumbnails/physics.jpg';
import physiologyThumb from 'images/subjects/thumbnails/physiology.jpg';
import psychologyThumb from 'images/subjects/thumbnails/psychology.jpg';
import readingThumb from 'images/subjects/thumbnails/reading.jpg';
import reasoningThumb from 'images/subjects/thumbnails/reasoning.jpg';
import statisticsThumb from 'images/subjects/thumbnails/statistics.jpg';

const SUBJECT_GROUPINGS = {
  business: { key: 'business', name: 'Business' },
  general: { key: 'general', name: 'General' },
  humanities: { key: 'humanities', name: 'Humanities' },
  lsat: { key: 'lsat', name: 'LSAT' },
  mathematics: { key: 'mathematics', name: 'Math' },
  science: { key: 'science', name: 'Science' },
  social_science: { key: 'social_science', name: 'Social Science' },
  technology: { key: 'technology', name: 'Technology' },
};

// should be kept in sync with backend constants.rb
export const SUBJECT_CONFIG = {
  accounting: {
    key: 'accounting',
    name: 'Accounting',
    subject_grouping: SUBJECT_GROUPINGS.business,
    color: 'green',
    stockImg: {
      banner: accountingBannerImg,
      thumb: accountingThumb,
      cover: accountingCoverImg,
      coverSmall: accountingCoverSmallImg,
    },
    codes: ['acco', 'acc', 'acc', 'actg', 'acct', 'accounting'],
    icon: accountingIcon,
    faIcon: faBriefcase,
  },
  biology: {
    key: 'biology',
    name: 'Biology',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'orange',
    stockImg: {
      banner: biologyBannerImg,
      thumb: biologyThumb,
      cover: biologyCoverImg,
      coverSmall: biologyCoverSmallImg,
    },
    codes: ['biology', 'bio', 'biol', 'blg', 'mbio', 'bs', 'bsc', 'bisc', 'hmb', 'biophys'],
    icon: biologyIcon,
    faIcon: faDna,
  },
  biochem: {
    key: 'biochem',
    name: 'Biochemistry',
    short_name: 'Biochem',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'secondary',
    stockImg: {
      banner: biochemBannerImg,
      thumb: biochemThumb,
      cover: biochemCoverImg,
      coverSmall: biochemCoverSmallImg,
    },
    codes: ['bioch', 'bch', 'bcem', 'biochem'],
    icon: biochemIcon,
    faIcon: faMicroscope,
  },
  business: {
    key: 'business',
    name: 'Business',
    subject_grouping: SUBJECT_GROUPINGS.business,
    color: 'green',
    codes: ['bus', 'busa'],
    stockImg: {
      banner: businessBannerImg,
      thumb: businessThumb,
      cover: businessCoverImg,
      coverSmall: businessCoverSmallImg,
    },
    icon: businessIcon,
    faIcon: faUserTie,
  },
  cars: {
    key: 'cars',
    name: 'CARS',
    subject_grouping: SUBJECT_GROUPINGS.lsat,
    color: 'yellow',
    codes: [],
    stockImg: {
      banner: reasoningBannerImg,
      thumb: reasoningThumb,
      cover: reasoningCoverImg,
      coverSmall: reasoningCoverSmallImg,
    },
    icon: reasoningIcon,
    faIcon: faLightbulbOn,
  },
  chemistry: {
    key: 'chemistry',
    name: 'Chemistry',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'cyan',
    stockImg: {
      banner: chemistryBannerImg,
      thumb: chemistryThumb,
      cover: chemistryCoverImg,
      coverSmall: chemistryCoverSmallImg,
    },
    codes: ['chem', 'phar', 'cem', 'che', 'chm', 'chy', 'aps'],
    icon: chemistryIcon,
    faIcon: faFlask,
  },
  orgchem: {
    key: 'orgchem',
    name: 'Organic Chemistry',
    short_name: 'Org Chem',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'primary',
    stockImg: {
      banner: orgchemBannerImg,
      thumb: orgchemThumb,
      cover: orgchemCoverImg,
      coverSmall: orgchemCoverSmallImg,
    },
    codes: [],
    icon: orgchemIcon,
    faIcon: faLeaf,
  },
  compsci: {
    key: 'compsci',
    name: 'Computer Science',
    short_name: 'Comp Sci',
    subject_grouping: SUBJECT_GROUPINGS.technology,
    color: 'orange',
    stockImg: {
      banner: compsciBannerImg,
      thumb: compsciThumb,
      cover: compsciCoverImg,
      coverSmall: compsciCoverSmallImg,
    },
    codes: ['comp', 'cis', 'cmpt', 'comp', 'compsci', 'cps', 'encmp'],
    icon: compsciIcon,
    faIcon: faLaptopCode,
  },
  economics: {
    key: 'economics',
    name: 'Economics',
    short_name: 'Econ',
    subject_grouping: SUBJECT_GROUPINGS.social_science,
    color: 'skyblue',
    stockImg: {
      banner: economicsBannerImg,
      thumb: economicsThumb,
      cover: economicsCoverImg,
      coverSmall: economicsCoverSmallImg,
    },
    codes: ['comm', 'mgcr', 'adms', 'btm', 'itm', 'sten', 'econ', 'eco', 'ecn', 'ec', 'rsm', 'aff'],
    icon: economicsIcon,
    faIcon: faChartLine,
  },
  engineering: {
    key: 'engineering',
    name: 'Engineering',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'cyan',
    stockImg: {
      banner: engineeringBannerImg,
      thumb: engineeringThumb,
      cover: engineeringCoverImg,
      coverSmall: engineeringCoverSmallImg,
    },
    codes: [
      'apsc',
      'bcee',
      'bldg',
      'civi',
      'ece',
      'ecor',
      'eecs',
      'elec',
      'eng',
      'engi',
      'engg',
      'engineer',
      'ensc',
      'ge',
      'gene',
      'geng',
      'mie',
      'engr',
      'engm',
      'mech',
      'gng',
      'civ',
      'engsci',
    ],
    icon: engineeringIcon,
    faIcon: faTools,
  },
  english: {
    key: 'english',
    name: 'English',
    subject_grouping: SUBJECT_GROUPINGS.humanities,
    color: 'yellow',
    codes: [],
    stockImg: {
      banner: englishBannerImg,
      thumb: englishThumb,
      cover: englishCoverImg,
      coverSmall: englishCoverSmallImg,
    },
    icon: englishIcon,
    faIcon: faGlasses,
  },
  finance: {
    key: 'finance',
    name: 'Finance',
    subject_grouping: SUBJECT_GROUPINGS.business,
    color: 'cyan',
    codes: ['fine', 'fin', 'fina', 'finance'],
    stockImg: {
      banner: financeBannerImg,
      thumb: financeThumb,
      cover: financeCoverImg,
      coverSmall: financeCoverSmallImg,
    },
    icon: financeIcon,
    faIcon: faCoins,
  },
  logic: {
    key: 'logic',
    name: 'Logic Games',
    short_name: 'Logic',
    subject_grouping: SUBJECT_GROUPINGS.lsat,
    color: 'skyblue',
    stockImg: { banner: logicBannerImg, thumb: logicThumb, cover: logicCoverImg, coverSmall: logicCoverSmallImg },
    codes: [],
    icon: logicIcon,
    faIcon: faPuzzlePiece,
  },
  marketing: {
    key: 'marketing',
    name: 'Marketing',
    subject: SUBJECT_GROUPINGS.business,
    color: 'secondary',
    codes: ['mkt', 'mktg'],
    stockImg: {
      banner: marketingBannerImg,
      thumb: marketingThumb,
      cover: marketingCoverImg,
      coverSmall: marketingCoverSmallImg,
    },
    icon: marketingIcon,
    faIcon: faMegaphone,
  },
  mathematics: {
    key: 'mathematics',
    name: 'Mathematics',
    short_name: 'Math',
    subject_grouping: SUBJECT_GROUPINGS.mathematics,
    color: 'primary',
    stockImg: { banner: mathBannerImg, thumb: mathThumb, cover: mathCoverImg, coverSmall: mathCoverSmallImg },
    codes: ['calc', 'math', 'mth', 'mat', 'mac', 'amath', 'qms', 'mac', 'mthe'],
    icon: mathIcon,
    faIcon: faCalculator,
  },
  physics: {
    key: 'physics',
    name: 'Physics',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'purple', //'cyan',
    stockImg: {
      banner: physicsBannerImg,
      thumb: physicsThumb,
      cover: physicsCoverImg,
      coverSmall: physicsCoverSmallImg,
    },
    codes: ['physics', 'phys', 'phy', 'pcs', 'phyc', 'astr'],
    icon: physicsIcon,
    faIcon: faAtomAlt,
  },
  physiology: {
    key: 'physiology',
    name: 'Physiology',
    subject_grouping: SUBJECT_GROUPINGS.science,
    color: 'purple',
    stockImg: {
      banner: physiologyBannerImg,
      thumb: physiologyThumb,
      cover: physiologyCoverImg,
      coverSmall: physiologyCoverSmallImg,
    },
    codes: ['phgy', 'psl', 'physiol'],
    icon: physiologyIcon,
    faIcon: faKidneys,
  },
  psychology: {
    key: 'psychology',
    name: 'Psychology',
    short_name: 'Psych',
    subject_grouping: SUBJECT_GROUPINGS.social_science,
    color: 'green', // 'primary'
    codes: ['psyc', 'psy', 'psych', 'psyo', 'psyco', 'cpsy'],
    stockImg: {
      banner: psychologyBannerImg,
      thumb: psychologyThumb,
      cover: psychologyCoverImg,
      coverSmall: psychologyCoverSmallImg,
    },
    icon: psychologyIcon,
    faIcon: faHeadSideBrain,
  },
  reading: {
    key: 'reading',
    name: 'Reading Comprehension',
    short_name: 'Reading',
    subject_grouping: SUBJECT_GROUPINGS.lsat,
    color: 'cyan',
    codes: ['reading'],
    stockImg: {
      banner: readingBannerImg,
      thumb: readingThumb,
      cover: readingCoverImg,
      coverSmall: readingCoverSmallImg,
    },
    icon: readingIcon,
    faIcon: faBookOpen,
  },
  reasoning: {
    key: 'reasoning',
    name: 'Logical Reasoning',
    short_name: 'Reasoning',
    subject_grouping: SUBJECT_GROUPINGS.lsat,
    color: 'yellow',
    codes: [],
    stockImg: {
      banner: reasoningBannerImg,
      thumb: reasoningThumb,
      cover: reasoningCoverImg,
      coverSmall: reasoningCoverSmallImg,
    },
    icon: reasoningIcon,
    faIcon: faLightbulbOn,
  },
  statistics: {
    key: 'statistics',
    name: 'Statistics',
    short_name: 'Stats',
    subject_grouping: SUBJECT_GROUPINGS.mathematics,
    color: 'secondary',
    stockImg: {
      banner: statisticsBannerImg,
      thumb: statisticsThumb,
      cover: statisticsCoverImg,
      coverSmall: statisticsCoverSmallImg,
    },
    codes: ['stats', 'stat', 'sta', 'stt', 'sosc'],
    icon: statisticsIcon,
    faIcon: faChartScatter,
  },
  wize: {
    key: 'wize',
    name: 'Wizeprep',
    subject_grouping: SUBJECT_GROUPINGS.general,
    color: 'primary',
    stockImg: {
      banner: generalBannerImg,
      thumb: generalThumb,
      cover: generalCoverImg,
      coverSmall: generalCoverSmallImg,
    },
    codes: ['prof'],
    icon: wizeIcon,
  },
};

export const FALLBACK_SUBJECT = {
  key: 'default',
  name: 'Other',
  subject_grouping: SUBJECT_GROUPINGS.general,
  color: 'primary',
  stockImg: { banner: generalBannerImg, thumb: generalThumb, cover: generalCoverImg, coverSmall: generalCoverSmallImg },
  icon: generalIcon,
};

let lookupCodeMap = null;
function buildLookupCodeMap() {
  return Object.values(SUBJECT_CONFIG).reduce((mem, subjConfig) => {
    subjConfig.codes.forEach(c => (mem[c] = subjConfig));
    return mem;
  }, {});
}

export function getCourseConfig(courseOrOnlineCourse) {
  if (!courseOrOnlineCourse) return FALLBACK_SUBJECT;
  const subjConfig =
    SUBJECT_CONFIG[(courseOrOnlineCourse.subject || courseOrOnlineCourse.course_subject)?.toLowerCase()];
  if (subjConfig) return subjConfig;
  const subjectCode = (courseOrOnlineCourse.code || courseOrOnlineCourse.course_code)
    ?.match(/^[a-z]+/i)?.[0]
    ?.toLowerCase();
  lookupCodeMap ||= buildLookupCodeMap();
  const scConfig = subjectCode && lookupCodeMap[subjectCode];
  if (scConfig) return scConfig;
  return FALLBACK_SUBJECT;
}

export function getSubjectConfig(subject) {
  return SUBJECT_CONFIG[subject] || FALLBACK_SUBJECT;
}

export const LSAC_PREP_PLUS_PRICE = 99;
export function isLsatCourse(course) {
  return course?.slug === 'lsat';
}
export const MCAT_SLUG = 'mcat';
export function isMcatCourse(course) {
  return course?.slug === MCAT_SLUG;
}
export function getMcatEnrollmentStatus(userCourse) {
  if (userCourse?.course_schedule_id) {
    if (userCourse.status === 'active') return 'elite_515_active';
    if (userCourse.mcat_elite_515_info?.payment_status === 'deposit_paid') return 'elite_515_deposit';
    return 'elite_515_headstart';
  }
  if (userCourse?.status === 'active') return 'self_paced';
  return 'trialing';
}

export function isPaidCourse(course) {
  return !!(course?.price || course?.stripe_product_id);
}

export function isCoursePurchased(userCourse, courseSubscription) {
  return (
    !!(userCourse && (userCourse.status === 'active' || userCourse.status === 'pending_payment')) ||
    getSubscriptionStatus(courseSubscription) === 'active'
  );
}

function getGradeLevels(isStudent) {
  return [
    { key: 'pre_k', label: 'Pre K', school_type: 'k_to_8' },
    { key: 'kinder', label: 'Kindergarten', school_type: 'k_to_8' },
    { key: 'grade_1', label: 'Grade 1', school_type: 'k_to_8' },
    { key: 'grade_2', label: 'Grade 2', school_type: 'k_to_8' },
    { key: 'grade_3', label: 'Grade 3', school_type: 'k_to_8' },
    { key: 'grade_4', label: 'Grade 4', school_type: 'k_to_8' },
    { key: 'grade_5', label: 'Grade 5', school_type: 'k_to_8' },
    { key: 'grade_6', label: 'Grade 6', school_type: 'k_to_8' },
    { key: 'grade_7', label: 'Grade 7', school_type: 'k_to_8' },
    { key: 'grade_8', label: 'Grade 8', school_type: 'k_to_8' },
    { key: 'grade_9', label: '9th Grade', school_type: 'high_school', ca_label: 'Grade 9' },
    { key: 'grade_10', label: '10th Grade', school_type: 'high_school', ca_label: 'Grade 10' },
    { key: 'grade_11', label: '11th Grade', school_type: 'high_school', ca_label: 'Grade 11' },
    { key: 'grade_12', label: '12th Grade', school_type: 'high_school', ca_label: 'Grade 12' },
    !isStudent && { key: 'ap', label: 'AP', school_type: 'high_school' },
    // { key: 'grade_13', label: 'Grade 13', school_type: 'high_school' },
    { key: 'undergrad_1', label: 'Freshman', school_type: 'undergrad', ca_label: '1st Year' },
    { key: 'undergrad_2', label: 'Sophomore', school_type: 'undergrad', ca_label: '2nd Year' },
    { key: 'undergrad_3', label: 'Junior', school_type: 'undergrad', ca_label: '3rd Year' },
    { key: 'undergrad_4', label: 'Senior', school_type: 'undergrad', ca_label: '4th Year' },
    { key: 'undergrad_other', label: 'Other', school_type: 'undergrad' },
    // { key: 'undergrad_5', label: 'Fifth Year', school_type: 'undergrad' },
    // { key: 'undergrad_6', label: 'Sixth Year', school_type: 'undergrad' }
  ]
    .filter(Boolean)
    .map((k, idx) => ({ ...k, order: idx }));
}

export const STUDENT_GRADE_LEVEL_CONFIG = getGradeLevels(true);

export const STUDENT_GRADE_LEVEL_CONFIG_MAP = STUDENT_GRADE_LEVEL_CONFIG.reduce(
  (mem, cfg) => ((mem[cfg.key] = cfg), mem),
  {},
);

export const COURSE_GRADE_LEVEL_CONFIG = getGradeLevels(false);
export const COURSE_GRADE_LEVEL_CONFIG_MAP = COURSE_GRADE_LEVEL_CONFIG.reduce(
  (mem, cfg) => ((mem[cfg.key] = cfg), mem),
  {},
);

export const COURSE_PRODUCTS_CONFIG = {
  class_pass: {
    tag: 'Smart Prep',
    description: 'Exam Prep at your Pace',
    icon: faLightbulbOn,
    color: 'primary',
  },
  study_sessions: {
    tag: 'Live Exam Prep',
    description: 'Exam Bootcamp',
    icon: faUsersClass,
    color: 'secondary',
  },
  tutors: {
    tag: 'Tutoring',
    description: 'One-on-one Help',
    icon: faChalkboardTeacher,
    color: 'orange',
  },
  practice_mode: {
    tag: 'Practice',
    description: '100+ extra practice problems with solutions',
    icon: faBook,
    color: 'green',
  },
};

export const USER_COURSE_STATUSES = {
  trialing: { label: 'Trialing' },
  active: { label: 'Active' },
  pending_payment: { label: 'Action Required' },
  refunded: { label: 'Refunded' },
  expired: { label: 'Expired' },
  invited: { label: 'Invited' },
  invite_rejected: { label: 'Invite Rejected' },
};

export const CUSTOM_BANNER_CONFIG = {
  concordia: {
    img: concordiaImg,
    imgAlt: 'Concordia school image',
    color: 'secondary',
    width: 556,
    height: 400,
  },
  michigan: {
    img: michiganImg,
    imgAlt: 'Michigan school image',
    color: 'primary',
    width: 556,
    height: 400,
  },
  'purdue-university': {
    img: purdueImg,
    imgAlt: 'Purdue school image',
    color: 'yellow',
    width: 556,
    height: 400,
  },
  'san-diego-state': {
    img: sanDiegoImg,
    imgAlt: 'San Diego school image',
    color: 'secondary',
    width: 556,
    height: 400,
  },
  'university-of-alberta': {
    img: uOfAImg,
    imgAlt: 'University of Alberta school image',
    color: 'green',
    width: 556,
    height: 400,
  },
  'u-of-c': {
    img: uOfCImg,
    imgAlt: 'University of Calgary school image',
    color: 'secondary',
    width: 556,
    height: 400,
  },
  ubc: { img: ubcImg, imgAlt: 'UBC school image', color: 'primary', width: 556, height: 400 },
  uwo: {
    img: westernImg,
    imgAlt: 'Western University school image',
    color: 'purple',
    width: 556,
    height: 400,
  },
  mcat: { img: mcatImg, imgAlt: 'MCAT image', width: 284, height: 150 },
  default_course: { img: onDemandImg, imgAlt: 'Course image', width: 220, height: 150 },
  default_school: { img: defaultSchoolImg, imgAlt: 'School image', color: 'primary', width: 556, height: 400 },
};
