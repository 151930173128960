import memoize from 'memoize-one';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import warning from 'warning';

import * as messageActions from 'my-actions/MessageActions';

export function useStandardApiRequest({
  actionCreator,
  errorMessage,
  onError,
  onSuccess,
  requestKey: requestKeyArg,
  successMessage,
}) {
  const dispatch = useDispatch();
  const activeRequestRef = useRef();
  const [requestKeyState, setRequestKey] = useState();
  const requestKey = requestKeyArg || requestKeyState;
  const requestStatus = useSelector(state => requestKey && state.apiRequests[requestKey]);
  // const requestRef = useRef();
  // const requestStatus = useSelector(state => state.apiRequests[requestRef.current?.requestKey]);
  const performRequest = useCallback(
    (...args) => {
      // tried to do this with useRef instead of useState, but the useSelector function
      // gets called with the internal 'FETCHING' action dispatch, before this dispatch
      // function has returned, so the requestKey is not yet set
      // requestRef.current = dispatch(actionCreator(...args));
      const { request, requestKey } = dispatch(actionCreator(...args));
      if (!requestKeyArg) setRequestKey(requestKey);
      activeRequestRef.current = request;
      return request; // experimental.. used in OnlineCourseQuesitonReplyFormCtnr for gtm-event call
    },
    [dispatch, actionCreator, requestKeyArg],
  );

  useEffect(() => {
    // TODO: what if requestKeyArg (and hence requestStatus) changes while a request is in progress?..
    if (activeRequestRef.current && requestStatus && !requestStatus.fetching) {
      activeRequestRef.current = null;
      if (requestStatus?.success) {
        if (successMessage) {
          dispatch(
            messageActions.addMessage({
              content: typeof successMessage === 'function' ? successMessage(requestStatus) : successMessage,
              type: 'success',
            }),
          );
        }
        onSuccess?.(requestStatus);
      } else if (requestStatus?.error) {
        if (errorMessage) {
          dispatch(messageActions.addErrorMessage(errorMessage === true ? requestStatus.error : errorMessage));
        }
        onError?.(requestStatus.error);
      }
    }
  }, [requestStatus, dispatch, errorMessage, successMessage, onSuccess, onError]);

  return { performRequest, requestStatus };
}

// Continuous List Fetching
export function useStandardApiListRequest({
  actionCreator,
  cacheGroupKey,
  disabled,
  limit,
  params,
  parentRequestKey,
  storeKey,
}) {
  warning(disabled || parentRequestKey, 'Must provide `parentRequestKey` argument to useStandardApiListRequest');
  const parentRequestStatus = useSelector(state => state.apiRequests[parentRequestKey]);

  const itemsSelector = useMemo(itemsSelectorFactory, []);

  const results = useSelector(state => itemsSelector(parentRequestStatus, storeKey && state[storeKey]?.items));
  const nextPage = parentRequestStatus?.requests ? Object.keys(parentRequestStatus.requests).length : 0;
  const requestStatus = parentRequestStatus?.requests?.[nextPage - 1];

  const isFetching = !!requestStatus?.fetching;
  const totalResults = parentRequestStatus?.total;
  const hasMoreResults = nextPage * limit < totalResults;
  const canFetchMore = hasMoreResults && !isFetching;

  const dispatch = useDispatch();
  const performFetch = useCallback(() => {
    // TODO: https://jsonapi.org/format/#fetching-pagination
    // page: { number: X, size: Y }
    dispatch(actionCreator({ ...params, page: nextPage, limit }, parentRequestKey, nextPage, cacheGroupKey));
  }, [actionCreator, params, nextPage, limit, dispatch, parentRequestKey, cacheGroupKey]);

  useEffect(() => {
    if (!(disabled || requestStatus || nextPage)) {
      performFetch();
    }
  }, [disabled, nextPage, performFetch, requestStatus]);

  return {
    fetchMoreResults: canFetchMore ? performFetch : undefined,
    fetching: !!((!disabled && !parentRequestStatus) || parentRequestStatus?.fetching),
    parentRequestStatus,
    requestStatus,
    results,
    total: totalResults,
  };
}

const emptySet = [];
function itemsSelectorFactory() {
  return memoize((parentRequestStatus, items) => {
    const results = Object.keys(parentRequestStatus?.requests || {})
      .map(p => parseInt(p)) // don't just pass in parseInt func because it takes multiple args
      .sort((a, b) => a - b) // default sort() converts to strings for sorting
      .flatMap(reqKey => {
        const ids = parentRequestStatus?.requests[reqKey]?.ids;
        return items ? ids?.map(id => items[id]) : ids;
      })
      .filter(Boolean);
    return results.length ? results : emptySet;
  });
}
