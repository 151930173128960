import memoize from 'memoize-one';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'sort-by';

import { WIZE_SUBSCRIPTION_NAME } from 'my-core/constants';
import { addDate } from 'my-core/date-utils';
import { subscriptionStartTrialButtonClicked } from 'my-core/gtm-events';
import { useStandardApiRequest } from 'my-core/hooks';
import { numberToCurrency } from 'my-utils';

import { fetchSubscription, startSubscriptionTrial } from 'my-actions/SubscriptionActions';

export const SUBSCRIPTION_YEARLY_PRICE = 179.4;
export const SUBSCRIPTION_MONTHLY_PRICE = 29.95;

export const PREMED_PLUS_YEARLY_PRICE = 299.4;
export const PREMED_PLUS_MONTHLY_PRICE = 49.95;

export const YEARLY_DISCOUNT_PERCENT =
  (1 -
    Math.min(
      SUBSCRIPTION_YEARLY_PRICE / (SUBSCRIPTION_MONTHLY_PRICE * 12),
      PREMED_PLUS_YEARLY_PRICE / (PREMED_PLUS_MONTHLY_PRICE * 12),
    )) *
  100;

export const SUBSCRIPTION_PRICE = Math.min(
  SUBSCRIPTION_MONTHLY_PRICE,
  Math.round((SUBSCRIPTION_YEARLY_PRICE * 100) / 12) / 100,
);
export const TRIAL_DURATION_DAYS = 7; // 7 days
// export const SUBSCRIPTION_QUESTION_QUOTA = 10;

export const SUBSCRIPTION_PRODUCT_DISCOUNT_PERCENT = 20;

export const PROMO_POINTS = [
  'Bite-sized video lessons',
  'Exam-like practice problems',
  'Expert tutor Q&A',
  `${SUBSCRIPTION_PRODUCT_DISCOUNT_PERCENT}% off all Grade Boosters`,
];

export const SUBSCRIPTION_PLANS_CONFIG = {
  monthly: {
    key: 'monthly',
    label: 'Monthly Subscription',
    per_desc: 'month',
    pricing: {
      amount: SUBSCRIPTION_MONTHLY_PRICE,
      amountPerMonth: SUBSCRIPTION_MONTHLY_PRICE,
    },
  },
  yearly: {
    key: 'yearly',
    label: 'Annual Subscription',
    per_desc: `month (${numberToCurrency(SUBSCRIPTION_YEARLY_PRICE)}/year)`,
    savingsPercent: Math.round((1 - SUBSCRIPTION_YEARLY_PRICE / 12 / SUBSCRIPTION_MONTHLY_PRICE) * 100),
    tag: `${Math.round(
      (1 - SUBSCRIPTION_YEARLY_PRICE / (SUBSCRIPTION_MONTHLY_PRICE * 12)) * 100,
    )}% cheaper than monthly`,
    pricing: {
      amount: SUBSCRIPTION_YEARLY_PRICE,
      amountPerMonth: SUBSCRIPTION_YEARLY_PRICE / 12,
    },
  },
};

export const ALL_PLANS = ['monthly', 'yearly'];
export const DEFAULT_PLAN = 'yearly';

export const SUBSCRIPTION_PRODUCT_TYPES_CONFIG = {
  trial: {
    label: 'Free',
    subtitle: 'Try before you buy',
    color: 'primary',
    pricing: {
      monthly: 0,
      yearly: 0,
    },
    perks: ['Student dashboard', 'Course booklet'],
  },
  wizeprep_plus: {
    label: 'Wizeprep Plus',
    subtitle: 'Most popular',
    color: 'primary',
    pricing: {
      monthly: SUBSCRIPTION_MONTHLY_PRICE,
      yearly: SUBSCRIPTION_YEARLY_PRICE,
    },
    overline: 'Most Popular',
    perks: [
      'Everything included in Free',
      'Bite-sized video lessons',
      'Exam-like practice problems',
      'Expert tutor Q&A',
      '20% off Grade Boosters',
    ],
  },
  premed_plus: {
    label: 'Pre-Med Plus',
    subtitle: 'Best for pre-med students',
    color: 'purple',
    pricing: {
      monthly: PREMED_PLUS_MONTHLY_PRICE,
      yearly: PREMED_PLUS_YEARLY_PRICE,
    },
    overline: 'For Aspiring Doctors',
    perks: [
      `Everything in ${WIZE_SUBSCRIPTION_NAME}`,
      'Exclusive MCAT Pre-requisite Courses',
      'Live monthly CARS workshop ',
      'CARS practice passage of the week',
      'Comprehensive MCAT Flashcard decks',
      'Monthly 30 minute pre-med coaching (valued at $100)',
      'Exclusive AMA events ',
    ],
  },
};

export const ALL_PRODUCTS = ['trial', 'wizeprep_plus', 'premed_plus'];
export const PAID_PRODUCTS = ['wizeprep_plus', 'premed_plus'];
export const DEFAULT_PRODUCT = 'wizeprep_plus';

export function useStartSubscriptionTrial(user) {
  const { performRequest, requestStatus } = useStandardApiRequest({
    actionCreator: startSubscriptionTrial,
    successMessage: `Thanks for starting your free trial of ${WIZE_SUBSCRIPTION_NAME}!`,
    errorMessage: true,
  });

  const startTrial = useCallback(
    trackingProps => {
      subscriptionStartTrialButtonClicked(
        user.country_code,
        trackingProps?.nativeEvent instanceof window.Event ? undefined : trackingProps,
      );
      performRequest();
    },
    [performRequest, user.country_code],
  );

  return { startTrial, requestStatus };
}

export function useFetchSubscription(productId) {
  const subscriptions = useCurrentUserSubscriptions();
  const matchId = productId === true ? null : productId;
  // eslint-disable-next-line eqeqeq -- stripe_product_id (null) == undefined productId
  const subscription = productId && subscriptions.find(s => s.stripe_product_id == matchId);
  const fetchKey = `fetch_subscription_${subscription?.id}`;
  const { performRequest: performFetchSubscription, requestStatus: fetchStatus } = useStandardApiRequest({
    actionCreator: fetchSubscription,
    requestKey: fetchKey,
  });

  useEffect(() => {
    if (
      (subscription?.stripe_subscription_id || subscription?.payment_intent_id) &&
      !fetchStatus &&
      !subscription.payment_method
    ) {
      performFetchSubscription(
        subscription?.id,
        {
          fields: {
            subscription: [
              'payment_method',
              'payment_intent',
              'discount_code',
              'next_invoice_at',
              'next_invoice_details',
              'cancel_discount_enabled',
              'discount_applied',
            ],
          },
          include: ['discount_code'],
        },
        fetchKey,
      );
    }
  }, [fetchStatus, fetchKey, performFetchSubscription, subscription]);

  return [subscription, fetchStatus];
}

export function useCurrentUserSubscription() {
  return useSelector(state =>
    getDefaultSubscription(state.users.status.current_user, state.subscriptions.items, state.discountCodes.items),
  );
}

export function useCurrentUserProductSubscription(productId) {
  const subs = useCurrentUserSubscriptions();
  return productId && subs.find(s => s.stripe_product_id === productId);
}

function useCurrentUserSubscriptions() {
  return useSelector(state =>
    getSubscriptions(state.users.status.current_user, state.subscriptions.items, state.discountCodes.items),
  );
}
// SELECTORS
const getDefaultSubscription = memoize((userId, subscriptions, discountCodes) => {
  const subs = getSubscriptions(userId, subscriptions, discountCodes);
  return subs.find(s => !s.stripe_product_id);
});

const getSubscriptions = memoize((userId, subscriptions, discountCodes) => {
  return Object.values(subscriptions)
    .filter(s => s.user_id === userId)
    .map(s => ({ ...s, discount_code: discountCodes[s.discount_code_id] }))
    .sort(sortBy('-created_at'));
});

// SUBSCRIPTION STATUS

export function getSubscriptionStatus(subscription) {
  if (!subscription) return;
  const curDate = new Date();
  if (subscription.expires_at && new Date(subscription.expires_at) < curDate) return 'expired';
  switch (subscription.status) {
    case 'trialing':
      if (new Date(subscription.trial_end_date) > curDate) return 'trialing';
      return 'incomplete';
    case 'active': {
      if (subscription.cancel_at && new Date(subscription.cancel_at) < curDate) return 'canceled';
      if (subscription.pause_at && new Date(subscription.pause_at) < curDate && getPauseEndDate(subscription) > curDate)
        return 'paused';
      return 'active';
    }
    default:
      return subscription.status;
  }
}

export function getIsActiveOrTrialing(subscription) {
  return ['active', 'trialing'].includes(getSubscriptionStatus(subscription));
}

export function getIsInactive(subscription) {
  return ['canceled', 'incomplete_expired'].includes(subscription?.status);
}
export function getHasPaymentIssue(subscription) {
  return subscription?.status === 'past_due';
}

export function trialSecondsRemaining(subscription) {
  if (subscription?.trial_end_date && subscription.status === 'trialing') {
    return Math.max(0, new Date(subscription.trial_end_date) - new Date()) / 1000;
  }
}

export function getSubscriptionProductDiscountPercent(subscription) {
  if (getIsActiveOrTrialing(subscription)) return SUBSCRIPTION_PRODUCT_DISCOUNT_PERCENT;
  return 0;
}

export function getCanPause(subscription) {
  return (
    getSubscriptionStatus(subscription) === 'active' &&
    subscription.billing_interval === 'monthly' &&
    subscription.stripe_subscription_id
  );
}
export function getCanUnpause(subscription) {
  return getCanPause(subscription) && new Date(subscription.pause_at) > addDate('', { hours: 1 });
}

export function getPauseEndDate(subscription) {
  return (
    subscription?.pause_at &&
    (subscription.pause_until ? new Date(subscription.pause_until) : addDate(subscription.pause_at, { months: 1 }))
  );
}

// SUBSCRIPTION FEATURE ACCESS
export function canAskQuestions(subscription) {
  return !!(subscription && subscription.remaining_questions > 0 && getIsActiveOrTrialing(subscription));
}
