import Loadable from 'my-components/Loadable';

export default Loadable(() => import(/* webpackChunkName: "login-flow" */ './LoginFormCtnr'), { hideLoader: true });
export const LoginFormDialog = Loadable(() => import(/* webpackChunkName: "login-flow" */ './LoginFormDialog'), {
  hideLoader: true,
});
export const ReauthenticateForm = Loadable(
  () => import(/* webpackChunkName: "reauthenticate-flow" */ './ReauthenticateFormCtnr'),
  {
    hideLoader: true,
  },
);
